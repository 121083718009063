/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';
import * as tools from '../controller/tools'
import LoadMore from './LoadMore'

const LeftNav = ({homeSquare, switchSquare, setSquare, getSquare}) => {
  let toSquare = type => e => {setSquare(type);switchSquare(type)}
  let hotTopics = (homeSquare.hasOwnProperty('sidebar_hot_topics') && tools.isArray(homeSquare.sidebar_hot_topics)) ? homeSquare.sidebar_hot_topics : []
  return (
  <div className="left_nav">
    <dl className="nav1 cur">
      <dt><a href="javascript:void(0);">发现</a></dt>
      <dd>
        <a href="javascript:void(0);" onClick={toSquare('new')} className={((getSquare() === 'new') ? 'Select' : '')}>最新动态</a>
        <a href="javascript:void(0);" onClick={toSquare('hot')} className={((getSquare() === 'hot') ? 'Select' : '')}>热门推荐</a>
      </dd>
    </dl>
    <dl className="nav4 cur" >
      <dt><a href="javascript:void(0)">推荐话题</a></dt>
      <dd className="tjht">
      {[...hotTopics].map(t=> <a href={"/topic/" + t.topic_title}><img src={t.topic_pic} alt={t.topic_title}/>{t.topic_title}</a>)}
      </dd>
    </dl>
  </div>
  )
}

const MiddleArea = ({ginfo, homeSquare, getSquare, more}) => {
  let postsList = homeSquare.hasOwnProperty('posts_list') ? (tools.isArray(homeSquare.posts_list) ? homeSquare.posts_list : []) : []
  let curHomeCount = homeSquare.hasOwnProperty('cur_home_count') ? homeSquare.cur_home_count : postsList.length
  return (
  <div className="Middle_area auto-square-home">
    <div className="home_list anonymous_list">
      <ul> 
      {
        [...postsList].map((post, i) => {
          let userInfo = post.hasOwnProperty('user_info') ? post.user_info : {}
          let letter = name => e => {
            if (!(ginfo.G_USER_ID > 0)){window.location.href = '/account';}
            window.AWS.dialog('inboxs', name);
          }
          let ask = (uid, name) => e => {
            if (!(ginfo.G_USER_ID > 0)){window.location.href = '/account';}
            window.AWS.dialog('ask_publish', {ask_user_id: uid, ask_user_name: name});
          }
          return (
          <li className="hdbox">
            <dl>
              <dt>
              {
                (post.question_id > 0) ?
                (
                  (post.anonymous === 0)
                  ? <a className="aw-user-img" data-id={userInfo.uid} href={"/people/" + userInfo.url_token}>
                    <img src={userInfo.avatar_file} alt={userInfo.user_name}/></a>
                  : <a href="javascript:void(0);" className="aw-user-name"><img src={require("../../img/avatar-mid-img.png")} alt="匿名用户"/></a>
                ) 
                :
                <a className="aw-user-img" data-id={userInfo.uid} href={"/people/" + userInfo.url_token}><img src={userInfo.avatar_file} alt={userInfo.user_name}/></a>
              }
              </dt>
              <dd>
                <div className="list_top">
                  <div className="pre_t">
                    <h5><a href={"/people/" + userInfo.url_token}>{(post.question_id > 0) ? ((post.anonymous === 0) ? userInfo.user_name : '匿名用户') : userInfo.user_name}</a></h5>
                    <span>
                    {
                      (post.question_id > 0) ? 
                      (
                        post.answer_count > 0 ? 
                        (post.update_time + '  ' + (post.answer_info.anonymous ? '匿名用户' : post.answer_info.user_info.user_name) + ' 回复了问题')
                        : (post.add_time + '  ' + ((post.anonymous === 0) ? userInfo.user_name : '匿名用户') + ' 发起了问题')
                      ) : (post.add_time + '  ' + userInfo.user_name + ' 发表了文章')
                    }
                    </span>
                  </div>

                  <div className="r_caozuo">
                    <i className="editList"><img src={require("../../img/dico.png")} alt=""/></i>
                    {
                      (post.question_id > 0) ? 
                      (
                        (post.anonymous === 0) ?
                        <label className="hideDel" style={{display:'none'}}>
                          <a onClick={letter(userInfo.user_name)} href="javascript:void(0);">私信</a>
                          <a onClick={ask(userInfo.uid, userInfo.user_name)} href="javascript:void(0);">@问他</a>
                        </label>
                        : 
                        <label className="hideDel" style={{display:'none'}}>
                          <a onClick={ask(userInfo.uid, '匿名用户')} href="javascript:void(0);">@问他</a>
                        </label>
                      )
                      :
                      (
                        <label className="hideDel" style={{display:'none'}}>
                          <a onClick={letter(userInfo.user_name)} href="javascript:void(0);">私信</a>
                          <a onClick={ask(userInfo.uid, userInfo.user_name)} href="javascript:void(0);">@问他</a>
                        </label>
                      )
                    }
                  </div>
                  <div className="clear"></div>
                </div>
                {
                  (post.question_id > 0) ? 
                  <div className="home-text">
                    <div className="home-text-title"><img src={require("../../img/wd2.png")} alt=""/><a href={"/question/" + post.question_id}>{post.question_content}</a></div>
                    <div>
                    {
                      (!tools.isArray(post.answer_users)) ? '' :
                      (
                        [...post.answer_users.slice(0, 8)].map(u => {
                          return (
                            <a className="aw-user-img" data-id={u.uid} href={"/people/" + u.url_token}><img src={u.avatar_file} alt={u.user_name}/></a>
                          )
                        })
                      )
                    }
                    {
                      (post.answer_users.length > 8) ? <span><a href={"/question/" + post.question_id}>>></a></span> : ''
                    }
                    </div>
                  </div>
                  :
                  <div className="home-text">
                    <div className="home-text-title"><img src={require("../../img/zl2.png")} alt=""/><a href={"/article/" + post.id}>{post.title}</a></div>
                    <div>
                    {
                      tools.htmlToText(tools.htmlUnescape(post.message)).length < 130
                      ?
                      <p>{tools.htmlToText(tools.htmlUnescape(post.message))}</p>
                      :
                      <p>{tools.htmlToText(tools.htmlUnescape(post.message)).slice(0, 130) + '...'}
                      <a className="Read" href={"/article/" + post.id}>阅读全部</a></p>
                    }
                    </div>
                  </div>
                }
              </dd>
              <div className="clear"></div>
            </dl>

            <div class="foot_bq">
            {
              (post.question_id > 0) ?
              <div>
                <label className="browse" style={{pointerEvents:'none'}}><i>{post.view_count} </i>次浏览</label>
                <label className="follow" style={{pointerEvents:'none'}}><i>{post.focus_count}</i>人关注</label>
                <label className="comment" style={{pointerEvents:'none'}}><i>{post.answer_count}</i>人回复</label>
              </div>
              :
              <div>
                <label className="hzan" style={{pointerEvents:'none'}}><i>{post.votes}</i>人点赞</label>
                <label className="browse" style={{pointerEvents:'none'}}><i>{post.views}</i>次浏览</label>
                <label className="comment" style={{pointerEvents:'none'}}><i>{post.comments}</i>人评论</label>
              </div>
            }
            </div>
          </li>
          )
        })
      }
      </ul>
    </div>

    <div className="mbw auto-square">
      <div className="load-more btn">
        <div className="load_more_div">
          <div className="load_more"><LoadMore perPage={ginfo.G_PER_PAGE} curRows={curHomeCount} loadEvent={e=>more(getSquare())}/></div>
        </div>
      </div>
    </div>

  </div>
  )
}

const RightArea = ({homeSquare}) => {
  let thisUser = homeSquare.hasOwnProperty('this_user') ? homeSquare.this_user : {}
  let thisUserInfo = thisUser.hasOwnProperty('user_info') ? thisUser.user_info : {}
  let hotUsers = homeSquare.hasOwnProperty('sidebar_hot_users') && tools.isArray(homeSquare.sidebar_hot_users) ? homeSquare.sidebar_hot_users : []
  return (
  <div className="right_area ">
    {/* 个人 */}
    {
      (thisUser.user_id > 0) ?
        <div className="personal" style={{position:'static'}}>
          <dl>
            <dt><a href={"/people/" + thisUserInfo.url_token}><img src={thisUserInfo.avatar_file} alt="" /></a></dt>
            <dd><a href={"/people/" + thisUserInfo.url_token}>{thisUserInfo.user_name}</a></dd>
          </dl>
          <ul>
            <li><span>赞同</span><label>{thisUserInfo.agree_count}</label></li>
            <li><span>威望</span><label>{thisUserInfo.reputation}</label></li>
            <div className="clear"></div>
          </ul>
        </div>
      : ''
    }

    {/* 公告板块
    <div class="right_Notice">
      <h4>公告<a href="/announce/index">查看更多</a></h4>
      <ul>
        <li><a href="/announce/detail/id-106">【奖励发放】<em class="new"></em></a></li>
        <li><a href="/announce/detail/id-105">【奖励发放】</a></li>
        <li><a href="/announce/detail/id-104">【奖励发放】</a></li>
      </ul>
    </div>
    */}	

    {/* 热门用户 */}
    <div class="right_Notice">
	    <h4>热门用户<a href="/people/">查看更多</a></h4>
      {
        [...hotUsers].map(u => {
          return (
          <dl>
            <dt><a href={"/people/" + u.url_token}><img src={u.avatar_file} alt=""/></a></dt>
            <dd>
              <a href={"/people/" + u.url_token} data-id={u.uid} className="aw-user-name">{u.user_name}</a>
              <p><i>{u.answer_count}</i>个问题，<i>{u.agree_count}</i>人赞同</p>
            </dd>
            <div className="clear"></div>
          </dl>
          )
        })
      }
		</div>

    {/* 广告板块
    <div class="right_Notice az_download" style={{width:'256px',height:'100%',padding:'0px'}}>
			<img onclick="goUrl(11,'https://www.chainfor.com/app/index.html');" style={{cursor:'pointer', width:'100%', height:'80px', margin:'0px 0px 8px 0px'}} src="/uploads/common/1552626853.jpg" alt="链向财经" />
			<img onclick="goUrl(15,'https://www.hellobtc.com/gzh/rumen/?b2  ');" style={{cursor:'pointer',width:'100%', height:'80px', margin:'0px'}} src="/uploads/common/1569216771.jpg" alt="白话区块链" />
    </div>
    */}

    {/* APP二维码下载板块
    <div class="right_Notice b_pp"><img src="../uploads/common/1540824923.jpg" alt="" /></div>
    */}
  </div>
  )
}

class HomeMain extends Component {

  constructor(props) {
    super(props)
    this.whereSquare = (props.ginfo.G_USER_ID > 0) ? 'hot' : 'new'
    this.setSquare = this.setSquare.bind(this)
    this.getSquare = this.getSquare.bind(this)
  }

  setSquare(where) {this.whereSquare = where}
  getSquare() {return this.whereSquare}

  render() {
    let {ginfo, homeSquare, switchSquare, more} = this.props
    return (
    <div className="w1150 Main_area">
      <LeftNav homeSquare={homeSquare} switchSquare={switchSquare} setSquare={this.setSquare} getSquare={this.getSquare}/>
      <MiddleArea ginfo={ginfo} homeSquare={homeSquare} getSquare={this.getSquare} more={more}/>
      <RightArea homeSquare={homeSquare}/>
      <div className="clear"></div>
    </div>)
  }
}

export default HomeMain
