/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';
import {BASE, URL} from '../controller/constants'
import * as tools from '../controller/tools'

const LeftNav = ({switchTab, setTab, getTab}) => {
  let toTab = tab => e => {setTab(tab); switchTab(tab)}
  return (
  <div className="left_nav">
    <dl className="nav15 cur">
      <dt><a href="javascript:void(0)">设置</a></dt>
      <dd>
        <a href="javascript:void(0);" onClick={toTab('profile')} className={(getTab() === 'profile') ? 'Select' : ''}>基本资料</a>
        <a href="javascript:void(0);" onClick={toTab('privacy')} className={(getTab() === 'privacy') ? 'Select' : ''}>隐私/提醒</a>
        {/*<a href="javascript:void(0);" onClick={toTab('openid')} className={(getTab() === 'openid') ? 'Select' : ''}>账号绑定</a> */}
        <a href="javascript:void(0);" onClick={toTab('change_mobile')} className={(getTab() === 'change_mobile') ? 'Select' : ''}>修改手机</a>
        <a href="javascript:void(0);" onClick={toTab('security')} className={(getTab() === 'security') ? 'Select' : ''}>安全设置</a>
      </dd>
    </dl>
  </div>
  )
}

const MiddleArea = ({setting, getTab}) => {
  let thisUser = setting.hasOwnProperty('this_user') ? setting.this_user : {}
  let thisUserInfo = thisUser.hasOwnProperty('user_info') ? thisUser.user_info : {}
  let birthdayYear = setting.hasOwnProperty('birthday_y') ? (tools.isObject(setting.birthday_y) ? setting.birthday_y : {}) : {}
  let birthdayMonth = setting.hasOwnProperty('birthday_m') ? (tools.isObject(setting.birthday_m) ? setting.birthday_m : {}) : {}
  let birthdayDay = setting.hasOwnProperty('birthday_d') ? (tools.isObject(setting.birthday_d) ? setting.birthday_d : {}) : {}
  let jobList = setting.hasOwnProperty('job_list') ? (tools.isObject(setting.job_list) ? setting.job_list : {}) : {}
  let notifyActions = setting.hasOwnProperty('notify_actions') ? (tools.isObject(setting.notify_actions) ? setting.notify_actions : {}) : {}
  let notificationSettings = setting.hasOwnProperty('notification_settings') ? (tools.isObject(setting.notification_settings) ? setting.notification_settings : {}) : {}
  return (
  <div className="Middle_area ">
    {/* 基本信息 */}
    <div id="auto-basic-info" style={{display:((getTab() === 'profile') ? 'block': 'none')}}>
      <form id="profile_setting_form" method="post" action={URL.SETTING_API + "profile_setting/"}>
        <div className="Basic_information">
          <dl>
            <dt>头像</dt>
            <dd>
              <img className="aw-border-radius-5" src={thisUserInfo.avatar_file} alt="" id="avatar_src" />
              <div className="pt_upload">
                <div id="head_uploader" style={{display:'none'}}></div>
                <a className="btn btn-mini btn-success" id="avatar_uploader" href="javascript:void(0);">上传</a>
                <span id="avatar_uploading_status" className="collapse" style={{display:'none'}}><i className="aw-loading"></i> 文件上传中...</span>
              </div>
            </dd>
            <div className="clear"></div>
          </dl>
          <dl><dt>用户名</dt><dd>{thisUserInfo.user_name}</dd><div className="clear"></div></dl>
          <dl>
            <dt>性别：</dt>
            <dd>
              <label className={"cf1 " + ((thisUserInfo.sex === 1) ? 'select-label' : '')} data-value="1"><i></i>男</label>
              <input type="radio" style={{display:'none'}} name="sex" id="sex1" value="1" defaultChecked={(thisUserInfo.sex === 1)}/>

              <label className={"cf1 " + ((thisUserInfo.sex === 2) ? 'select-label' : '')} data-value="2"><i></i>女</label>
              <input type="radio" style={{display:'none'}} name="sex" id="sex2" value="2" defaultChecked={(thisUserInfo.sex === 2)}/>

              <label className={"cf1 " + (((!thisUserInfo.sex) || (thisUserInfo.sex === 3)) ? 'select-label' : '')} data-value="3"><i></i>保密</label>
              <input type="radio" style={{display:'none'}} name="sex" id="sex3" value="3" defaultChecked={((!thisUserInfo.sex) || (thisUserInfo.sex === 3))}/>
            </dd>
            <div className="clear"></div>
          </dl>
          <dl>
            <dt>生日</dt>
            <dd>
              <select name="birthday_y" className="li_input w80">
                <option value=""></option>
                {[...Object.keys(birthdayYear)].map(k => <option value={k} selected={(k === thisUserInfo.birthday_year) ? 'selected' : ''}>{birthdayYear[k]}</option>)}
              </select>&nbsp;年&nbsp;
              <select name="birthday_m" class="li_input w80">
                <option value=""></option>
                {[...Object.keys(birthdayMonth)].map(k => <option value={k} selected={(k === thisUserInfo.birthday_month.replace(/\b(0+)/gi,"")) ? 'selected' : ''}>{birthdayMonth[k]}</option>)}
              </select>&nbsp;月&nbsp;
              <select name="birthday_d" class="li_input w80">
                <option value=""></option>
                {[...Object.keys(birthdayDay)].map(k => <option value={k} selected={(k === thisUserInfo.birthday_day.replace(/\b(0+)/gi,"")) ? 'selected' : ''}>{birthdayDay[k]}</option>)}
              </select>&nbsp;日
            </dd>
            <div className="clear"></div>
          </dl>
          <dl>
            <dt>职业</dt>
            <dd>
              <select className="li_input w150" name="job_id">
                <option value="0">--</option>
                {[...Object.keys(jobList)].map(k => <option value={k} selected={(parseInt(k) === parseInt(thisUserInfo.job_id)) ? 'selected' : ''}>{jobList[k]}</option>)}
              </select>
            </dd>
            <div className="clear"></div>
          </dl>
          <dl>
            <dt>个人介绍</dt>
            <dd><input className="li_input w490" name="signature" maxlength="128" type="text" placeholder="请输入" defaultValue={thisUserInfo.signature}/></dd>
            <div className="clear"></div>
          </dl>
          <dl>
            <dt>个性网址</dt>
            <dd>
              {BASE + '/people/'} <input type="text" className="li_input w270" placeholder="请输入" defaultValue={thisUserInfo.url_token} maxlength="32" name="url_token" />
              <p className="tips">可输入 4~20 位的英文或数字, 30 天内只能修改一次</p>
            </dd>
            <div className="clear"></div>
          </dl>
          <div className="t_line"></div>
          <dl>
            <dt>QQ</dt>
            <dd><input className="li_input w490" type="text" name="qq" placeholder="请输入" defaultValue={thisUserInfo.qq ? thisUserInfo.qq : ''}/></dd>
            <div className="clear"></div>
          </dl>
          <dl>
            <dt>常用邮箱</dt>
            <dd><input className="li_input w490" type="text" name="common_email" placeholder="请输入" defaultValue={thisUserInfo.common_email}/></dd>
            <div className="clear"></div>
          </dl>
          <div className="bc_but">
            <a href="javascript:void(0);" className="btn btn-large btn-success pull-right" onClick={e=>window.AWS.ajax_post(window.$('#profile_setting_form'))}>保存</a>
          </div>
        </div>
      </form>
    </div>
    
  {/* 隐私提醒 */}
  <div id="auto-privacy-remind" style={{display:((getTab() === 'privacy') ? 'block': 'none')}}>
    <div className="Privacy_reminding">
      <form id="privacy_setting_form" method="post" action={URL.BASE_URL + "/account/ajax/privacy_setting/"}>
        <dl>
          <dt>私信设置</dt>
          <dd className="who">
              <p>谁可以给我发私信：</p>
              <label className={"cf1 " + ((thisUserInfo.inbox_recv === 0) ? 'select-label' : '')}><i></i>
                <input type="radio" value="0" name="inbox_recv" defaultChecked={thisUserInfo.inbox_recv === 0} /> 所有人 </label>
              <label className={"cf1 " + ((thisUserInfo.inbox_recv === 1) ? 'select-label' : '')}><i></i>
                <input type="radio" value="1" name="inbox_recv" defaultChecked={thisUserInfo.inbox_recv === 1}/> 我关注的人 </label>
          </dd>
          <div className="clear"></div>
        </dl>
        <dl>
          <dt>邮箱设置</dt>
          <dd className="email">
              <p>什么情况下给我发邮件：</p>
              <label className={"cf1 " + ((thisUserInfo['email_settings']['FOLLOW_ME'] !== 'N') ? 'select-label' : '')}><i></i>
                <input name="email_settings[]" value="FOLLOW_ME" type="checkbox" defaultChecked={thisUserInfo['email_settings']['FOLLOW_ME'] !== 'N'}/> 当有人关注我 </label>
              <label className={"cf1 " + ((thisUserInfo['email_settings']['QUESTION_INVITE'] !== 'N') ? 'select-label' : '')}><i></i>
                <input name="email_settings[]" value="QUESTION_INVITE" type="checkbox" defaultChecked={thisUserInfo['email_settings']['QUESTION_INVITE'] !== 'N'}/> 有人邀请我回答问题 </label >
              <label className={"cf1 " + ((thisUserInfo['email_settings']['NEW_ANSWER'] !== 'N') ? 'select-label' : '')}><i></i>
                <input name="email_settings[]" value="NEW_ANSWER" type="checkbox" defaultChecked={thisUserInfo['email_settings']['NEW_ANSWER'] !== 'N'}/> 我关注的问题有了新回复 </label>
              <label className={"cf1 " + ((thisUserInfo['email_settings']['NEW_MESSAGE'] !== 'N') ? 'select-label' : '')}><i></i>
                <input name="email_settings[]" value="NEW_MESSAGE" type="checkbox" defaultChecked={thisUserInfo['email_settings']['NEW_MESSAGE'] !== 'N'}/> 有人向我发送私信 </label>
              <label className={"cf1 " + ((thisUserInfo['email_settings']['QUESTION_MOD'] !== 'N') ? 'select-label' : '')}><i></i>
                <input name="email_settings[]" value="QUESTION_MOD" type="checkbox" defaultChecked={thisUserInfo['email_settings']['QUESTION_MOD'] !== 'N'}/> 我的问题被编辑 </label>
          </dd>
          <div className="clear"></div>
        </dl>
        <dl>
          <dt>微信设置</dt>
          <dd className="weixin">
            <p>什么情况下给我发送通知：</p>
            <label className={"cf1 " + ((thisUserInfo['weixin_settings']['AT_ME'] !== 'N') ? 'select-label' : '')}><i></i>
              <input name="weixin_settings[]" value="AT_ME" type="checkbox" defaultChecked={thisUserInfo['weixin_settings']['AT_ME'] !== 'N'}/> 当有人@我 </label>
            <label className={"cf1 " + ((thisUserInfo['weixin_settings']['NEW_ANSWER'] !== 'N') ? 'select-label' : '')}><i></i>
              <input name="weixin_settings[]" value="NEW_ANSWER" type="checkbox" defaultChecked={thisUserInfo['weixin_settings']['NEW_ANSWER'] !== 'N'}/> 我的问题有了新回复 </label>
            <label className={"cf1 " + ((thisUserInfo['weixin_settings']['NEW_ARTICLE_COMMENT'] !== 'N') ? 'select-label' : '')}><i></i>
              <input name="weixin_settings[]" value="NEW_ARTICLE_COMMENT" type="checkbox" defaultChecked={thisUserInfo['weixin_settings']['NEW_ARTICLE_COMMENT'] !== 'N'}/> 我的文章有了新评论 </label>
            <label className={"cf1 " + ((thisUserInfo['weixin_settings']['NEW_COMMENT'] !== 'N') ? 'select-label' : '')}><i></i>
              <input name="weixin_settings[]" value="NEW_COMMENT" type="checkbox" defaultChecked={thisUserInfo['weixin_settings']['NEW_COMMENT'] !== 'N'}/> 我的回复有了新评论 </label>
            <label className={"cf1 " + ((thisUserInfo['weixin_settings']['QUESTION_INVITE'] !== 'N') ? 'select-label' : '')}><i></i>
              <input name="weixin_settings[]" value="QUESTION_INVITE" type="checkbox" defaultChecked={thisUserInfo['weixin_settings']['QUESTION_INVITE'] !== 'N'}/> 有人邀请我回答问题 </label>
          </dd>
          <div className="clear"></div>
        </dl>
        <dl>
          <dt>通知设置</dt>
          <dd className="notics">
            <p>什么情况下给我发送通知：</p>
            {
              [...Object.keys(notifyActions)].map(k => {
                let checkedFlag = tools.isEmpty(notificationSettings['data']) || (!tools.inArray(k, notificationSettings['data']))
                return (
                  (!(notifyActions[k]['user_setting'])) ? '' :
                  <label className={"cf1 " + (checkedFlag ? 'select-label' : '')} id={"notics-" + k} onClick={e=>window.label_click(window.$('#notics-' + k))}><i></i>
                    <input name={"notification_settings[" + k + "]"} type="checkbox" value="1" defaultChecked={checkedFlag} />
                    {notifyActions[k]['desc']}
                  </label>
                )
              })
            }
          </dd>
          <div className="clear"></div>
        </dl>
      </form>
      <div className="bc_but"><a href="javascript:void(0);" className="btn btn-large btn-success pull-right" onClick={e=>window.AWS.ajax_post(window.$('#privacy_setting_form'))}>保存</a></div>
    </div>
  </div>

  {/* 账号绑定 */}
  {/*
  <div id="auto-account-bind" style={{display:((getTab() === 'openid') ? 'block': 'none')}}>
    <div className="Bind_Account">
        
        <dl>
          <dt>
            <img src={require("../../img/weixin_new.png")} alt=""/>
            <span>微信</span>
          </dt>
          <dd>未绑定</dd>
          <dd>
            <a onclick="AWS.dialog('alertImg', {
              'hide' : 'show', 
              'url' : 'http://www.biask.com/static/css/default/img/wechat_code.jpg', 
              'message' : '请关注公众帐号进行绑定操作'});" href="javascript:;" class="btn btn-normal btn-success">绑定</a>
          </dd>
          <div class="clear"></div>
        </dl>

        <dl>
          <dt>
            <img src="http://www.biask.com/static/theme/lime/images/yoyow_new.png" alt=""/>
            <span> YOYOW </span>
          </dt>
          <dd>未绑定</dd>
          <dd>
            <a class="yoyow_qr_login btn btn-normal btn-success" href="javascript:void(0)" onclick="AWS.dialog('yoyow_qr_login', 'xxxxxxxxxxxx');">APP扫一扫</a>       
            <a href="http://www.biask.com/account/openid/yoyow/bind/" class="btn btn-normal btn-success">网页钱包绑定</a>
          </dd>
          <div class="clear"></div>
        </dl>

        <dl>
          <dt>
            <img src={require("../../img/phone_new.png")} alt=""/>
            <span>手机账号</span>
            </dt>
            <dd>159****9048</dd>
            <dd><a style={{cursor:'pointer'}} class="modify" onclick="AWS.dialog('modify_revise', {item_phone2:'159****9048',item_phone:15996419048});" >修改</a></dd>
            <div class="clear"></div>
        </dl> 
      </div>
  </div>
  */}

  {/* 修改手机 */}
  <div id="auto-change-mobile" style={{display:((getTab() === 'change_mobile') ? 'block': 'none')}}>
    <form action="#" method="post">
      <div id="wizard">
          <ul id="status" className="status">
            <li className="active"><strong>1.</strong>验证原手机</li>
            <li><strong>2.</strong>验证新手机</li>
            <li><strong>3.</strong>完成</li>
          </ul>
          <div className="items">
            {/* 验证原手机 */}
            <div className="page">
                  <div className="binding">
                    <ul>
                        <input type="hidden" id="old_mobile" name="old_mobile" value={(!tools.isEmpty(setting.mobile)) ? setting.mobile : ''}  />
                        <li>
                          <span>+86<em></em></span>
                          <input type="text" defaultValue={(!tools.isEmpty(setting.mobile)) ? setting.mobile.replace(/^(\d{3})\d*(\d{4})$/, "$1****$2") : ''} disabled="disabled" />
                        </li>
                        <li className="wsmall">
                          <input id="old_code" name="old_code" type="text" placeholder="请输入验证码" defaultValue=''/>
                          <button id="sendButton1" type="button" onClick={e=>window.sendCode('#sendButton1')}>发送验证码</button>
                        </li>
                    </ul>
                  </div>
                  <div className="btn_nav f_but">
                    <input type="button" className="next" value="下一步" />
                  </div>
            </div>
            {/* 验证新手机 */}
            <div className="page">
                  <div className="binding">
                    <ul>
                        <li>
                          <span>+86<em></em></span><input type="text" id="new_mobile" name="new_mobile" defaultValue=''/>
                        </li>
                        <li className="wsmall">
                          <input id="new_code" name="new_code" type="text" placeholder="请输入验证码" defaultValue=''/>
                          <button id="sendButton2" type="button" onClick={e=>window.sendCode('#sendButton2')}>发送验证码</button>
                        </li>
                    </ul>
                  </div>
                  <div className="btn_nav f_but">
                    <input type="button" className="prev left disabled" value="上一步" />
                    <input type="button" className="next right" value="下一步" />
                  </div>
            </div>
            {/* 完成修改 */}
            <div className="page">
                  <h4>点击确定，完成修改！</h4>
                  <div className="btn_nav f_but">
                    <input type="button" className="prev left disabled" style={{float:'left'}} value="上一步" />
                    <input type="button" className="next right" id="sub" value="确定" />
                  </div>
            </div>
          </div>
        </div>
    </form>
  </div>

  {/* 安全设置 */}
  <div id="auto-security-set" style={{display:((getTab() === 'security') ? 'block': 'none')}}>
    <form className="form-horizontal" method="post" id="security_setting_form">
      <input type="hidden" name="token" value={setting.token}/>
      <input type="hidden" id="pub_key" value={setting.pub_key}/>
      <div className="Password_setting">
          <h3>密码设置</h3>
          <dl>
            <dt>旧密码</dt>
            <dd>
              <input name="old_password" id="old_password" type="hidden" value=""/>
              <input id="old_passinput" name="old_passinput" type="password" className="old_password" autocomplete='new-password' placeholder="请输入当前密码"/>
            </dd>
            <div className="clear"></div>
          </dl>
          <dl>
            <dt>新密码</dt>
            <dd>
              <input name="password" className="password" id="password" type="hidden" value=""/>
              <input id="passinput" name="passinput" type="password" className="password" autocomplete='new-password' placeholder="请输入新密码"/>
            </dd>
            <div className="clear"></div>
          </dl>
          <dl>
            <dt>确认密码</dt>
            <dd>
              <input name="re_password" className="re_password" id="re_password" type="hidden" value=""/>
              <input id="re_passinput" name="re_passinput" type="password" className="re_password" autocomplete='new-password' placeholder="请重复输入新密码"/>
            </dd>
            <div className="clear"></div>
          </dl>
          <div className="bc_but">
            <a href="javascript:void(0);" className="btn btn-large btn-success pull-right " id="modify_password">保存</a>
          </div>
      </div>
    </form>
  </div>
  </div>
  )
}

const RightArea = ({setting}) => {
  return (
  <div className="right_area ">
    {/* 
      <div class="right_Notice">
      <h4>帮助</h4>
      <ul>
          <li><a href="/question/2216">币问账号下的真实姓名如何修改？</a></li>
          <li><a href="/question/4861">币问新手引导</a></li>
          <li><a href="/article/529">币问用户守则</a></li>
      </ul>
      </div>
    */}
       
    <div className="right_Notice az_download">
      <dl>
        <dt><img src={require("../../img/anr.png")} alt=""/></dt>
        <dd><h5>下载App</h5><p>您身边的汽车专家！</p></dd>
        <div className="clear"></div>
      </dl>
    </div>
  </div>
  )
}

class Setting extends Component {

  constructor(props) {
    super(props)
    this.whereTab = (!tools.isEmpty(this.props.params.action)) ? this.props.params.action : 'profile'
    this.setTab = this.setTab.bind(this)
    this.getTab = this.getTab.bind(this)
  }

  setTab(where) {this.whereTab = where}
  getTab() {return this.whereTab}

  render() {
    let {ginfo, setting, switchTab} = this.props
    return (
    <div className="w1150 Main_area">
      <LeftNav setting={setting} switchTab={switchTab} setTab={this.setTab} getTab={this.getTab}/>
      <MiddleArea ginfo={ginfo} setting={setting} getTab={this.getTab}/>
      <RightArea setting={setting}/>
      <div className="clear"></div>
    </div>)
  }
}

export default Setting
