/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import LoadMore from './LoadMore'
import * as tools from '../controller/tools'

const ReadInbox = ({ginfo, inbox, id, updateDialog, setDialog, startInter}) => {
  const getDialog = (dia, id) => {
    for (let [k, v] of Object.entries(dia)) {
      if (Number(k) === id) { return v }
    }
    return {}
  }
  let dialog = inbox.hasOwnProperty('dialog') ? (inbox.dialog ? getDialog(inbox.dialog, id) : {}) : {}
  let dialogList = dialog.hasOwnProperty('list') ? dialog.list : {}
  let thisUserInfo = dialog.hasOwnProperty('this_user') ? dialog.this_user : {}
  let recipientUser = dialog.hasOwnProperty('recipient_user') ? dialog.recipient_user : {}
  const messSend = id => e => {
    window.AWS.ajax_post(window.$('#recipient_form' + id), null, 'inbox_dialog')
    window.$("#text_input_" + id).val('')
    setDialog(id, true, false)
    updateDialog(id)
    startInter(()=>{
      updateDialog(id)
      setDialog(id, true, true)
    })
  }
  return (
    <div className="auto-read-inbox" id={"read_inbox_" + id} style={{display:'none'}}>
      <div className="message-list">
        {
          Object.values(dialogList).map(val => {
            return (
              <div className={(val.uid === thisUserInfo.user_id) ? "message-item message-item-right" : "message-item message-item-left"}>
                <a href={"/people/" + (val.uid === thisUserInfo.user_id ? thisUserInfo.url_token : val.url_token)} data-id={val.uid}>
                  <img className="avatar" src={val.avatar_file} alt="" />
                </a>
                <div className="message-bubble" dangerouslySetInnerHTML={{__html: val.message}}></div>
              </div>
            )
          })
        }    
      </div>
      <div className="message-footer">
        <form action={ginfo.G_BASE_URL + "/api/inbox/send/"} method="post" id={"recipient_form" + id}>
          <input type="hidden" name="post_hash" value={ginfo.G_POST_HASH} />
          <input type="hidden" name="recipient" value={recipientUser.user_name} />
          <textarea rows="5" className="text-input" id={"text_input_" + id} placeholder="想要对ta说点什么" type="text" name="message"></textarea>
          <div className="text-send" onClick={messSend(id)}>发送</div>
        </form>
      </div>
    </div>
  )
}

const LeftNav = ({inbox}) => {
  let inboxList = inbox.hasOwnProperty('list') ? (inbox.list ? inbox.list : []) : []
  const newInboxs = e => {window.AWS.dialog('inboxs', '')}
  return (
    <div className="left_nav">
      <dl className="nav2 "><dt><Link to="/question">问答</Link></dt></dl>
      <dl className="nav4 "><dt><Link to="/topic">话题</Link></dt></dl>
      <dl className="nav6 cur ">
        <dt>
          <a href="javascript:void(0);">{'私信 ' + [...inboxList].length}</a>
          <i className="del" style={{cursor:'pointer'}} onClick={newInboxs} >新私信</i>
        </dt>
        <dd>
          <a href="/inbox" class="Select" >私信列表</a>
          <a href="/setting/privacy/">私信设置</a>
        </dd>
		  </dl>
	  </div>
  )
}

const InboxMiddle = ({ginfo, inbox, more, dialog, setDialog, startInter, closeInter}) => {
  let inboxList = inbox.hasOwnProperty('list') ? (inbox.list ? inbox.list : []) : []
  const toDialog = (index, id)=> e => {
    window.$('#unread_inbox_' + index).html('')
    window.AWS.Message.check_lime_notifications();
    if(window.$('#read_inbox_' + id).is(':visible')) {
      window.$('#read_inbox_' + id).hide(1000)
      setDialog('', false, false)
      closeInter()
    }else{
      setDialog(id, false, false)
      dialog(id)
    }
  }
  const delDialog = id => e => {
    window.AWS.dialog('confirm', {'message':'确认删除对话'}, function(){
      window.location = ginfo.G_BASE_URL + '/inbox/delete_dialog/dialog_id-' + id
    })
  }
  return (
    <div className="Middle_area ">
		{
      ([...inboxList].length === 0) 
      ?
      <div class="wenda">
        <div class="no_result">
          <img src={require("../../img/no_result.png")} alt="no result"/>
          <p>暂无内容</p>
        </div>
      </div>
      :
			<div className="y_notice auto-inbox">
				<ul>
          {
            [...inboxList].map((item, index) => {
              return (
              <li>
                <div className="auto-inbox-img">
                  <a className="aw-user-img" data-id={item.uid} href={"/people/" + item.url_token}><img src={item.avatar_file} alt=""/></a>
                </div>
                <div className="auto-inbox-info">
                  <p>
                    <a className="aw-user-name" data-id={item.uid} href={"/people/" + item.url_token}>{item.user_name}</a>
                    {item.unread ? <span id={"unread_inbox_" + index}><em>{item.unread}</em></span> : ''}
                  </p>
                  <p>
                    <a href="javascript:void(0);" className={"to_dialog_" + item.id} onClick={toDialog(index, item.id)}>
                    {(item.last_message.length <= 24) ? item.last_message : (item.last_message.substring(0, 23) + '...')}
                    </a>
                    <label>{item.update_time}</label>
                  </p>
                </div>
                <div className="clear"></div>
                <p>
										<label className="browse">
                      <a className={"pull-right to_dialog_" + item.id} href="javascript:void(0);" onClick={toDialog(index, item.id)}>
                      {item.unread ? '有' + item.unread + '条新回复' : '共' + item.count + '条对话'}
                      </a>
                    </label>
                    <label className="del"><a className="pull-right" href="javascript:void(0);" onClick={delDialog(item.id)}>删除</a></label>
                </p>
                <ReadInbox ginfo={ginfo} inbox={inbox} id={item.id}  updateDialog={dialog} setDialog={setDialog} startInter={startInter}/>
              </li>
              )
            })
          }
          <li><LoadMore perPage={ginfo.G_PER_PAGE} curRows={inbox.cur_inbox_num} loadEvent={more}/></li>
				</ul>
			</div>
    }
		</div>
  )
}

const InboxRight = ({inbox}) => {
  let recommend = inbox.hasOwnProperty('recommend_users_topics') ? (inbox.recommend_users_topics ? inbox.recommend_users_topics : []) : []
  let recommendTopics = recommend.filter(value => value.topic_id)
  let recommendUsers = recommend.filter(value => !value.topic_id)
  return (
    <div className="right_area ">
			<div className="right_Notice">
		    <h4>可能感兴趣的人<a href="/people">查看更多</a></h4>
        {
          recommendUsers.map(u => {
            return (
            <dl>
              <dt><a href={"/people/" + u.url_token} data-id={u.uid} class="aw-user-name"><img alt={u.user_name} src={u.avatar_file}/></a></dt>
              <dd>
                <a href={"/people/" + u.url_token} data-id={u.uid} class="aw-user-name">{u.user_name}</a>
                {
                  !u.hasOwnProperty('type') ? '' : (
                    (u.type === 'friend') 
                    ? 
                    <p><a href={"/people/"+ u.friend_users.url_token} class="aw-user-name" data-id={u.friend_users.uid} style={{fontSize:'12px'}}>{u.friend_users.user_name}</a> 关注了他</p>
                    :
                    ((u.type === 'topic') ? <p>他也关注<a href={"/topic/"+ u.topic_info.url_token} style={{fontSize:'12px'}}>{u.topic_info.topic_title}</a></p> : '')
                  )
                }
              </dd>
              <div class="clear"></div>
            </dl>
            )
          })
        }
			</div>

			<div class="right_Notice">
		    <h4>可能感兴趣的话题<a href="/topic">查看更多</a></h4>
	      <div class="Topic">
          {
            recommendTopics.map(t => {
              return (
                <a href={"/topic/" + t.url_token} className="text">{t.topic_title}</a>
              )
            })
          }
			  </div>
	    </div>
	  </div>
  )
}

class InboxMain extends Component {

  constructor(props) {
    super(props)
    this.inter = false
    this.execOnce = true
    this.dialog = {
      dialogId: '', 
      dialogWorking: false,
      dialogRefreshing: false
    }
    this.setDialog = this.setDialog.bind(this)
    this.getDialog = this.getDialog.bind(this)
    this.startInter = this.startInter.bind(this)
    this.closeInter = this.closeInter.bind(this)
  }
  
  setDialog(id, workFlag, freshFlag) {
    this.dialog.dialogId = id
    this.dialog.dialogWorking = workFlag
    this.dialog.dialogRefreshing = freshFlag
  }
  getDialog() {return this.dialog}

  startInter(cb) {
    if (!this.inter) {
      this.inter = setInterval(cb, 3000)
    }
  }
  closeInter() {
    if (this.inter) {
      clearInterval(this.inter) 
      this.inter = false
    }
  }

  componentDidUpdate() {
    let dialogId = this.getDialog().dialogId
    let dialogWorking = this.getDialog().dialogWorking
    let dialogRefreshing = this.getDialog().dialogRefreshing
    if (!tools.isEmpty(dialogId) && !dialogWorking) {
      this.closeInter()
      window.$('.auto-read-inbox').hide(1000)
      window.$('#read_inbox_' + dialogId).toggle('slow')
      window.$('#read_inbox_' + dialogId + ' .message-list')[0].scrollTop = window.$('#read_inbox_' + dialogId + ' .message-list')[0].scrollHeight
    }
    if(!tools.isEmpty(dialogId) && dialogWorking && !dialogRefreshing){
      window.$('#read_inbox_' + dialogId + ' .message-list')[0].scrollTop = window.$('#read_inbox_' + dialogId + ' .message-list')[0].scrollHeight
    }
    
    if (this.execOnce) {
      this.execOnce = false
      window.$(document).click(event => {
        let dialogId = this.getDialog().dialogId
        if (!tools.isEmpty(dialogId)) {
          let elem = window.$('#read_inbox_' + dialogId)
          if(!elem.is(event.target) && elem.has(event.target).length === 0){ 
              if (window.$('#read_inbox_' + dialogId).is(':visible')) {
                  window.$('#read_inbox_' + dialogId).hide(1000)
                  this.setDialog('', false, false)
                  this.closeInter()
              }
          }
        }
      })
    }
  }
  
  render() {
    const {ginfo, inbox, more, dialog} = this.props
    return (
      <div class="w1150 Main_area">
        <LeftNav inbox={inbox}/>
        <InboxMiddle ginfo={ginfo} inbox={inbox} more={more} dialog={dialog} 
          setDialog={this.setDialog} startInter={this.startInter} closeInter={this.closeInter}/>
        <InboxRight inbox={inbox}/>
        <div class="clear"></div>
      </div>
    )
  }
}

export default InboxMain
