/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import * as tools from '../controller/tools'
import LoadMore from './LoadMore'

const LeftNav = ({people, userActions, userFollows, userTopics, setPageSquare, getPageSquare}) => {
  let uid = people.user.uid
  let updateStyle = (nav, area) => {
    window.$('.nav').removeClass('cur')
    window.$('#' + nav).addClass('cur')
    window.$('.user-area').css('display', 'none')
    window.$('.' + area).css('display', 'block')
    if (window.$('#nav-follows dd').css('display') === 'block') {
      window.$('#nav-follows dd').css('display', 'none')
      window.$('.follows').removeClass('Select')
    }
  }
  let updateFollowsStyle = (nav, area) => {
    window.$('.nav').removeClass('cur')
    window.$('#nav-follows').addClass('cur')
    window.$('.follows').removeClass('Select')
    window.$('.' + nav).addClass('Select')
    window.$('.user-area').css('display', 'none')
    window.$('.' + area).css('display', 'block')
  }
  let navFollows = e => {
    window.$('#nav-follows dd').css('display') === 'block' ?
    window.$('#nav-follows dd').css('display', 'none') : window.$('#nav-follows dd').css('display', 'block')
  }
  let actionsAll = e => {setPageSquare('nav-actions-all'); userActions(uid, 'all'); updateStyle('nav-actions-all', 'user-actions-all')}
  let actionsQues = e => {setPageSquare('nav-actions-ques'); userActions(uid, '101'); updateStyle('nav-actions-ques', 'user-actions-ques')}
  let actionsAnswer = e => {setPageSquare('nav-actions-answer'); userActions(uid, '201'); updateStyle('nav-actions-answer', 'user-actions-answer')}
  let actionsArt = e => {setPageSquare('nav-actions-art'); userActions(uid, '501'); updateStyle('nav-actions-art', 'user-actions-art')}
  
  let followsFollows = e => {setPageSquare('nav-follows-follows'); userFollows(uid, 'follows'); updateFollowsStyle('nav-follows-follows', 'user-follows-follows')}
  let followsFans = e => {setPageSquare('nav-follows-fans'); userFollows(uid, 'fans'); updateFollowsStyle('nav-follows-fans', 'user-follows-fans')}
  let topicsFollows = e => {setPageSquare('nav-follows-topics'); userTopics(uid); updateFollowsStyle('nav-follows-topics', 'user-follows-topics')}
  return (
    <div className="left_nav">
      <dl id="nav-actions-all" className={"nav nav1 " + ((getPageSquare() === 'nav-actions-all') ? 'cur' : '')}>
        <dt><a href="javascript:void(0);" onClick={actionsAll}>动态</a></dt>
      </dl>
      <dl id="nav-actions-ques" className={"nav nav2 " + ((getPageSquare() === 'nav-actions-ques') ? 'cur' : '')}>
        <dt><a href="javascript:void(0);" onClick={actionsQues}>发问&nbsp;&nbsp;{people.user.question_count}</a></dt>
      </dl>
      <dl id="nav-actions-answer" className={"nav nav8 " + ((getPageSquare() === 'nav-actions-answer') ? 'cur' : '')}>
        <dt><a href="javascript:void(0);" onClick={actionsAnswer}>回复&nbsp;&nbsp;{people.user.answer_count}</a></dt>
      </dl>
      <dl id="nav-actions-art" className={"nav nav3 " + ((getPageSquare() === 'nav-actions-art') ? 'cur' : '')}>
        <dt><a href="javascript:void(0);" onClick={actionsArt}>文章&nbsp;&nbsp;{people.user.article_count}</a></dt>
      </dl>
      <dl id="nav-follows" className={"nav nav4 " + ((getPageSquare().indexOf('nav-follows') !== -1) ? 'cur' : '')}>
        <dt><a href="javascript:void(0);" onClick={navFollows}>关注</a></dt>
        <dd style={{display:((getPageSquare().indexOf('nav-follows') !== -1) ? 'block' : 'none')}}>
          <a className={"follows nav-follows-follows " + ((getPageSquare() === 'nav-follows-follows') ? "Select" : '')} href="javascript:void(0);" onClick={followsFollows}>关注谁</a>
          <a className={"follows nav-follows-fans " + ((getPageSquare() === 'nav-follows-fans') ? "Select" : '')} href="javascript:void(0);" onClick={followsFans}>被谁关注</a>
          <a className={"follows nav-follows-topics " + ((getPageSquare() === 'nav-follows-topics') ? "Select" : '')} href="javascript:void(0);" onClick={topicsFollows}>关注的话题</a>
        </dd>
      </dl>
    </div>
  )
}

const MiddleArea = ({ginfo, people, userActionsMore, userFollowsMore, userTopicsMore, getPageSquare}) => {
  let uid = people.user.uid
  let actionsAllMore = e => {userActionsMore(uid, 'all')}
  let actionsQuesMore = e => {userActionsMore(uid, '101')}
  let actionsAnswerMore = e => {userActionsMore(uid, '201')}
  let actionsArtMore = e => {userActionsMore(uid, '501')}
  let followsFollowsMore = e => {userFollowsMore(uid, 'follows')}
  let followsFansMore = e => {userFollowsMore(uid, 'fans')}
  let topicsMore = e => {userTopicsMore(uid)}
  let userActionsContent = people.hasOwnProperty('user_actions_content') ? people.user_actions_content : {}
  let userActionsAll = userActionsContent.hasOwnProperty('all') ? userActionsContent['all'] : {}
  let actionsAllList = (userActionsAll.hasOwnProperty('list') && tools.isArray(userActionsAll.list)) ? userActionsAll.list :
                       ((userActionsAll.hasOwnProperty('list') && tools.isObject(userActionsAll.list)) ? Object.values(userActionsAll.list) : [])
  let actionsAllCurCount = userActionsAll.hasOwnProperty('cur_actions_count') ? userActionsAll.cur_actions_count : actionsAllList.length
  let userActionsQues = userActionsContent.hasOwnProperty('101') ? userActionsContent['101'] : {}
  let actionsQuesList = (userActionsQues.hasOwnProperty('list') && tools.isArray(userActionsQues.list)) ? userActionsQues.list :
                        ((userActionsQues.hasOwnProperty('list') && tools.isObject(userActionsQues.list)) ? Object.values(userActionsQues.list) : [])
  let actionsQuesCurCount = userActionsQues.hasOwnProperty('cur_actions_count') ? userActionsQues.cur_actions_count : actionsQuesList.length
  let userActionsAnswer = userActionsContent.hasOwnProperty('201') ? userActionsContent['201'] : {}
  let actionsAnswerList = (userActionsAnswer.hasOwnProperty('list') && tools.isArray(userActionsAnswer.list)) ? userActionsAnswer.list :
                          ((userActionsAnswer.hasOwnProperty('list') && tools.isObject(userActionsAnswer.list)) ? Object.values(userActionsAnswer.list) : [])
  let actionsAnswerCurCount = userActionsAnswer.hasOwnProperty('cur_actions_count') ? userActionsAnswer.cur_actions_count : actionsAnswerList.length
  let userActionsArt = userActionsContent.hasOwnProperty('501') ? userActionsContent['501'] : {}
  let actionsArtList = (userActionsArt.hasOwnProperty('list') && tools.isArray(userActionsArt.list)) ? userActionsArt.list :
                          ((userActionsArt.hasOwnProperty('list') && tools.isObject(userActionsArt.list)) ? Object.values(userActionsArt.list) : [])
  let actionsArtCurCount = userActionsArt.hasOwnProperty('cur_actions_count') ? userActionsArt.cur_actions_count : actionsArtList.length

  let userFollows = people.hasOwnProperty('user_follows') ? people.user_follows : {}
  let userFollowsFollows = userFollows.hasOwnProperty('follows') ? userFollows.follows : {}
  let followsList = (userFollowsFollows.hasOwnProperty('users_list') && tools.isArray(userFollowsFollows.users_list)) ? userFollowsFollows.users_list :
                    ((userFollowsFollows.hasOwnProperty('users_list') && tools.isObject(userFollowsFollows.users_list)) ? Object.values(userFollowsFollows.users_list) : [])
  let followsCurCount = userFollowsFollows.hasOwnProperty('cur_follows_count') ? userFollowsFollows.cur_follows_count : followsList.length

  let userFollowsFans = userFollows.hasOwnProperty('fans') ? userFollows.fans : {}
  let fansList = (userFollowsFans.hasOwnProperty('users_list') && tools.isArray(userFollowsFans.users_list)) ? userFollowsFans.users_list :
                 ((userFollowsFans.hasOwnProperty('users_list') && tools.isObject(userFollowsFans.users_list)) ? Object.values(userFollowsFans.users_list) : [])
  let fansCurCount = userFollowsFans.hasOwnProperty('cur_follows_count') ? userFollowsFans.cur_follows_count : fansList.length

  let userTopics = people.hasOwnProperty('user_topics') ? people.user_topics : {}
  let topicsList = (userTopics.hasOwnProperty('topic_list') && tools.isArray(userTopics.topic_list)) ? userTopics.topic_list : 
                   ((userTopics.hasOwnProperty('topic_list') && tools.isObject(userTopics.topic_list)) ? Object.values(userTopics.topic_list) : [])
  let topicsCurCount = userTopics.hasOwnProperty('cur_topics_count') ? userTopics.cur_topics_count : topicsList.length
  
  let followPeople = (uid, index) => e => {window.follow_people(window.$('#follow-people'+index), 'user', uid)}
  let fanPeople = (uid, index) => e => {window.follow_people(window.$('#fan-people'+index), 'user', uid)}
  let followTopic = (tid, index) => e => {window.follow_topic(window.$('#follow-topic'+index), 'topic', tid)}
  return (
    <div className="Middle_area ">
      <div className="wenda personal_s">
        <div className="user-area user-actions-all" style={{display:((getPageSquare() === 'nav-actions-all') ? 'block' : 'none')}}>
            {
              ([...actionsAllList].length === 0)
              ? 
              <div className="no_result"><img src={require("../../img/no_result.png")} alt=""/><p>暂无内容</p></div>
              :
              <div>
              {
                [...actionsAllList].map(action => {
                  return (
                    ((action.associate_action === 101) && (tools.isObject(action.question_info)))
                    ?
                    <dl>
                      <dt><span>发起了问题</span><label className="fr">{action.add_time}</label></dt>
                      <dd><p className="bold"><a href={"/question/"+ action.question_info.question_id}>{action.title}</a></p></dd>
                      <div className="foot_bq">
                        <div className="zkzd"><span className="grayon"><em className="up"></em>{action.question_info.answer_count}</span></div>
                        <label className="browse"><i>{action.question_info.view_count}</i>次浏览</label>
                        <label className="follow" ><i>{action.question_info.focus_count}</i>关注</label>
                      </div>
                    </dl>
                    :
                    (
                      ((action.associate_action === 201) && (tools.isObject(action.answer_info)) && (tools.isObject(action.question_info))) ?
                      <dl>
                        <dt>
                          <span>{"回答了问题(" + action.answer_info.agree_count + "回复)"}</span>
                          <label className="fr">{action.add_time}</label>
                        </dt>
                        <dd>
                          <p className="bold"><a href={"/question/" + action.question_info.question_id}>{action.title}</a></p>
                        </dd>
                        <dd>
                        {
                          (tools.htmlToText(tools.htmlUnescape(action.answer_info.answer_content)).length < 130)
                          ?
                          <p>{"回复：" + tools.htmlToText(tools.htmlUnescape(action.answer_info.answer_content))}</p>
                          :
                          <p>
                            {"回复：" + tools.htmlToText(tools.htmlUnescape(action.answer_info.answer_content)).slice(0, 130) + '...'}
                            <a className="Read" href={"/question/" + action.question_info.question_id}>阅读全部</a>
                          </p>
                        }
                        </dd>
                      </dl>
                      :
                      (
                        ((action.associate_action === 501) && (tools.isObject(action.article_info)))
                        ?
                        <dl>
                          <dt>
                            <span>发表了文章</span>
                            <label className="fr">{action.add_time}</label>
                          </dt>
                          <dd>
                            <p className="bold"><a href={"/article/" + action.article_info.id}>{action.title}</a></p>
                          </dd>
                        
                          <dd>
                          {
                            tools.htmlToText(tools.htmlUnescape(action.article_info.message)).length < 130
                            ?
                            <p><br/>{tools.htmlToText(tools.htmlUnescape(action.article_info.message))}</p>
                            :
                            <p><br/>{tools.htmlToText(tools.htmlUnescape(action.article_info.message)).slice(0, 130) + '...'}
                            <a className="Read" href={"/article/" + action.article_info.id}>阅读全部</a></p>
                          }
                          </dd>
                          <div className="foot_bq">
                            <div className="zkzd"><span className="grayon"><em className="up"></em>{action.article_info.votes}</span></div>
                            <label className="browse"><i>{action.article_info.views}</i>次浏览</label>
                            <label className="comment"><i>{action.article_info.comments}</i>人评论</label>
                          </div>
                        </dl>
                        : ''
                      )
                    )
                  )
                })
              }
              <div className="load_more_div">
                <div className="load_more"><LoadMore perPage={ginfo.G_PER_PAGE} curRows={actionsAllCurCount} loadEvent={actionsAllMore}/></div>
              </div>
              </div>
            }
        </div>
       
        <div className="user-area user-actions-ques" style={{display:((getPageSquare() === 'nav-actions-ques') ? 'block' : 'none')}}>
          {
            ([...actionsQuesList].length === 0)
            ?
            <div className="no_result"><img src={require("../../img/no_result.png")} alt=""/><p>暂无内容</p></div>
            :
            <div>
            {
              [...actionsQuesList].map(ques => {
                return (
                tools.isObject(ques.question_info) ?
                <dl>
                  <dt><span>发起了问题</span><label className="fr">{ques.add_time}</label></dt>
                  <dd><p className="bold"><a href={"/question/"+ ques.question_info.question_id}>{ques.title}</a></p></dd>
                  <div className="foot_bq">
                    <div className="zkzd"><span className="grayon"><em className="up"></em>{ques.question_info.answer_count}</span></div>
                    <label className="browse"><i>{ques.question_info.view_count}</i>次浏览</label>
                    <label className="follow" ><i>{ques.question_info.focus_count}</i>关注</label>
                  </div>
                </dl>
                : ''
                )
              })
            }
            <div className="load_more_div">
              <div className="load_more"><LoadMore perPage={ginfo.G_PER_PAGE} curRows={actionsQuesCurCount} loadEvent={actionsQuesMore}/></div>
            </div>
            </div>
          }
        </div>
        
        <div className="user-area user-actions-answer" style={{display:((getPageSquare() === 'nav-actions-answer') ? 'block' : 'none')}}>
        {
          ([...actionsAnswerList].length === 0)
          ?
          <div className="no_result"><img src={require("../../img/no_result.png")} alt=""/><p>暂无内容</p></div>
          :
          <div>
            {
              [...actionsAnswerList].map(answer => {
                return (
                (tools.isObject(answer.answer_info) && tools.isObject(answer.question_info)) ?
                <dl>
                  <dt>
                    <span>{"回答了问题(" + answer.answer_info.agree_count + "回复)"}</span>
                    <label className="fr">{answer.add_time}</label>
                  </dt>
                  <dd>
                    <p className="bold"><a href={"/question/" + answer.question_info.question_id}>{answer.title}</a></p>
                  </dd>
                  <dd>
                  {
                    (tools.htmlToText(tools.htmlUnescape(answer.answer_info.answer_content)).length < 130)
                    ?
                    <p>{"回复：" + tools.htmlToText(tools.htmlUnescape(answer.answer_info.answer_content))}</p>
                    :
                    <p>
                      {"回复：" + tools.htmlToText(tools.htmlUnescape(answer.answer_info.answer_content)).slice(0, 130) + '...'}
                      <a className="Read" href={"/question/" + answer.question_info.question_id}>阅读全部</a>
                    </p>
                  }
                  </dd>
                </dl>
                : ''
                )
              })
            }
            <div className="load_more_div">
              <div className="load_more"><LoadMore perPage={ginfo.G_PER_PAGE} curRows={actionsAnswerCurCount} loadEvent={actionsAnswerMore}/></div>
            </div>
          </div>
        }
        </div>

        <div className="user-area user-actions-art" style={{display:((getPageSquare() === 'nav-actions-art') ? 'block' : 'none')}}>
        {
          ([...actionsArtList].length === 0)
          ?
          <div className="no_result"><img src={require("../../img/no_result.png")} alt=""/><p>暂无内容</p></div>
          :
          <div>
          {
            [...actionsArtList].map(art => {
              return (
              tools.isObject(art.article_info) ?
              <dl>
              <dt>
                <span>发表了文章</span>
                <label className="fr">{art.add_time}</label>
              </dt>
              <dd>
                <p className="bold"><a href={"/article/" + art.article_info.id}>{art.title}</a></p>
              </dd>
             
              <dd>
              {
                tools.htmlToText(tools.htmlUnescape(art.article_info.message)).length < 130
                ?
                <p><br/>{tools.htmlToText(tools.htmlUnescape(art.article_info.message))}</p>
                :
                <p><br/>{tools.htmlToText(tools.htmlUnescape(art.article_info.message)).slice(0, 130) + '...'}
                <a className="Read" href={"/article/" + art.article_info.id}>阅读全部</a></p>
              }
              </dd>
              <div className="foot_bq">
                <div className="zkzd"><span className="grayon"><em className="up"></em>{art.article_info.votes}</span></div>
                <label className="browse"><i>{art.article_info.views}</i>次浏览</label>
                <label className="comment"><i>{art.article_info.comments}</i>人评论</label>
              </div>
              </dl>
              : ''
              )
            })
          }
            <div className="load_more_div">
              <div className="load_more"><LoadMore perPage={ginfo.G_PER_PAGE} curRows={actionsArtCurCount} loadEvent={actionsArtMore}/></div>
            </div>
          </div>
        
        }
        </div>

        <div className="user-area user-follows-follows" style={{display:((getPageSquare() === 'nav-follows-follows') ? 'block' : 'none')}}>
        {
          ([...followsList].length === 0) 
          ?
          <div className="no_result"><img src={require("../../img/no_result.png")} alt=""/><p>暂无内容</p></div>
          :
          <div>
          {
            [...followsList].map((follow, index) => {
              return (
              <dl>
                <dd>
                  <div className="h_pic">
                    <a className="aw-user-img pull-left aw-border-radius-5" data-id={follow.uid} href={"/people/" + follow.url_token}>
                      <img src={follow.avatar_file} alt={follow.user_name} />
                    </a>
                  </div>
                  <div className="h_text">
                    <a href={"/people/" + follow.url_token}>{follow.user_name}</a>

                    <p className="text-color-999 aw-hide-txt">{tools.isEmpty(follow.signature) ? '暂无介绍' : follow.signature}</p>
                    <p><i>{follow.reputation}</i>威望 <i>{follow.agree_count}</i>赞同</p>
                  </div>
                  {
                    (ginfo.G_USER_ID === follow.uid) ? '' :
                    <div className="follow_BUT"><button id={"follow-people" + index} onClick={followPeople(follow.uid, index)}>我要关注</button></div>
                  }
                  <div className="clear"></div>
                </dd>
              </dl>
              )
            })
          }
            <div className="load_more_div">
              <div className="load_more"><LoadMore perPage={ginfo.G_PER_PAGE} curRows={followsCurCount} loadEvent={followsFollowsMore}/></div>
            </div>
          </div>
        }
        </div>

        <div className="user-area user-follows-fans" style={{display:((getPageSquare() === 'nav-follows-fans') ? 'block' : 'none')}}>
        {
          ([...fansList].length === 0)
          ?
          <div className="no_result"><img src={require("../../img/no_result.png")} alt=""/><p>暂无内容</p></div>
          :
          <div>
          {
            [...fansList].map((fan, index) => {
              return (
              <dl>
                <dd>
                  <div className="h_pic">
                    <a className="aw-user-img pull-left aw-border-radius-5" data-id={fan.uid} href={"/people/" + fan.url_token}>
                      <img src={fan.avatar_file} alt={fan.user_name} />
                    </a>
                  </div>
                  <div className="h_text">
                    <a href={"/people/" + fan.url_token}>{fan.user_name}</a>

                    <p className="text-color-999 aw-hide-txt">{tools.isEmpty(fan.signature) ? '暂无介绍' : fan.signature}</p>
                    <p><i>{fan.reputation}</i>威望 <i>{fan.agree_count}</i>赞同</p>
                  </div>
                  <div className="follow_BUT">
                    <button id={"fan-people" + index} onClick={fanPeople(fan.uid, index)}>我要关注</button>
                  </div>
                  <div className="clear"></div>
                </dd>
              </dl>
              )
            })
          }
            <div className="load_more_div">
              <div className="load_more"><LoadMore perPage={ginfo.G_PER_PAGE} curRows={fansCurCount} loadEvent={followsFansMore}/></div>
            </div>
          </div>
        }
        </div>

        <div className="user-area user-follows-topics" style={{display:((getPageSquare() === 'nav-follows-topics') ? 'block' : 'none')}}>
        {
          ([...topicsList].length === 0)
          ?
          <div className="no_result"><img src={require("../../img/no_result.png")} alt=""/><p>暂无内容</p></div>
          :
          <div>
          {
            [...topicsList].map((topic, index) => {
              return (
              <dl className="gzht">
                <dt>
                  <span>关注了话题</span><label>{topic.add_time}</label>
                </dt>
                <dd>
                  <div className="h_pic">
                    <a className="aw-topic-img pull-left aw-border-radius-5" data-id={topic.topic_id} href={"/topic/" + topic.url_token}>
                      <img src={topic.topic_pic} alt={topic.topic_title} />
                    </a>
                  </div>
                  <div className="h_text">
                    <a className="aw-topic-name" data-id={topic.topic_id} href={"/topic/" + topic.url_token}><span>{topic.topic_title}</span></a>
                    <p>7天新增<i>{topic.discuss_count_last_week}</i>个讨论，30天新增<i>{topic.discuss_count_last_month}</i>个讨论</p>
                  </div>
                      <div className="follow_BUT"><button id={"follow-topic" + index} onClick={followTopic(topic.topic_id, index)}>我要关注</button></div>
                      <div className="clear"></div>
                </dd>
              </dl>
              )
            })
          }
            <div className="load_more_div">
              <div className="load_more"><LoadMore perPage={ginfo.G_PER_PAGE} curRows={topicsCurCount} loadEvent={topicsMore}/></div>
            </div>
          </div>
        }
        </div>
      </div>
    </div>
  )
}

const RightArea = ({people, userFollows, userTopics,}) => {
  let followsFollows = e => {userFollows(people.user.uid, 'follows')}
  let followsFans = e => {userFollows(people.user.uid, 'fans')}
  let topicsFollows = e => {userTopics(people.user.uid)}
  let friendsList = (people.hasOwnProperty('friends_list') && tools.isArray(people.friends_list)) ? people.friends_list :
                    ((people.hasOwnProperty('friends_list') && tools.isObject(people.friends_list)) ? Object.values(people.friends_list) : [])
  let fansList = (people.hasOwnProperty('fans_list') && tools.isArray(people.fans_list)) ? people.fans_list :
                 ((people.hasOwnProperty('fans_list') && tools.isObject(people.fans_list)) ? Object.values(people.fans_list) : [])
  let focusTopics = (people.hasOwnProperty('focus_topics') && tools.isArray(people.focus_topics)) ? people.focus_topics :
                 ((people.hasOwnProperty('focus_topics') && tools.isObject(people.focus_topics)) ? Object.values(people.focus_topics) : [])
  return (
  <div className="right_area ">
    <div className="person_concern">
      <h4>关注&nbsp;{people.user.friend_count}&nbsp;人<a href="javascript:void(0);" onClick={followsFollows}>查看更多</a></h4>
      <ul>
        {
          [...friendsList].map(friend => {
            return (
              <li>
                <a className="aw-user-name" data-id={friend.uid} href={"/people/" + friend.url_token}><img src={friend.avatar_file} alt={friend.user_name}/></a>
              </li>
            )
          })
        }
        <div className="clear"></div>
      </ul>
      <div className="t_line"></div>
      <h4>被&nbsp;{people.user.fans_count}&nbsp;人关注<a href="javascript:void(0);" onClick={followsFans}>查看更多</a></h4>
      <ul>
        {
          [...fansList].map(fan => {
            return (
              <li>
                <a className="aw-user-name" data-id={fan.uid} href={"/people/" + fan.url_token}><img src={fan.avatar_file} alt={fan.user_name}/></a>
              </li>
            )
          })
        }
        <div className="clear"></div>
      </ul>
    </div>

    <div className="right_Notice">
        <h4>关注&nbsp;{people.user.topic_focus_count}&nbsp;话题<a href="javascript:void(0);" onClick={topicsFollows}>查看更多</a></h4>
        <div className="Topic">
        {
          [...focusTopics].map(topic => <a href={"/topic/" + topic.url_token} className="text" data-id={topic.topic_id}>{topic.topic_title}</a>)
        }
        </div>
    </div>

    <div className="person_concern">
        <h4>主页访问量&nbsp;{people.user.views_count}</h4>
        <p>最后登录时间: {people.user.last_login}</p>
    </div>
  </div>
  )
}

class PeoplePageMain extends Component {

  constructor(props) {
    super(props)
    this.whereSquare = 'nav-actions-all'
    this.setPageSquare = this.setPageSquare.bind(this)
    this.getPageSquare = this.getPageSquare.bind(this)
  }

  setPageSquare(where) {this.whereSquare = where}
  getPageSquare() {return this.whereSquare}

  render() {
    let {ginfo, people, userActions, userActionsMore, userFollows, userFollowsMore, userTopics, userTopicsMore} = this.props
    return (
    <div className="w1150 Main_area">
      <LeftNav people={people} userActions={userActions} userFollows={userFollows} userTopics={userTopics} setPageSquare={this.setPageSquare} getPageSquare={this.getPageSquare}/>
      <MiddleArea ginfo={ginfo} people={people} userActionsMore={userActionsMore} userFollowsMore={userFollowsMore} userTopicsMore={userTopicsMore} getPageSquare={this.getPageSquare}/>
      <RightArea  ginfo={ginfo} people={people}/>
      <div className="clear"></div>
    </div>)
  }
}

export default PeoplePageMain
