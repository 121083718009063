/*
const initialState = {
  ginfo:{ G_SITE_NAME:'', G_UPLOAD_URL:'', G_PER_PAGE:'' ...},
  square:{
    home: {},
    question: {},
    article:{},
    topic:{},
    people:{}
    ...
  }
  question: {
    thisUser: {},
    question: {}
  },
  article: {
    thisUser: {},
    article: {}
  }
  topic: {
    all_list: [],
    topic_recommend_list: [],
    best_questions_list: [],
    all_questions_list: [],
    articles_list: [],
    this_user: {}
  },
  people: {
    user: {},
    ....
    user_actions_content: {
      101: {}, 201: {}, 501: {}, all: {}
    }
    user_follows: {
      follows: {}, fans: {}
    }
    user_topics: {}
  }
  search: {
    status:{cur_result_rows:0, raw_query:'', query:'', type:'', page:1, is_recommend:1},
    result:
    [
      {uid:"", score:"", type:"", ...},
      {uid:"", score:"", type:"", ...},
    ]
  },
  publish: {
    question:{},
    article:{}
  },
  notifications: {...}
  account: {...}
  setting: {...}
} */

const initer = store => {
  //ginfo
  store.getState().ginfo = {}

  //question
  store.getState().question = {}
  store.getState().question.thisUser = {}
  store.getState().question.question = {}

  //topic
  store.getState().topic = {}

  //search
  store.getState().search = {}
  store.getState().search.status = { 
    cur_result_rows: 0, 
    raw_query: '', 
    query: '', 
    type: '', 
    page: 1, 
    is_recommend: 1
  }
  store.getState().search.result = []

  //publish
  store.getState().publish = {}
  store.getState().publish.question = {}
  store.getState().publish.article = {}
}

export default initer