import thunk from 'redux-thunk'
import {createStore, combineReducers, applyMiddleware} from 'redux'
import {ginfo, square, question, article, topic, people, search, publish, notifications, inbox, account, setting} from '../controller/reducers'

const logger = store => next => action => {
  let result
  console.groupCollapsed("dispatching", action.type)
  console.log("pre state", store.getState())
  console.log("action", action)
  result = next(action)
  console.log("next state", store.getState())
  console.groupEnd()
  return result
}

const middleware = () => [
  logger,
  thunk
]
const storeFactory = (initialState = {}) =>
  applyMiddleware(...middleware())(createStore) (
    combineReducers({ginfo, square, question, article, topic, people, search, publish, notifications, inbox, account, setting}),
    initialState
  )

const store = storeFactory()

export default store