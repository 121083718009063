/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';
import * as tools from '../controller/tools'
import LoadMore from './LoadMore'

const LeftNav = ({topic, setTab, getTab}) => {
  let topicInfo = topic.hasOwnProperty('topic_info') ? topic.topic_info : {}
  let tab = type => e => {
    window.$('.tab-nav').removeClass('Select')
    window.$('#tab-'+type).addClass('Select')
    window.$('.content').hide()
    window.$('#content-'+type).show()
    setTab(type)
  }
  return (
  <div className="left_nav">
    <dl className="nav9 cur">
      <dt><a href="javascript:void(0);">{topicInfo.topic_title}</a></dt>
      <dd>
        <a  role="tab" className={'tab-nav ' + ((getTab() ==='all') ? 'Select' : '')} id="tab-all" href="javascript:void(0);" onClick={tab('all')}>全部</a>
        <a  role="tab" className={'tab-nav ' + ((getTab() ==='best') ? 'Select' : '')} id="tab-best" href="javascript:void(0);" onClick={tab('best')}>精华</a>
        <a  role="tab" className={'tab-nav ' + ((getTab() ==='ques') ? 'Select' : '')} id="tab-ques" href="javascript:void(0);" onClick={tab('ques')} >问题</a>
        <a  role="tab" className={'tab-nav ' + ((getTab() ==='art') ? 'Select' : '')} id="tab-art" href="javascript:void(0);" onClick={tab('art')}>文章</a>
        <a  role="tab" className={'tab-nav ' + ((getTab() ==='about') ? 'Select' : '')} id="tab-about" href="javascript:void(0);" onClick={tab('about')}>关于话题</a>
      </dd>
    </dl>
  </div>
  )
}

const MiddleArea = ({ginfo, topic, more, getTab}) => {
  let allList = topic.hasOwnProperty('all_list') ? (tools.isArray(topic.all_list) ? topic.all_list : []) : []
  let curAllListCount = topic.hasOwnProperty('cur_all_list_count') ? topic.cur_all_list_count : allList.length
  let bestQuesList = topic.hasOwnProperty('best_questions_list') ? (tools.isArray(topic.best_questions_list) ? topic.best_questions_list : []) : []
  let curBestQuesCount = topic.hasOwnProperty('cur_best_ques_count') ? topic.cur_best_ques_count : bestQuesList.length
  let allQuesList = topic.hasOwnProperty('all_questions_list') ? (tools.isArray(topic.all_questions_list) ? topic.all_questions_list : []) : []
  let curAllQuesCount = topic.hasOwnProperty('cur_all_ques_count') ? topic.cur_all_ques_count : allQuesList.length
  let artList = topic.hasOwnProperty('articles_list') ? (tools.isArray(topic.articles_list) ? topic.articles_list : []) : []
  let curArtListCount = topic.hasOwnProperty('cur_art_list_count') ? topic.cur_art_list_count : artList.length
  return (
  <div className="Middle_area">
      <div className="wenda">
        {/* 全部 */}
        <div className="content" id="content-all" style={{display: (getTab() === 'all') ? 'block' : 'none'}}>
        {
          ([...allList].length === 0)
          ?
          <div className="no_result"><img src={require("../../img/no_result.png")} alt=""/><p>暂无内容</p></div>
          :
          <div>
          {
            [...allList].map(item => {
              let userInfo = item.hasOwnProperty('user_info') ? item.user_info : {}
              return (
              <dl>
                <dt>
                {
                  (item.question_id > 0) ?
                  (
                    (item.anonymous === 0) ? 
                    <a href={"/people/" + userInfo.url_token} className="aw-user-name" data-id={userInfo.uid}>
                    <img src={userInfo.avatar_file} alt={userInfo.user_name}/>{userInfo.user_name}</a>
                    :
                    <a href="javascript:void(0);" className="aw-user-name"><img src={require("../../img/avatar-mid-img.png")} alt="匿名用户"/>匿名用户</a>
                  ) :
                  <a href={"/people/" + userInfo.url_token} className="aw-user-name" data-id={userInfo.uid}>
                  <img src={userInfo.avatar_file} alt={userInfo.user_name}/>{userInfo.user_name}</a>
                }
                {
                  (item.question_id > 0) ? 
                  (item.answer_count > 0 ? <span>回复了问题（{item.answer_count} 个回复）</span>: <span>发起了问题</span>) : <span>发表了文章</span>
                }
                {
                  (item.question_id > 0) ?
                  (item.answer_count > 0 ? <label>{item.update_time}</label> : <label>{item.add_time}</label>) : <label>{item.add_time}</label>
                }
                </dt>
  
                <dd>
                {
                  (item.question_id > 0) ?
                  <p className="bold"><a href={"/question/" + item.question_id}>{item.question_content}</a></p>
                  :
                  <p className="bold">
                  {
                    tools.htmlToText(tools.htmlUnescape(item.message)).length < 130
                    ?
                    <p>{tools.htmlToText(tools.htmlUnescape(item.message))}</p>
                    :
                    <p>{tools.htmlToText(tools.htmlUnescape(item.message)).slice(0, 130) + '...'}
                    <a className="Read" style={{color:'#6098f5'}} href={"/article/" + item.id}>阅读全部</a></p>
                  }
                  </p>
                }
                </dd>
                
                <dd>
                  <label className="browse" style={{pointerEvents:'none'}}><i>{item.view_count} 次浏览</i></label>
                  <label className="follow" style={{pointerEvents:'none'}}><i>{item.focus_count}</i>人关注</label>
                </dd>
              </dl>
              )
            })
          }
          <dl style={{paddingBottom:'50px'}}><LoadMore perPage={ginfo.G_PER_PAGE} curRows={curAllListCount} loadEvent={e=>more('all')}/></dl>
          </div>
        }
        </div>

        {/* 精华 */}
        <div className="content" id="content-best" style={{display: (getTab() === 'best') ? 'block' : 'none'}}>
        {
          ([...bestQuesList].length === 0)
          ?
          <div className="no_result"><img src={require("../../img/no_result.png")} alt=""/><p>暂无内容</p></div>
          :
          <div>
          {
            [...bestQuesList].map(ques => {
              let userInfo = ques.hasOwnProperty('user_info') ? ques.user_info : {}
              return (
              <dl data-history-id="" className="aw-item">
                <dt>
                  {
                    (ques.anonymous === 0) ? 
                    <a href={"/people/" + userInfo.url_token} className="aw-user-img aw-border-radius-5" data-id={userInfo.uid}>
                    <img src={userInfo.avatar_file} alt={userInfo.user_name}/>{userInfo.user_name}</a>
                    :
                    <a href="javascript:void(0);" className="aw-user-name"><img src={require("../../img/avatar-mid-img.png")} alt="匿名用户"/>匿名用户</a>
                  }
                  {
                    
                  }
                  <span>发起了问题（{ques.answer_count} 个回复）</span>
                  <label>{ques.answer_count > 0 ? ques.update_time : ques.add_time}</label>
                </dt>
                <dd><p className="bold"><a href={"/question/" + ques.question_id}>{ques.question_content}</a></p></dd>
              </dl>
              )
            })
          }
          <dl style={{paddingBottom:'50px'}}><LoadMore perPage={ginfo.G_PER_PAGE} curRows={curBestQuesCount} loadEvent={e=>more('best')}/></dl>
          </div>
        }
        </div>
       
        {/* 问题 */}
        <div className="content" id="content-ques" style={{display: (getTab() === 'ques') ? 'block' : 'none'}}>
        {
          ([...allQuesList].length === 0)
          ?
          <div className="no_result"><img src={require("../../img/no_result.png")} alt=""/><p>暂无内容</p></div>
          :
          <div>
          {
            [...allQuesList].map(ques => {
              let userInfo = ques.hasOwnProperty('user_info') ? ques.user_info : {}
              return (
              <dl>
                <dt>
                {
                  (ques.anonymous === 0) ? 
                  <a href={"/people/" + userInfo.url_token} className="aw-user-name" data-id={userInfo.uid}>
                  <img src={userInfo.avatar_file} alt={userInfo.user_name}/>{userInfo.user_name}</a>
                  :
                  <a href="javascript:void(0);" className="aw-user-name"><img src={require("../../img/avatar-mid-img.png")} alt="匿名用户"/>匿名用户</a>
                }
                {
                  ques.answer_count > 0 ? <span>回复了问题（{ques.answer_count} 个回复）</span>: <span>发起了问题</span>
                }
                {
                  ques.answer_count > 0 ? <label>{ques.update_time}</label> : <label>{ques.add_time}</label>
                }
                </dt>
                <dd><p className="bold"><a href={"/question/" + ques.question_id}>{ques.question_content}</a></p></dd>
                <dd>
                  <label className="browse" style={{pointerEvents:'none'}}><i>{ques.view_count} 次浏览</i></label>
                  <label className="follow" style={{pointerEvents:'none'}}><i>{ques.focus_count}</i>人关注</label>
                </dd>
              </dl>
              )
            })
          }
          <dl style={{paddingBottom:'50px'}}><LoadMore perPage={ginfo.G_PER_PAGE} curRows={curAllQuesCount} loadEvent={e=>more('ques')}/></dl>
          </div>
        }
        </div>
       
       {/* 文章 */}
       <div className="content" id="content-art" style={{display: (getTab() === 'art') ? 'block' : 'none'}}>
        {
          ([...artList].length === 0)
          ?
          <div className="no_result"><img src={require("../../img/no_result.png")} alt=""/><p>暂无内容</p></div>
          :
          <div>
          {
            [...artList].map(art => {
              let userInfo = art.hasOwnProperty('user_info') ? art.user_info : {}
              return (
              <dl>
                <dt>
                  <a href={"/people/" + userInfo.url_token} className="aw-user-name" data-id={userInfo.uid}>
                  <img src={userInfo.avatar_file} alt={userInfo.user_name}/>{userInfo.user_name}</a>
                  <span>发表了文章</span>
                  <label>{art.add_time}</label>
                </dt>
                <dd><p className="bold">
                {
                  tools.htmlToText(tools.htmlUnescape(art.message)).length < 130
                  ?
                  <p>{tools.htmlToText(tools.htmlUnescape(art.message))}</p>
                  :
                  <p>{tools.htmlToText(tools.htmlUnescape(art.message)).slice(0, 130) + '...'}
                  <a className="Read" style={{color:'#6098f5'}} href={"/article/" + art.id}>阅读全部</a></p>
                }
                </p></dd>
                <dd>
                  <label className="browse" style={{pointerEvents:'none'}}><i>{art.view_count} 次浏览</i></label>
                  <label className="follow" style={{pointerEvents:'none'}}><i>{art.focus_count}</i>人关注</label>
                </dd>
              </dl>
              )
            })
          }
          <dl style={{paddingBottom:'50px'}}><LoadMore perPage={ginfo.G_PER_PAGE} curRows={curArtListCount} loadEvent={e=>more('art')}/></dl>
          </div>
        }
        </div>

        {/* 关于话题 */}
        <div style={{marginTop:'20px'}}>
          <div className="content" id="content-about" style={{display: (getTab() === 'about') ? 'block' : 'none'}}>
            {topic.hasOwnProperty('topic_info') ? topic.topic_info.topic_description : ''}
          </div>
        </div>
      </div>
  </div>  
  )
}

const Searcher = props => {
  return (
    <div className="right_ser">
      <div className="top_search">
          <div className="navbar-search-ch" id="global_search_form_ch">
              <input className="form-control search-query" autocomplete="off" name="q" id="aw-search-query-ch" type="text" placeholder="搜索..."/>
              <button disabled="disabled"></button>
              <div className="search_result aw-dropdown" style={{zIndex:'999'}}>
                  <p className="title" style={{marginTop:'0px'}}>输入关键字进行搜索</p>
                  <div className="aw-dropdown-list" style={{maxHeight:'202px', overflowY:'auto'}}></div>
              </div>
          </div>
          <div className="clear"></div>
      </div>
    </div>
  )
}

const BetterAnswer = ({topic}) => {
  let bestAnswerUsers = topic.hasOwnProperty('best_answer_users') ? (tools.isObject(topic.best_answer_users) ? topic.best_answer_users : {}) : {}
  return (
    <div className="right_Notice">
    <h4>最佳回复者</h4>
    {
      [...Object.values(bestAnswerUsers)].map((user, i) => {
        let userInfo = user.hasOwnProperty('user_info') ? user.user_info : {}
        return (
        <dl>
          <dt><span>{i + 1 }</span><a href={"/people/" + userInfo.url_token}><img src={userInfo.avatar_file} alt=""/></a></dt>
          <dd>
              <a className="aw-user-name" href={"/people/" + userInfo.url_token} data-id={userInfo.uid} >{userInfo.user_name}</a>
              <p>获得<i>{user.agree_count}</i>次赞同</p>
          </dd>
          <div className="clear"></div>
        </dl>
        )
      })
    }
    </div>
  )
}

const TopicFocus = ({topic}) => {
  let topicInfo = topic.hasOwnProperty('topic_info') ? topic.topic_info : {}
  let focusUsers = topic.hasOwnProperty('focus_users') ? (tools.isObject(topic.focus_users) ? topic.focus_users : {}): {}
  return (
  <div className="person_concern">
      <h4>{topicInfo.focus_count} 人关注该话题</h4>
      <ul>
        {
          [...Object.values(focusUsers)].map(user =><li><a href={user.url}><img src={user.avatar_file} alt={user.user_name}/></a></li>)
        }
        <div className="clear"></div>
      </ul>
  </div>
  )
}

const RightArea = ({topic}) =>
  <div className="right_area">
    <Searcher topic={topic}/>
    <BetterAnswer topic={topic}/>
    <TopicFocus topic={topic}/>
  </div>

class TopicPageMain extends Component {

  constructor(props) {
    super(props)
    this.whereTab = 'all'
    this.setTab = this.setTab.bind(this)
    this.getTab = this.getTab.bind(this)
  }

  setTab(where) {this.whereTab = where}
  getTab() {return this.whereTab}

  render() {
    let {ginfo, topic, more} = this.props
    return (
    <div className="w1150 Main_area">
      <LeftNav topic={topic} setTab={this.setTab} getTab={this.getTab}/>
      <MiddleArea ginfo={ginfo} topic={topic} more={more} getTab={this.getTab}/>
      <RightArea topic={topic}/>
      <div className="clear"></div>
    </div>
    )
  }
}

export default TopicPageMain
