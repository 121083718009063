/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import LoadMore from './LoadMore'

const LeftNav = ({noti}) => {
  let notiList = noti.hasOwnProperty('list') ? (noti.list ? noti.list : []) : []
  const readNoti = e => {window.AWS.Message.read_notification(true, 0, true)}
  return (
    <div className="left_nav">
      <dl className="nav2 "><dt><Link to="/question">问答</Link></dt></dl>
      <dl className="nav4 "><dt><Link to="/topic">话题</Link></dt></dl>
      <dl className="nav5 cur">
        <dt>
          <a href="javascript:void(0);">{'通知 ' + [...notiList].length}</a>
          <i className="del" style={{cursor:'pointer'}} onClick={readNoti}>全部已读</i>
        </dt>
        <dd>
          <a className="Select" href="javascript:void(0);">通知内容</a>
          <a href="/setting/privacy/">通知设置</a>
        </dd>
      </dl>
	  </div>
  )
}

const NotiMiddle = ({ginfo, noti, more}) => {
  let notiList = noti.hasOwnProperty('list') ? (noti.list ? noti.list : []) : []
  const message = (item) => {
    let oDiv = document.createElement('div');
    oDiv.innerHTML = item.message;
    let aTags = oDiv.getElementsByTagName('a');
    for(let i = 0, len = aTags.length; i < len; i++){
      oDiv.removeChild(aTags[0]);
    }
    return oDiv.innerHTML
  }
  const readNoti = (index, id)=> e => {
    let url = ginfo.G_BASE_URL + '/notifications/ajax/read_notification/notification_id-' + id
    window.$.get(url, function (result){
        window.AWS.Message.check_lime_notifications()
    })
    window.$('#read_noti_' + index).html('已读')
  }
  return (
    <div className="Middle_area ">
    {
      ([...notiList].length === 0) 
      ?
      <div class="wenda">
        <div class="no_result">
          <img src={require("../../img/no_result.png")} alt="no result"/>
          <p>暂无内容</p>
        </div>
      </div>
      :
			<div className="y_notice">
				<ul>
          {
            [...notiList].map((item,index) => {
              return (
              <li>
                <em></em>
                <a href={item.p_url && item.p_url.replace(ginfo.G_BASE_URL,'')}>{item.anonymous ? '匿名用户': (item.p_user_name ? item.p_user_name : '')}</a>
                <span>{message(item)}</span> 
                <a href={item.key_url && item.key_url.replace(ginfo.G_BASE_URL,'')}>{(item.title ? item.title : '')}</a>
                <label>{(item.add_time ? item.add_time : '')}</label>
                <div className="clear"></div>
                <p>
                {
                  item.read_flag ? <label className="browse">已读</label> :
                  <label className="browse" id={"read_noti_" + index}>
                    <a className="read pull-right" onClick={readNoti(index, item.notification_id)}>设为已读</a>
                  </label>
                }
                </p>
              </li>
              )
            })
          }
          <li style={{marginBottom: '20px'}}><LoadMore perPage={noti.per_page} curRows={noti.cur_noti_num} loadEvent={more}/></li>
				</ul>
			</div>
    }
		</div>
  )
}

const NotiRight = ({noti}) => {
  let recommend = noti.hasOwnProperty('recommend_users_topics') ? (noti.recommend_users_topics ? noti.recommend_users_topics : []) : []
  let recommendTopics = recommend.filter(value => value.topic_id)
  let recommendUsers = recommend.filter(value => !value.topic_id)
  return (
    <div className="right_area ">
			<div className="right_Notice">
		    <h4>可能感兴趣的人<a href="/people">查看更多</a></h4>
        {
          recommendUsers.map(u => {
            return (
            <dl>
              <dt><a href={"/people/" + u.url_token} data-id={u.uid} class="aw-user-name"><img alt={u.user_name} src={u.avatar_file}/></a></dt>
              <dd>
                <a href={"/people/" + u.url_token} data-id={u.uid} class="aw-user-name">{u.user_name}</a>
                {
                  !u.hasOwnProperty('type') ? '' : (
                    (u.type === 'friend') 
                    ? 
                    <p><a href={"/people/"+ u.friend_users.url_token} class="aw-user-name" data-id={u.friend_users.uid} style={{fontSize:'12px'}}>{u.friend_users.user_name}</a> 关注了他</p>
                    :
                    ((u.type === 'topic') ? <p>他也关注<a href={"/topic/"+ u.topic_info.url_token} style={{fontSize:'12px'}}>{u.topic_info.topic_title}</a></p> : '')
                  )
                }
              </dd>
              <div class="clear"></div>
            </dl>
            )
          })
        }
			</div>

			<div className="right_Notice">
		    <h4>可能感兴趣的话题<a href="/topic">查看更多</a></h4>
	      <div className="Topic">
          {
            recommendTopics.map(t => {
              return (
                <a href={"/topic/" + t.url_token} className="text">{t.topic_title}</a>
              )
            })
          }
			  </div>
	    </div>
	  </div>
  )
}

class NotiMain extends Component {
  render() {
    const {ginfo, notifications, more} = this.props
    return (
      <div className="w1150 Main_area">
        <LeftNav noti={notifications}/>
        <NotiMiddle ginfo={ginfo} noti={notifications} more={more}/>
        <NotiRight noti={notifications}/>
        <div className="clear"></div>
      </div>
    )
  }
}

export default NotiMain
