/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';

class LoadMore extends Component {

  constructor(props) {
    super(props)
    this.more = this.more.bind(this)
    this.done = this.done.bind(this)
  }

  more() {
    const {_more, _loading} = this.refs
    const {loadEvent} = this.props
    _more.style.display = 'none'
    _loading.style.display = 'block'
    loadEvent()
  }

  done() {
    const {_loading, _more, _empty} = this.refs
    const {perPage, curRows} = this.props
    _loading.style.display = 'none'
    if (curRows < perPage){
      _more.style.display = 'none'
      _empty.style.display = 'block'
    } else {
      _more.style.display = 'block'
      _empty.style.display = 'none'
    }
  }

  componentDidMount(){
    this.done()
  }

  componentDidUpdate(){
    this.done()
  }

  render() {
    return (
    <dl className="auto-load-more">
      <dd ref="_loading" className="auto-loading" style={{display:'block'}}>
        <span><img src={require("../../img/loading_b.gif")} alt=""/> 正在加载... </span>
      </dd>
      <dd ref="_more" className="auto-more" style={{display:'none'}} onClick={this.more}>
        <a href="javascript:void(0)">
          <span>加载更多<img src={require("../../img/load-more.png")} alt=""/></span>
        </a>
      </dd>
      <dd ref="_empty" className="auto-empty" style={{display:'none'}}><span>没有更多了</span></dd>
    </dl>
    )
  }
}

export default LoadMore;
