/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import * as tools from '../controller/tools'

const PeopleBox = props => {
  let {ginfo, people} = props
  let user = people.hasOwnProperty('user') ? people.user : {}
  let askPublish = e => {
    let options = {
      category_enable: (people.category_enable === 'Y' ? '1' : '0'), 
      ask_user_id: user.uid, 
      ask_user_name: user.user_name
    }
    window.AWS.dialog('ask_publish', options);
  }
  let privateLetter = e => {window.AWS.dialog('inboxs', user.user_name);}
  let follow = e => {window.follow(window.$('#person_follow'), 'user', user.uid);}
  return (
  <div className="personal_top">
    <div className="w1150">
        <dl>
            <dt><a href={"/people/" + user.url_token} className="aw-user-name" data-id={user.uid}>
              <img src={user.avatar_file} alt={user.user_name} /></a>
            </dt>
            <dd>
                <h1>{user.user_name}{/*<span class="money">2851.6952</span>*/}</h1>
                <h5>{tools.isEmpty(user.introduction) ? '暂无介绍' : user.introduction}</h5>
                {/*<h4>YOYOW号：483807009</h4>*/}
                <p><span><i>{user.reputation}</i>威望</span><span><i>{user.agree_count}</i>赞同</span></p>
            </dd>
            {
              (ginfo.G_USER_ID === user.uid) ? '' :
              <dd className="but">
                <a className="w60" href="javascript:void(0);" onClick={askPublish}>@问他</a>
                <a className="w60" id="privateletter" href="javascript:void(0);" onClick={privateLetter}>私信</a>
                <a className="gz_but w100" href="javascript:void(0);" id="person_follow" onClick={follow}>{(people.user_follow_check) ? '取消关注' : '+ 关注'}</a>
              </dd>
            }
            <div className="clear"></div>
        </dl>
    </div>
  </div>
  )
}
export default PeopleBox
