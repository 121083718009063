
export const BASE = "https://kchain.com"

export const URL= {
  BASE_URL: BASE + "/?/",
  BASE_API: BASE + "/?/api/",
  //ginfo
  GINFO_API: BASE + "/?/api/ginfo/",
  //home
  HOME_API: BASE + "/?/api/home/",
  //questions
  QUESTIONS_API: BASE + "/?/api/question/",
  QUESTION_ANSWERS_API: BASE + "/?/api/question/answers/",
  //articles
  ARTICLES_API: BASE + "/?/api/article/",
  //topics
  TOPICS: BASE + "/?/topic/",
  TOPICS_API: BASE + "/?/api/topic/",
  //people
  PEOPLE: BASE + "/?/people/",
  PEOPLE_API: BASE + "/?/api/people/",
  USER_ACTIONS_API: BASE + "/?/api/people/user_actions/",
  USER_FOLLOWS_API: BASE + "/?/api/people/follows/",
  USER_TOPICS_API: BASE + "/?/api/people/topics/",
  //search
  SEARCH_API: BASE + "/?/api/search/",
  //publish
  PUBLISH_QUES_API: BASE + "/?/api/publish/",
  PUBLISH_ARTICLE_API: BASE + "/?/api/publish/article/",
  //notifications
  NOTIFICATIONS_API: BASE + "/?/api/notifications/",
  //inbox
  INBOX_API: BASE + "/?/api/inbox/",
  INBOX_READ_API: BASE + "/?/api/inbox/read/",
  //account
  ACCOUNT_API: BASE + "/?/api/account/",
  //setting
  SETTING_API: BASE + "/?/api/setting/"
}

export const CMD = {
  //ginfo
  ADD_GLOBAL_INFO: "ADD_GLOBAL_INFO",
  //square
  BUILD_HOME_SQUARE: "BUILD_HOME_SQUARE",
  ADD_HOME_RESULT: "ADD_HOME_RESULT",
  BUILD_QUES_SQUARE:"BUILD_QUES_SQUARE",
  ADD_QUES_RESULT:"ADD_QUES_RESULT",
  BUILD_ART_SQUARE:"BUILD_ART_SQUARE",
  ADD_ART_RESULT:"ADD_ART_RESULT",
  BUILD_TOPIC_SQUARE:"BUILD_TOPIC_SQUARE",
  ADD_TOPIC_RESULT:"ADD_TOPIC_RESULT",
  BUILD_PEOPLE_SQUARE:"BUILD_PEOPLE_SQUARE",
  ADD_PEOPLE_RESULT:"ADD_PEOPLE_RESULT",
  //question
  CHANGE_QUES_PAGE:"CHANGE_QUES_PAGE",
  ADD_ANSWERS_RESULT:"ADD_ANSWERS_RESULT",
  //article
  CHANGE_ARTICLE_PAGE: "CHANGE_ARTICLE_PAGE",
  ADD_COMMENT_RESULT: "ADD_COMMENT_RESULT",
  //topic
  CHANGE_TOPIC_PAGE: "CHANGE_TOPIC_PAGE",
  ADD_ALL_LIST_RESULT: "ADD_ALL_LIST_RESULT",
  ADD_BEST_QUES_RESULT: "ADD_BEST_QUES_RESULT",
  ADD_ALL_QUES_RESULT: "ADD_ALL_QUES_RESULT",
  ADD_ART_LIST_RESULT: "ADD_ART_LIST_RESULT",
  //people
  CHANGE_PEOPLE_PAGE: "CHANGE_PEOPLE_PAGE",
  CHANGE_USER_ACTIONS: "CHANGE_USER_ACTIONS",
  ADD_USER_ACTIONS: "ADD_USER_ACTIONS",
  CHANGE_USER_FOLLOWS: "CHANGE_USER_FOLLOWS",
  ADD_USER_FOLLOWS: "ADD_USER_FOLLOWS",
  CHANGE_USER_TOPICS: "CHANGE_USER_TOPICS",
  ADD_USER_TOPICS: "ADD_USER_TOPICS",
  //search
  UPDATE_SEARCH_STATUS:"UPDATE_SEARCH_STATUS",
  ADD_SEARCH_RESULT: "ADD_SEARCH_RESULT",
  CHANGE_SEARCH_CONTENT: "CHANGE_SEARCH_CONTENT",
  //publish
  CHANGE_PUBLISH_QUESTION: "CHANGE_PUBLISH_QUESTION",
  CHANGE_PUBLISH_ARTICLE: "CHANGE_PUBLISH_ARTICLE",
  //notifications
  CHANGE_NOTI_CONTENT: "CHANGE_NOTI_CONTENT",
  ADD_NOTI_RESULT: "ADD_NOTI_RESULT",
  //inbox
  CHANGE_INBOX_CONTENT: "CHANGE_INBOX_CONTENT",
  ADD_INBOX_RESULT: "ADD_INBOX_RESULT",
  ADD_DIALOG_RESULT: "ADD_DIALOG_RESULT",
  //account
  CHANGE_ACCOUNT_CONTENT:"CHANGE_ACCOUNT_CONTENT",
  //setting
  CHANGE_SETTING_CONTENT:"CHANGE_SETTING_CONTENT"
}
