import React from 'react';

const Footer = props =>
    <div class="foot_box">
        <div id="aw-ajax-box" className="aw-ajax-box"></div> {/* Don't remove */}
        <div class="w1150">
            <div class="foot_logo">
                <img src={require("../../img/logo2.png")} alt=""/>
            </div>
            <div class="foot_top">
                <div class="foot_link">
                    <ul>
                        <li> <a target="_blank" href="/">浩瀚官网</a></li>
                        <li> <a target="_blank" href="/" >HelloEOS</a></li>
                        <li> <a target="_blank" href="/" >IMEOS</a></li>
                        <li> <a target="_blank" title="知识链" href="/" >知识图谱</a></li>
                        <li class="sqlj"><a target="_blank" href="/" >其他链接</a></li>
                    </ul>
                </div>
                <div class="Customer_service">
                    <dl>
                        <dt><img src={require("../../img/qqx.jpeg")} alt="" /></dt>
                        <dd>商务合作</dd>
                    </dl>
                    <dl>
                        <dt><img src={require("../../img/wex.jpeg")} alt=""/></dt>
                        <dd>微信客服</dd>
                    </dl>
                </div>
                <div class="clear"></div>
            </div>
                
            <div class="foot_bottom">
                <p>Copyright © 2021<span class="hidden-xs"> - <a style={{color:"#CCCCCC"}} href="../index-1.htm" >触角</a> - 京ICP备18027706号-1,All Rights Reserved</span>

                    <span class="hidden-xs" style={{color:"#6098f5"}}></span>
                </p>
            </div>
        </div>
    </div>

export default Footer;
