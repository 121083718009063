/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */
/* eslint-disable no-useless-escape */
import 'core-js'
import 'fetch-detector'
import 'fetch-ie8'
require('es6-promise').polyfill()
/* import fetch from 'isomorphic-fetch' */

/**
 * 异步http请求并处理响应
 */
const logError = error => console.error(error)
const parseResponse = response => response.json()
const handleResponse = cb => json => (typeof cb === "function") ? cb(json) : json
export const httpRequest = (handle, url, method = 'GET', body = {}) => {
  let formData = new FormData();
  for (let key in body) {
    formData.append(key, body[key])
  }
  let postops = { 
    method: 'POST', 
    body: formData, 
    headers:{'Accept':'application/json'}
  }
  let getops = {method: 'GET', headers: postops.headers}
  let ops = (method === 'GET') ? getops : postops

  return fetch(url, ops).then(parseResponse).then(handleResponse(handle)).catch(logError)
}

/**
 * 字符串清除空格
 */
export const ltrim = s => s.replace(/^\s*/g,'') //左空格
export const rtrim = s => s.replace(/\s*$/g,'')  //右空格
export const trim = s => s.replace(/(^\s*)|(\s*$)/g,'') //两边空格

/**
 * 判断变量是否为空
 */
export const isEmpty = s => (s === '' || s === undefined || s == null) ? true : false

/**
 * 判断字符串是否是数字
 */
export const isNumber = s => (/^\d+$/.test(s))

/**
 * 判断对象是否是对象类型
 */
export const isObject = o => Object.prototype.toString.call(o) === "[object Object]"

/**
 * 判断对象是否是数组类型
 */
export const isArray = o => {
  return (typeof Array.isArray === "function") ? Array.isArray(o) : Object.prototype.toString.call(o) === "[object Array]"
}

/**
 * 判断是否为数组中的元素
 */
export const inArray = (val, arr) => {
  if (isEmpty(val) || (!isArray(arr))) {
    return false
  }
  return arr.some(v => val.toString() === v.toString())
}

/**
 * 解析编码后的html文本
 */
export const htmlUnescape = content => {
  let temp = document.createElement('div')   
  temp.innerHTML = content     
  return temp.innerText || temp.textContent; 
}

/**
 * 将HTML字符串里面的文本字符检出
 */
export const htmlToText = html => {
  if(typeof html !== "string") {
    return ""
  }
  return html.replace(/(^\s*)|(\s*$)/g, "").replace(/<[^<^>]*>/g, "").replace(/[\r\n]/g, "")
}

/**
 * 截取带HTML样式的字符串，并保留并自动补齐HTML标签
 * oHtml  将要截取的HTML字符串
 * nlen   截取后的长度，包含标签之间的空格
 * isByte 是否按照字节长度截取
 */
export const subHtml = (oHtml, nlen, isByte) => {
  var rgx1 = /<[^<^>^\/]+>/;      //前标签(<a>的href属性中可能会有“//”符号，先移除再判断)
  var rgx2 = /<\/[^<^>^\/]+>/;    //后标签
  var rgx3 = /<[^<^>^\/]+\/>/;    //自标签
  var rgx4 = /<[^<^>]+>/;         //所有标签
  var selfTags = "hr,br,img,input,meta".split(",");

  const inArray = (v, a) => {
    for(i = 0; i < a.length; i++) {
      if(a[i] === v){
          return true;
      }
    }
    return false;
  }

  if(typeof oHtml !== "string"){
      return "";
  }
  oHtml = oHtml.replace(/(^\s*)|(\s*$)/g, "").replace(/[\r\n]/g, "");
  var oStr = oHtml.replace(/<[^<^>]*>/g, "");
  var olen = isByte ? oStr.replace(/[^\x00-\xff]/g,"**").length : oStr.length;
  if(!/^\d+$/.test(nlen) || olen <= nlen){
      return oHtml;
  }
  var tStr = oHtml;
  var index = 0;
  var matchs = [];
  while(rgx4.test(tStr)){
      var m = {};
      m.index = index + tStr.search(rgx4);
      m.string = tStr.match(rgx4).toString();
      var len = tStr.search(/<[^<^>]+>/)+tStr.match(/<[^<^>]+>/)[0].length;
      tStr = tStr.substr(len);
      index += len;
      matchs.push(m);
  }
  if(isByte){
      var i=0;
      for(var z = 0; z < oStr.length; z++){
          i += (oStr.charCodeAt(z) > 255) ? 2 : 1;
          if(i >= nlen){
              tStr=oStr.slice(0,(z + 1));
              break;
          }
      }
  } else {
      tStr = oStr.substr(0, nlen);
  }
  var startTags = [];
  for(var i = 0; i < matchs.length; i++){
      if(tStr.length <= matchs[i].index){
          //tStr += matchs[i].string;
          matchs = matchs.slice(0, i);
          break;
      } else {
          tStr = tStr.substring(0, matchs[i].index) + matchs[i].string + tStr.substr(matchs[i].index);
          if(rgx1.test(matchs[i].string.replace(/(\/\/)/g, ""))){
              var name = matchs[i].string.replace(/[<>]/g, "").split(" ");
              if(name.length > 0){
                  name = name[0];
                  if (!inArray(name, selfTags)) {
                    startTags.push(name);
                  }
              }
          } else if(rgx2.test(matchs[i].string)){
              var name = matchs[i].string.replace(/[<\/>]/g, "");
              if(startTags.length > 0 && startTags[startTags.length - 1] === name){
                  startTags.pop();
              }
          }
      }
  }
  if(startTags.length > 0){
      for(var i = startTags.length - 1; i >=0; i--){
          tStr += '</' + startTags[i] + '>';
      }
  }
  return tStr;
}