/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import * as tools from '../controller/tools'

const UpSlideBox = ({ques}) => {
  let quesInfo = ques.hasOwnProperty('question_info') ? ques.question_info : {}
  return (
    <div className="wz_tt" style={{display:'none'}}>
      <div className="w1150 checkdiv" >
          <label>{quesInfo.question_content}</label>
          <div className="problem_but">
            <a style={{marginTop:'17px'}} href="javascript:void(0);"  id="up_box_answer">回复</a>
            <a style={{marginTop:'17px'}} href="javascript:void(0);" id="up_box_follow" className={'gz_but ' + (ques.question_focus ? 'active': '')}>
            <span>{ques.question_focus ? '取消关注' : '关注' }</span><em>{' '}</em> <b>{quesInfo.focus_count}</b></a>
          </div>
      </div>
    </div>
  )
}

const InviteBox = ({ques}) => {
  let inviteUsers = ques.hasOwnProperty('invite_users') ? ques.invite_users : {}
  let helpUsers = ques.hasOwnProperty('helpful_users') ? ques.helpful_users : {}
  const disInvite = i => e => {window.AWS.User.disinvite_users(window.$('#help_user_disinvite' + i))}
  const invite = i => e => {window.AWS.User.invite_users(window.$('#help_user_invite' + i), window.$('#help_user_invite' + i).parents('li').find('img').attr('src'))}
  return (
  <div className="Popup_bg aw-invite-box" style={{display:'none'}}>
    <div className="Popup_box invitee">
    <em className="close"><img src={require("../../img/close3.png")} alt="close"/></em>
        <div className="left_tt invite-list">
          <span>已邀请:</span>
          {
            [...Object.values(inviteUsers)].map(val => 
              <a href={'/people/'+val.url_token} data-id={val.uid} data-original-title={val.user_name} data-placement="bottom" data-toggle="tooltip">
              <img src={val.avatar_file} title={val.user_name} alt={val.user_name} /></a>
            )
          }
        </div>
        <div className="top_search">
          <input id="invite-input" className="form-control" type="text" placeholder="搜索你想邀请的人..." /><button></button>
        </div>
        <div className="clear"></div>
        <div className="answer" style={{maxHeight:'500px',overflow:'auto'}}>
            <ul className="aw-dropdown-list">
              {
                [...Object.values(helpUsers)].map((val,i) => 
                  <li>
                  <a className="name aw-user-name" data-id={val.user_info.uid} href={'/people/'+ val.user_info.url_token}>
                    <img className="img" alt="" src={val.user_info.avatar_file} />{val.user_info.user_name} </a>
                  <a className="bqian" data-id={val.experience.topic_info.topic_id} href={'/topic/'+ val.experience.topic_info.url_token} >
                    {val.experience.topic_info.topic_title}</a>
                  <span>话题下获得<i>{val.experience.agree_count}</i>个赞</span>
                  {
                    val.has_invite 
                    ? 
                    <button data-value={val.user_info.user_name} data-id={val.user_info.uid} id={"help_user_disinvite" + i} onClick={disInvite(i)}>取消邀请</button>
                    :
                    <button data-value={val.user_info.user_name} data-id={val.user_info.uid} id={"help_user_invite" + i} onClick={invite(i)}>邀请</button>
                  }
                  </li>
                )
              }     
            </ul>
            <div className="aw-dropdown" style={{display:'none'}}>
              <p className="title" style={{textAlign:'center'}}>没有找到相关结果</p>
            </div>
        </div>
    </div>
  </div>
  )
}

const ProBoxLeft = ({thisUser, ques}) => {
  let quesInfo = ques.hasOwnProperty('question_info') ? ques.question_info : {}
  let quesTopics = ques.hasOwnProperty('question_topics') ? ques.question_topics : []
  let relatedTopics = ques.hasOwnProperty('related_topics') ? ques.related_topics : {}
  let thisUserInfo = thisUser.hasOwnProperty('user_info') ? thisUser.user_info : {}
  let showEditTopic = ([...quesTopics].length > 0) && thisUser.user_id 
                      && ((!quesInfo.lock && parseInt(thisUserInfo.permission.edit_topic)) || (thisUser.user_id === quesInfo.published_uid))
  let hasIntroTopics = ([...quesTopics].length === 0) 
                      && thisUser.user_id && !quesInfo.lock && parseInt(thisUserInfo.permission.edit_topic)
  const IntroTopics = props => {
    const addTopic = (v, k) => e => {
      window.one_click_add_topic(window.$("#_topicItem" + k), v, quesInfo.question_id);
    }
    return (!hasIntroTopics) ? '' :
    ((tools.isObject(relatedTopics) && (Object.values(relatedTopics).length > 0))
      ? 
      <div>
        <span className="pull-left">可选择添加如下话题:</span>
        {
          Object.values(relatedTopics).map((value,key) => {
            return (<a onClick={addTopic(value,key)} className="topic-tag text" id={"_topicItem" + key}>{value}</a>)
          })
        }
        <div className="foot_bq aw-edit-topic"><label className="edit">若都不是，点此添加话题</label></div>
      </div>
      :
      <div className="foot_bq aw-edit-topic"><label className="edit">没有归属话题, 请添加</label></div> 
    )
  }
  return (
    <div className="problem_bq topic_label aw-topic-bar" data-type="question" data-id={quesInfo.question_id} data-img={require("../../img/close2.png")}>
      <IntroTopics />
      {
        [...quesTopics].map(v=><a className="topic-tag text" data-id={v.topic_id} href={"/topic/" + v.url_token} >{v.topic_title}</a>)
      }
      {
        (!showEditTopic) ? '' : <div className="foot_bq aw-edit-topic"><label className="edit">编辑</label></div>
      }
    </div>
  )
}

const ProBoxRight  = ({thisUser, ques}) => {
  let quesInfo = ques.hasOwnProperty('question_info') ? ques.question_info : {}
  let thisUserInfo = thisUser.hasOwnProperty('user_info') ? thisUser.user_info : {}
  let showManage = thisUser.user_id
                   && ((!quesInfo.lock && parseInt(thisUserInfo.permission.edit_question)) || (thisUser.user_id === quesInfo.published_uid))
  const rightFollow = id => e => {window.AWS.User.follow(window.$('#right_follow'), 'question', id)}
  const scrollToAnchor = (anchorName) => e => {
    if (anchorName) {
        let anchorElement = document.getElementById(anchorName)
        if(anchorElement) {anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'})}
    }
  }
  return (
  <div className="problem_but">
    <ul>
      {
        !showManage ? '' :
        <li className="am-dropdown">
          <a className="w60 am-btn am-btn-primary" href="javascript:void(0);" id="editList">管理</a>
          <div className="Administration_hover" style={{display: 'none'}}>
            <a href={'/publish/question/' + quesInfo.question_id}>修改问题</a>
          </div>
        </li>
      }
      <li>
        <a className="w60" href="javascript:void(0);" onClick={scrollToAnchor('container')}>回复</a>
      </li>
      {
        !(thisUser.user_id) ? '' :
        <li>
          <a className={'gz_but ' + (ques.question_focus ? 'active': '')} href="javascript:void(0);" id="right_follow" onClick={rightFollow(quesInfo.question_id)}>
          <span>{ques.question_focus?'取消关注':'关注'}</span><em>{' '}</em><b>{quesInfo.focus_count}</b>
          </a>
        </li>
      }
    </ul>
  </div>
  )
}

const ProBoxMiddle = ({ginfo, thisUser, ques}) => {
  let _fold, _unfold
  let thisUserInfo = thisUser.hasOwnProperty('user_info') ? thisUser.user_info : {}
  let quesInviteUsers = ques.hasOwnProperty('invite_users') ? ques.invite_users : {}
  let quesInfo = ques.hasOwnProperty('question_info') ? ques.question_info : {}
  let quesDetail = quesInfo.hasOwnProperty('question_detail') ? tools.htmlUnescape(quesInfo.question_detail.replace(/(^\s*)|(\s*$)/g, '').replace(/[\r\n]/g, '')) : ''
  let showDel = thisUser.user_id && (parseInt(thisUserInfo.permission.is_administortar) || parseInt(thisUserInfo.permission.is_moderator))
  let showEdit = (!quesInfo.lock && (quesInfo.published_uid === thisUser.user_id || parseInt(thisUserInfo.permission.edit_question)))
                 || (parseInt(thisUserInfo.permission.is_administortar)) || (parseInt(thisUserInfo.permission.is_moderator))

  const unfold = e => {window.tab_p(1); _unfold.style.display='none'; _fold.style.display='block'}
  const fold = e => {window.tab_p(2); _unfold.style.display='block'; _fold.style.display='none'}
  const follow = e => {window.AWS.User.follows(window.$('#middle_follow'), 'question', quesInfo.question_id)}
  const share = e => {window.question_share("#question_share")}
  const report = e => {window.AWS.dialog('report', {item_type:'question', item_id: quesInfo.question_id})}
  const del = e => {
    window.AWS.dialog('confirm', {'message' : '确认删除'}, function(){
      window.AWS.ajax_request(ginfo.G_BASE_URL + '/api/question/remove_question/', 'question_id=' + quesInfo.question_id)
    })
  }
  return (
  <div className="problem_d">
    <h2>{quesInfo.question_content}</h2>
    <p className="p1">
      <div className="part_detail" dangerouslySetInnerHTML={{__html: (tools.htmlToText(quesDetail).length <= 66) ? quesDetail : tools.subHtml(quesDetail, 66, false) }} />
      <span style={(tools.htmlToText(quesDetail).length <= 66) ? {display:'none'} : {display:'block'}}>
      <a href="javascript:void(0);" ref={a=>_unfold=a} onClick={unfold} style={{display:'block'}}>{'展开>>'}</a>
      <a href="javascript:void(0);" ref={a=>_fold=a} onClick={fold} style={{display:'none'}}>{'收起<<'}</a>
      </span>
    </p>
    <div className="detail_info" style={{display: 'none'}}>
      <div className="full_detail" dangerouslySetInnerHTML={{__html: quesDetail}} />
    </div>

    <div className="foot_bq">

      {/*
      let _agree, _disagree
      const agree = (user, quesId) => e => {window.AWS.User.agree_question_vote(_agree, user, quesId)}
      const disagree = (user, quesId) => e => {window.AWS.User.disagree_question_vote(_disagree, user, quesId)}
      <div className="zkzd">
        <span ref={span=>_agree=span} className={"agree " + (quesInfo.lock ? "grayon" : "")} onClick={agree(thisUserInfo.user_name, quesInfo.question_id)}>
        <em className="up"></em>{quesInfo.agree_count}</span>
        <span ref={span=>_disagree=span} className={"disagree " + (quesInfo.lock ? "grayon" : "")} onClick={disagree(thisUserInfo.user_name, quesInfo.question_id)}>
        <em className="downs"></em>{quesInfo.against_count}</span>
      </div>
      */}
      
      {!(thisUser.user_id) ? '' : <label className="follow fow" id="middle_follow" onClick={follow} ><i>{quesInfo.focus_count}</i>人关注</label>}
      {!showEdit ? '' : <label className="edit"><a style={{color: '#999'}} href={'/publish/question/' + quesInfo.question_id}>编辑</a></label>}
      
      <label className="fx" data-id={ginfo.G_BASE_URL + '/m/question/'+ quesInfo.question_id} data-name={quesInfo.question_content} id="question_share" onClick={share} style={{cursor:'pointer'}}>分享                    
        <div className="fx_list bdsharebuttonbox bdshare-button-style0-16" style={{display: 'none'}} data-bd-bind="">
          <ul>
              <li className="qq"><a style={{fontSize:'12px', lineHeight:'17px'}} href="#" className="bds_qzone" data-cmd="qzone" title="分享到QQ空间">分享到空间</a></li>
              <li className="wb"><a style={{fontSize:'12px', lineHeight:'17px'}} href="#" className="bds_tsina" data-cmd="tsina" title="分享到新浪微博">分享到微博</a></li>
              <li className="wx"><a style={{fontSize:'12px', lineHeight:'17px'}} href="#" className="bds_weixin" data-cmd="weixin" title="分享到微信">分享到微信</a></li>
              {/*
              const qrCode = e => {window.qrCodeGo('/question/'+ quesInfo.question_id)}
              <li className="wx"><a href="javascript:void(0);" onClick={qrCode} title="分享到微信">分享到微信</a></li>
              */}
          </ul>
        </div>
      </label>

      {!(thisUser.user_id) ? '' : <label className="invitation" id="invitesss"><i>{(tools.isObject(quesInviteUsers) && (Object.keys(quesInviteUsers).length > 0)) ? Object.keys(quesInviteUsers).length : 0}</i>邀请</label>}
      {!(thisUser.user_id) ? '' : <label className="report" onClick={report}>举报</label>}
      {!showDel ? '' : <label className="del"><a href="javascript:void(0);" onClick={del}>删除</a></label>}
      <label className="fr">{quesInfo.add_time}</label>
    </div>
  </div>
  )
}

const ProBox = ({ginfo, thisUser, ques}) => 
  <div className="problem_box">
    <div className="w1150">
      <ProBoxLeft thisUser={thisUser} ques={ques} />
      <ProBoxRight thisUser={thisUser} ques={ques} />
      <ProBoxMiddle ginfo={ginfo} thisUser={thisUser} ques={ques} />
      <div className="clear"></div>
    </div>
  </div>

const QuesBox = props => {
  let {ginfo, thisUser, question} = props
  return (
  <div>
    <UpSlideBox ques={question} /> 
    <InviteBox ques={question} />
    <ProBox ginfo={ginfo} thisUser={thisUser} ques={question}/>
  </div>
  )
}
export default QuesBox
