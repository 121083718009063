import React from 'react';

var banner = {
  backgroundImage: 'url(' + require("../../img/banner.jpg") + ')',
  backgroundSize: '100% 100%',
  backgroundRepeat: 'no-repeat'
}

export const HomeSlogan = props =>
  <div class="slogan" style={banner}>
    <div class="w1150">
      <dl>
        <dt>
          <h1>欢迎来到Auto++世界，你想收获什么？（主页）</h1>
          <p>Auto++是中国最专业的智能汽车知识分享平台，用户可以在平台上分享彼此对智能汽车行业的经验和见解。</p>
        </dt>
      </dl>
    </div>
  </div>

export const QuesSlogan = props =>
  <div class="slogan" style={banner}>
    <div class="w1150">
      <dl>
        <dt>
          <h1>欢迎来到Auto++世界，你想收获什么？（问题栏目）</h1>
          <p>Auto++是中国最专业的智能汽车知识分享平台，用户可以在平台上分享彼此对智能汽车行业的经验和见解。</p>
        </dt>
      </dl>
    </div>
  </div>

export const ArticlesSlogan = props =>
  <div class="slogan" style={banner}>
    <div class="w1150">
      <dl>
        <dt>
          <h1>欢迎来到Auto++世界，你想收获什么？（文章栏目）</h1>
          <p>Auto++是中国最专业的智能汽车知识分享平台，用户可以在平台上分享彼此对智能汽车行业的经验和见解。</p>
        </dt>
      </dl>
    </div>
  </div>

export const TopicsSlogan = props =>
  <div class="slogan" style={banner}>
    <div class="w1150">
      <dl>
        <dt>
          <h1>欢迎来到Auto++世界，你想收获什么？（话题栏目）</h1>
          <p>Auto++是中国最专业的智能汽车知识分享平台，用户可以在平台上分享彼此对智能汽车行业的经验和见解。</p>
        </dt>
      </dl>
    </div>
  </div>

export const SearchSlogan = props =>
  <div class="slogan" style={banner}>
    <div class="w1150">
      <dl>
        <dt>
          <h1>欢迎来到Auto++世界，你想收获什么？（搜索栏目）</h1>
          <p>Auto++是中国最专业的智能汽车知识分享平台，用户可以在平台上分享彼此对智能汽车行业的经验和见解。</p>
        </dt>
      </dl>
    </div>
  </div>

