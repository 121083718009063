/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';
import * as tools from '../controller/tools'
import LoadMore from './LoadMore'

const LeftNav = ({topicSquare, switchSquare, setSquare, getSquare}) => {
  let toSquare = type => e => {setSquare(type);switchSquare(type)}
  let hotTopics = (topicSquare.hasOwnProperty('sidebar_hot_topics') && tools.isArray(topicSquare.sidebar_hot_topics)) ? topicSquare.sidebar_hot_topics : []
  return (
  <div className="left_nav">
    <dl className="nav19 cur">
      <dt><a href="javascript:void(0);">话题</a></dt>
      <dd>
        <a href="javascript:void(0);" onClick={toSquare('all')} className={((getSquare() === 'all') ? 'Select' : '')}>全部</a>
        <a href="javascript:void(0);" onClick={toSquare('week')} className={((getSquare() === 'week') ? 'Select' : '')}>7天</a>
        <a href="javascript:void(0);" onClick={toSquare('month')} className={((getSquare() === 'month') ? 'Select' : '')}>30天</a>
      </dd>
    </dl>
    <dl className="nav4 cur" >
      <dt><a href="javascript:void(0)">推荐话题</a></dt>
      <dd className="tjht">
      {[...hotTopics].map(t=> <a href={"/topic/" + t.topic_title}><img src={t.topic_pic} alt={t.topic_title}/>{t.topic_title}</a>)}
      </dd>
    </dl>
  </div>
  )
}

const MiddleArea = ({topicSquare, getSquare, more}) => {
  let thisUser = topicSquare.hasOwnProperty('this_user') ? topicSquare.this_user : {}
  let topicsList = topicSquare.hasOwnProperty('topics_list') ? (tools.isArray(topicSquare.topics_list) ? topicSquare.topics_list : []) : []
  let curTopicCount = topicSquare.hasOwnProperty('cur_topic_count') ? topicSquare.cur_topic_count : topicsList.length
  let follow = (tid, index) => e => {window.follow(window.$('#topic-follow-elem' + index), 'topic', tid);}
  return (
  <div className="Middle_area auto-square-topic">
    <div className="topic_square">
      <ul>
      {
        [...topicsList].map((topic, i) => {
          return (
          <li>
            <dl>
              <dt><a href={"/topic/"+ topic.url_token} data-id={topic.topic_id}><img src={topic.topic_pic} alt={topic.topic_title}/></a></dt>
              <dd><a href={"/topic/"+ topic.url_token} data-id={topic.topic_id}>{topic.topic_title}</a></dd>
              <div className="clear"></div>
            </dl>
            <div className="foot_bq">
              <label className="discuss">{topic.discuss_count}</label>
              <label className="follow2">{topic.focus_count}</label>
              {
                (thisUser.user_id > 0) ?
                <a href="javascript:void(0);" id={"topic-follow-elem" + i} onClick={follow(topic.topic_id, i)} className="">关注</a>
                : ''
              }
            </div>
          </li>
          )
        })
      }
      </ul>
      <div className="clear"></div>
    </div>

    <div className="mbw auto-square">
      <div className="load-more btn">
        <div className="load_more_div">
          <div className="load_more"><LoadMore perPage={'18'} curRows={curTopicCount} loadEvent={e=>more(getSquare())}/></div>
        </div>
      </div>
    </div>

  </div>
  )
}

const RightArea = ({topicSquare}) => {
  let thisUser = topicSquare.hasOwnProperty('this_user') ? topicSquare.this_user : {}
  let thisUserInfo = thisUser.hasOwnProperty('user_info') ? thisUser.user_info : {}
  let newTopics = topicSquare.hasOwnProperty('new_topics') ? (tools.isArray(topicSquare.new_topics) ? topicSquare.new_topics : []) : []
  let focusTopics = topicSquare.hasOwnProperty('focus_topics') ? (tools.isArray(topicSquare.focus_topics) ? topicSquare.focus_topics : []) : []
  return (
  <div className="right_area ">
    {/* 个人 */}
    {
      (thisUser.user_id > 0) ?
        <div className="personal" style={{position:'static'}}>
          <dl>
            <dt><a href={"/people/" + thisUserInfo.url_token}><img src={thisUserInfo.avatar_file} alt="" /></a></dt>
            <dd><a href={"/people/" + thisUserInfo.url_token}>{thisUserInfo.user_name}</a></dd>
          </dl>
          <ul>
            <li><span>赞同</span><label>{thisUserInfo.agree_count}</label></li>
            <li><span>威望</span><label>{thisUserInfo.reputation}</label></li>
            <div className="clear"></div>
          </ul>
        </div>
      : ''
    }

    {/* 公告板块
    <div class="right_Notice">
      <h4>公告<a href="/announce/index">查看更多</a></h4>
      <ul>
        <li><a href="/announce/detail/id-106">【奖励发放】<em class="new"></em></a></li>
        <li><a href="/announce/detail/id-105">【奖励发放】</a></li>
        <li><a href="/announce/detail/id-104">【奖励发放】</a></li>
      </ul>
    </div>
    */}

    <div className="right_Notice">
        <h4>新增话题</h4>
        <div className="Topic">
        {
          [...newTopics].map(topic => <a href={"/topic/" + topic.url_token} className="text" data-id={topic.topic_id}>{topic.topic_title}</a>)
        }
        </div>
    </div>

    {
      (thisUser.user_id > 0) ?
        <div className="right_Notice">
          <h4>我关注的话题</h4>
          <div className="Topic">
          {
            [...focusTopics].map(topic => <a href={"/topic/" + topic.url_token} className="text" data-id={topic.topic_id}>{topic.topic_title}</a>)
          }
          </div>
        </div>
      : ''
    }

    {/* APP二维码下载板块
    <div class="right_Notice b_pp"><img src="../uploads/common/1540824923.jpg" alt="" /></div>
    */}
  </div>
  )
}

class TopicsMain extends Component {

  constructor(props) {
    super(props)
    this.whereSquare = 'all'
    this.setSquare = this.setSquare.bind(this)
    this.getSquare = this.getSquare.bind(this)
  }

  setSquare(where) {this.whereSquare = where}
  getSquare() {return this.whereSquare}

  render() {
    let {ginfo, topicSquare, switchSquare, more} = this.props
    return (
    <div className="w1150 Main_area">
      <LeftNav topicSquare={topicSquare} switchSquare={switchSquare} setSquare={this.setSquare} getSquare={this.getSquare}/>
      <MiddleArea ginfo={ginfo} topicSquare={topicSquare} getSquare={this.getSquare} more={more}/>
      <RightArea topicSquare={topicSquare}/>
      <div className="clear"></div>
    </div>)
  }
}

export default TopicsMain
