import {connect} from 'react-redux'
import {URL} from './constants'
import TopNav from '../view/TopNav'
import Footer from '../view/Footer'
import GoTop from '../view/GoTop'
import {HomeSlogan,
        QuesSlogan, 
        ArticlesSlogan,
        TopicsSlogan,
        SearchSlogan} from '../view/Slogan'
import HomeMain from '../view/HomeMain'
import QuesMain from '../view/QuesMain'
import QuesBox from '../view/QuesBox'
import QuesPageMain from '../view/QuesPageMain'
import ArticlesMain from '../view/ArticlesMain'
import ArticlePageMain from '../view/ArticlePageMain'
import TopicsMain from '../view/TopicsMain'
import TopicBox from '../view/TopicBox'
import TopicPageMain from '../view/TopicPageMain'
import PeopleMain from '../view/PeopleMain'
import PeopleBox from '../view/PeopleBox'
import PeoplePageMain from '../view/PeoplePageMain'
import SearchMain from '../view/SearchMain'
import PublishMain from '../view/PublishMain'
import NotiMain from '../view/NotiMain'
import InboxMain from '../view/InboxMain'
import Account from '../view/Account'
import Setting from '../view/Setting'
import {
  homeSquare,
  addHomeResult,
  quesSquare,
  addQuesResult,
  articleSquare,
  addArticlesResult,
  topicSquare,
  addTopicResult,
  addAllListResult,
  addBestQuesResult, 
  addAllQuesResult, 
  addArtListResult,
  peopleSquare,
  addPeopleResult,
  addUserActions,
  changeUserActions,
  changeUserFollows,
  addUserFollows,
  changeUserFollowTopics,
  addUserFollowTopics,
  addSearchResult,
  addAnswersResult,
  addNotiResult,
  addInboxResult,
  addDialogResult,
  addCommentResult,
  changeSettingContent} from './actions'

//top nav
export const TopNavContainer = connect(
  (state, props) =>({
    ginfo: state.ginfo,
    column: props.column
  }),
  dispatch => ({})
)(TopNav)

//home
export const HomeSloganContainer = connect(
  state =>({}),
  dispatch => ({})
)(HomeSlogan)

export const HomeMainContainer = connect(
  state =>({
    ginfo: state.ginfo,
    homeSquare: state.square.home
  }),
  dispatch => ({
    more(sortType) {
      dispatch(addHomeResult(URL.HOME_API + ((sortType === 'hot') ? 'sort_type-hot__' : '')))
    },
    switchSquare(sortType) {
      dispatch(homeSquare(URL.HOME_API + ((sortType === 'hot') ? 'sort_type-hot__' : '')))
    }
  })
)(HomeMain)

//questions
export const QuesSloganContainer = connect(
  state =>({}),
  dispatch => ({})
)(QuesSlogan)

export const QuesMainContainer = connect(
  state =>({
    ginfo: state.ginfo,
    quesSquare: state.square.question
  }),
  dispatch => ({
    more(sortType) {
      dispatch(addQuesResult(URL.QUESTIONS_API + 'index_square/' + ((sortType === 'hot') ? 'sort_type-hot__' : '')))
    },
    switchSquare(sortType) {
      dispatch(quesSquare(URL.QUESTIONS_API + 'index_square/' + ((sortType === 'hot') ? 'sort_type-hot__' : '')))
    }
  })
)(QuesMain)

export const QuesBoxContainer = connect(
  (state, props) =>({
    ginfo: state.ginfo,
    params: props.match.params,
    thisUser: state.question.thisUser,
    question: state.question.question
  }),
  dispatch => ({})
)(QuesBox)

export const QuesPageMainContainer = connect(
  (state, props) =>({
    ginfo: state.ginfo,
    params: props.match.params,
    thisUser: state.question.thisUser,
    question: state.question.question
  }),
  (dispatch, props) => ({
    more() {
      let params = props.match.params
      dispatch(addAnswersResult(URL.QUESTION_ANSWERS_API, params))
    }
  })
)(QuesPageMain)

//articles
export const ArticlesSloganContainer = connect(
  state =>({}),
  dispatch => ({})
)(ArticlesSlogan)

export const ArticlesMainContainer = connect(
  (state, props) =>({
    ginfo: state.ginfo,
    artSquare: state.square.article,
    peopleSquare: state.square.people
  }),
  dispatch => ({
    artMore() {
      dispatch(addArticlesResult(URL.ARTICLES_API + 'index_square/'))
    },
    peopleMore() {
      dispatch(addPeopleResult(URL.PEOPLE_API + 'index_square/'))
    },
    articles() {
      dispatch(articleSquare(URL.ARTICLES_API + 'index_square/'))
    },
    authors() {
      dispatch(peopleSquare(URL.PEOPLE_API + 'index_square/'))
    }
  })
)(ArticlesMain)

export const ArticlePageMainContainer = connect(
  (state, props) =>({
    ginfo: state.ginfo,
    params: props.match.params,
    thisUser: state.article.thisUser,
    article: state.article.article
  }),
  (dispatch, props) => ({
    more() {
      let params = props.match.params
      dispatch(addCommentResult(URL.ARTICLES_API, params))
    }
  })
)(ArticlePageMain)

//topic
export const TopicsSloganContainer = connect(
  state =>({}),
  dispatch => ({})
)(TopicsSlogan)

export const TopicsMainContainer = connect(
  state =>({
    ginfo: state.ginfo,
    topicSquare: state.square.topic
  }),
  dispatch => ({
    more(sortType) {
      let options = ((!sortType) || (sortType === 'all')) ? '' : ('channel-hot__day-' + sortType + '__')
      dispatch(addTopicResult(URL.TOPICS_API + 'index_square/' + options))
    },
    switchSquare(sortType) {
      let options = ((!sortType) || (sortType === 'all')) ? '' : ('channel-hot__day-' + sortType + '__')
      dispatch(topicSquare(URL.TOPICS_API + 'index_square/' + options))
    }
  })
)(TopicsMain)

export const TopicBoxContainer = connect(
  (state, props) =>({
    ginfo: state.ginfo,
    params: props.match.params,
    topic: state.topic
  }),
  dispatch => ({})
)(TopicBox)

export const TopicPageMainContainer = connect(
  (state, props) =>({
    ginfo: state.ginfo,
    params: props.match.params,
    topic: state.topic
  }),
  (dispatch, props) => ({
    more(type) {
      let {id} = props.match.params
      let tabs = {'all': addAllListResult, 'best': addBestQuesResult, 'ques': addAllQuesResult, 'art': addArtListResult}
      dispatch(tabs[type](URL.TOPICS_API + 'id-' + id + '__'))
    }
  })
)(TopicPageMain)

//people
export const PeopleMainContainer = connect(
  (state, props) =>({
    ginfo: state.ginfo,
    peopleSquare: state.square.people
  }),
  dispatch => ({
    more() {
      dispatch(addPeopleResult(URL.PEOPLE_API + 'index_square/'))
    }
  })
)(PeopleMain)

export const PeopleBoxContainer = connect(
  (state, props) =>({
    ginfo: state.ginfo,
    people: state.people
  }),
  dispatch => ({})
)(PeopleBox)

export const PeoplePageMainContainer = connect(
  (state, props) =>({
    ginfo: state.ginfo,
    people: state.people
  }),
  (dispatch, props) => ({
    userActions(uid, actions) {
      dispatch(changeUserActions(URL.USER_ACTIONS_API, {uid, actions}))
    },
    userActionsMore(uid, actions) {
      dispatch(addUserActions(URL.USER_ACTIONS_API, {uid, actions}))
    },
    userFollows(uid, followsType) {
      dispatch(changeUserFollows(URL.USER_FOLLOWS_API, {uid, followsType}))
    },
    userFollowsMore(uid, followsType) {
      dispatch(addUserFollows(URL.USER_FOLLOWS_API, {uid, followsType}))
    },
    userTopics(uid) {
      dispatch(changeUserFollowTopics(URL.USER_TOPICS_API, {uid}))
    },
    userTopicsMore(uid) {
      dispatch(addUserFollowTopics(URL.USER_TOPICS_API, {uid}))
    }
  })
)(PeoplePageMain)

//search
export const SearchSloganContainer = connect(
  (state, props) =>({}),
  (dispatch, props) => ({})
)(SearchSlogan)

export const SearchMainContainer = connect(
  (state, props) => ({
    ginfo: state.ginfo,
    status: state.search.status,
    result: state.search.result
  }),
  (dispatch, props) => ({
    more() {
      dispatch(addSearchResult(URL.SEARCH_API))
    }
  })
)(SearchMain)

//publish
export const PublishMainContainer = connect(
  (state, props) =>({
    ginfo: state.ginfo,
    type: props.match.params.type,
    question: state.publish.question,
    article: state.publish.article
  }),
  (dispatch, props) => ({})
)(PublishMain)

//notifications
export const NotiMainContainer = connect(
  state =>({
    ginfo: state.ginfo,
    notifications: state.notifications,
  }),
  dispatch => ({
    more() {
      dispatch(addNotiResult(URL.NOTIFICATIONS_API))
    }
  })
)(NotiMain)

//inbox
export const InboxMainContainer = connect(
  state =>({
    ginfo: state.ginfo,
    inbox: state.inbox,
  }),
  dispatch => ({
    more() {
      dispatch(addInboxResult(URL.INBOX_API))
    },
    dialog(id) {
      dispatch(addDialogResult(URL.INBOX_READ_API, id))
    }
  })
)(InboxMain)

//account
export const AccountContainer = connect(
  (state, props) =>({
    ginfo: state.ginfo,
    params: props.match.params,
    account: state.account
  }),
  dispatch => ({})
)(Account)

//setting
export const SettingContainer = connect(
  (state, props) =>({
    ginfo: state.ginfo,
    params: props.match.params,
    setting: state.setting
  }),
  dispatch => ({
    switchTab(tab) {
      dispatch(changeSettingContent(URL.SETTING_API + tab + '/'))
    }
  })
)(Setting)

//footer
export const FooterContainer = connect(
  state =>({}),
  dispatch => ({})
)(Footer)

export const GoTopContainer = connect(
  state =>({}),
  dispatch => ({})
)(GoTop)

