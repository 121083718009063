/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import * as tools from '../controller/tools'

const UserProtocol = props => 
  <div class="Popup_bg" style={{display:'none'}}>
    <div class="Popup_box w540">
        <em class="close"><img src={require("../../img/close3.png")} alt=""/></em>
        <div class="User_protocol">
            <h4>触角用户协议</h4>
            <p>当您申请用户时，表示您已经同意遵守本规章。<br/> 欢迎您加入本站点参与交流和讨论，本站点为社区，为维护网上公共秩序和社会稳定，请您自觉遵守以下条款：</p>
            <h5>一、不得利用本站危害国家安全、泄露国家秘密，不得侵犯国家社会集体的和公民的合法权益，不得利用本站制作、复制和传播下列信息：</h5>
            <p>
                （一）煽动抗拒、破坏宪法和法律、行政法规实施的；<br/>
                （二）煽动颠覆国家政权，推翻社会主义制度的；<br/>
                （三）煽动分裂国家、破坏国家统一的；<br/>
                （四）煽动民族仇恨、民族歧视，破坏民族团结的；<br/>
                （五）捏造或者歪曲事实，散布谣言，扰乱社会秩序的；<br/>
                （六）宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；<br/>
                （七）公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；<br/>
                （八）损害国家机关信誉的；<br/>
                （九）其他违反宪法和法律行政法规的；<br/>
                （十）进行商业广告行为的。
            </p>
            <h5>二、互相尊重，对自己的言论和行为负责。</h5>
            <h5> 三、禁止在申请用户时使用相关本站的词汇，或是带有侮辱、毁谤、造谣类的或是有其含义的各种语言进行注册用户，否则我们会将其删除。</h5>
            <h5> 四、禁止以任何方式对本站进行各种破坏行为。</h5>
            <h5> 五、如果您有违反国家相关法律法规的行为，本站概不负责，您的登录信息均被记录无疑，必要时，我们会向相关的国家管理部门提供此类信息。 </h5>
        </div>
    </div>
  </div>

const Register = ({account}) => {
  return (
  <div className="Sign_inbox">
    <div className="lefft_js"><div className="mrdl"><img src={require("../../img/dl_pic.png")} alt=""/></div></div>
    <div className="Sign_info zc_info">
      <h3>欢迎回来!</h3>
      <h5>欢迎<i>注册</i>触角，最靠谱的知识获取平台！</h5>
      <p className="red alert alert-danger collapse error_message text-left"></p>
      <form className="aw-register-form aw-register-box" method="post" id="register_form">
        <input type="hidden" name="token" value={account.token}/> 
        <input type="hidden" name="return_url" value={account.url}/>
        <ul>
          <li><input name="user_name" type="text" placeholder="用户名" tips="请输入1-8个汉字或2-16个字符，内容不限制" errortips="用户名长度不符合规则"/></li>
          <li><input id="phone" name="mobile" type="text" placeholder="手机号" tips="请输入你正在使用的手机号" errortips="手机号不正确"/></li>
          <li>
            <input className="w220" name="smscode" type="text"  placeholder="验证码" tips="请输入短信验证码" errortips="短信验证码不正确"/>
            <button id="Smsbtn" type="button" class="btn btn-large btn-blue btn-block">获取验证码</button>
          </li>
          <li>
            <input id="password" name="password" type="hidden" value=""/>
            <input id="passinput" name="passinput" type="password" className="password" placeholder="密码" tips="请输入 6-16 个字符，区分大小写" errortips="密码不符合规则" />
          </li>
          {/*<li><input name="icode" type="text" placeholder="邀请码（非必填）" errortips="邀请码不存在"/></li>*/}
        </ul>
        <input type="checkbox" value="0" name="agreement_chk" id="user_agree" style={{display:'none'}} checked="checked"/>
        <p>
          <span className="agree"><em></em>我同意</span><span><a className="aw-agreement-btn" href="javascript:void(0);">用户协议</a></span>
          <span className="fr">已有账号？<a className="fr"  href="/account/login/">登录</a></span>
        </p>
        <div className="login_but"><button className="dl w100 btn btn-large btn-blue btn-block" id="register_exec" >注册</button></div>
      </form>
      <div className="clear"></div>
      </div>
      <UserProtocol />
    </div>
  )
}

const Loginer = ({account}) => {
  return (
  <div className="Sign_inbox">
    <div className="lefft_js"><div className="mrdl"><img src={require("../../img/dl_pic.png")} alt=""/></div></div>
    <div className="Sign_info">
        <h3>欢迎回来!</h3>
        <h5>欢迎<i>登录</i>触角，最靠谱的知识获取平台！</h5>
        <p className="red alert alert-danger collapse error_message text-left"></p>
        <form id="login_form" method="post">
          <input type="hidden" name="token" value={account.token}/> 
          <input type="hidden" name="return_url" value={account.url}/>
          <ul>
            <li><input name="user_name" type="text" placeholder="用户名/手机号"/></li>
            <li>
              <input id="password" name="password" type="hidden" value=""/>
              <input id="passinput" name="passinput" type="password" className="password" placeholder="密码"/>
            </li>
          </ul>
          <input style={{display:'none'}} type="checkbox" value="1" name="net_auto_login" id="rember"/>
          <p><span className="rember_user"><em></em>记住我</span><span className="fr"><a href="/account/find_password">忘记密码？</a></span></p>
          <div className="login_but auto-login-regist">
            <button className="dl" id="login_exec">登录</button>
            <div className="zc" onClick={e=>{window.location='/account/register/'}}>注册</div>
          </div>
        </form>
        {/* 
          <div className="Other_way">
            <h4>其他登录方式</h4>

            <a href="http://www.biask.com/account/openid/weibo/bind/return_url-aHR0cDovL3d3dy5iaWFzay5jb20v" title="微博登录" alt="微博登录">
              <img src={require("../../img/wb_ico.png")} alt=""/></a>
        
            <a href="http://www.biask.com/account/openid/qq/bind/return_url-aHR0cDovL3d3dy5iaWFzay5jb20v" title="QQ登录" alt="QQ登录">
              <img src={require("../../img/qq_ico.png")} alt=""/></a>
        
            <a href="http://www.biask.com/account/weixin_login/return_url-aHR0cDovL3d3dy5iaWFzay5jb20v" title="微信登录" alt="微信登录">
              <img src={require("../../img/wx_ico.png")} alt=""/></a>
        
            <a href="http://www.biask.com/account/openid/yoyow/bind/" title="YOYOW登录" alt="YOYOW登录">
            <img src="http://www.biask.com/static/theme/lime/images/yy_ico.png" alt=""/></a>
            
            <a class="yoyow_qr_login" href="javascript:void(0);" onclick="AWS.dialog('yoyow_qr_login', '');" title="YOYOW扫一扫登录" alt="YOYOW扫一扫登录">
            <img src="http://www.biask.com/static/theme/lime/images/ss_ico.png" alt=""/></a>
            
            <div className="clear"></div>
          </div>
        */}
        <div className="f_t">
            <a href="/">游客访问</a>
        </div>
        <div className="clear"></div>
    </div>
  </div>
  )
}

const FindPassword = ({ginfo, account}) => {
  let captchaClick = e => {window.$('#captcha').attr("src", ginfo.G_BASE_URL + '/account/captcha/' + Math.floor(Math.random() * 10000))}
  return (
  <div className="Sign_inbox">
    <div className="lefft_js"><div className="mrdl"><img src={require("../../img/dl_pic.png")} alt=""/></div></div>
    <div className="Sign_info zc_info">
        <h3>找回密码</h3>
        <h5>验证码将会发送至您的手机</h5>
        <p className="red alert alert-danger collapse error_message text-left"></p>
        <form className="aw-register-form aw-register-box" id="fpw_form" method="post">
          <input type="hidden" name="token" value={account.token}/>
          <ul>
            <li>
              <input id="phone" name="mobile" type="text" autocomplete='off' placeholder="手机号" tips="请输入正确的手机号码" errortips="手机号不正确"/>
            </li>
            <li>
              <input className="w220" id="smscode" type="text" autocomplete='off' placeholder="短信验证码" name="smscode" tips="请输入短信验证码" errortips="短信验证码不正确" />
              <button id="Smsbtn" type="button" className="btn btn-large btn-blue btn-block">获取验证码</button>
            </li>
            <li>
              <input id="password" name="password" type="hidden" value=""/>
              <input id="passinput" name="passinput" type="password" className="password" autocomplete='new-password' placeholder="请输入新密码" tips="请输入 6-16 个字符,区分大小写" errortips="密码不符合规则" />
            </li>
            <li>
              <input id="re_password" name="re_password" type="hidden" value=""/>
              <input id="re_passinput" name="re_passinput" type="password" className="password" autocomplete='new-password' placeholder="再次输入密码" tips="请输入 6-16 个字符,区分大小写" errortips="密码不符合规则" />
            </li>
            <li className="aw-register-verify">
                <img style={{float:'right'}} id="captcha" onClick={captchaClick} src="" className="auth-img pull-right" alt="" />
                <input className="form-control w220" type="text" name="seccode_verify" autocomplete='off' placeholder="验证码" />
            </li>
          </ul>
          <div className="login_but"><button className="dl w100 btn btn-large btn-blue btn-block" id="reset_password">重置密码</button></div>
        </form>
        <div className="clear"></div>
    </div>
  </div>
  )
}

class Account extends Component {
  render() {
    let {ginfo, params, account} = this.props
    let {route} = params
    route = tools.isEmpty(route) ? 'login' : route
    return (
      <div className="dl_bg">
      {(route === 'login') ? <Loginer ginfo={ginfo} account={account}/> : ''}
      {(route === 'register') ? <Register ginfo={ginfo} account={account}/> : ''}
      {(route === 'find_password') ? <FindPassword ginfo={ginfo} account={account}/> : ''}
      </div>
    )
  }
}

export default Account;
