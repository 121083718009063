const PUBLIC_URL = process.env.PUBLIC_URL

const ieVersion = () => {
	var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串  
	var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器  
	var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器  
	var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
	if(isIE) {
		var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
		reIE.test(userAgent);
		var fIEVersion = parseFloat(RegExp["$1"]);
		if(fIEVersion === 7) {
			return 7;
		} else if(fIEVersion === 8) {
			return 8;
		} else if(fIEVersion === 9) {
			return 9;
		} else if(fIEVersion === 10) {
			return 10;
		} else {
			return 6;//IE版本<=7
		}   
	} else if(isEdge) {
		return 'edge';//edge
	} else if(isIE11) {
		return 11; //IE11  
	}else{
		return -1;//不是ie浏览器
	}
}

const loadCSS = (url, id, params, callback) => {
  var link = document.createElement ("link")
	link.type = 'text/css';
	link.rel = 'stylesheet';
    if (link.readyState){ //IE
        link.onreadystatechange = function(){
            if (link.readyState === "loaded" || link.readyState === "complete"){
                link.onreadystatechange = null;
				if (typeof callback === "function") {callback();} 
            }
        };
    } else { //Others
        link.onload = function(){
        	if (typeof callback === "function") {callback();} 
        };
    }
    link.href = url;
    link.id = id;
    link.params = params;
    document.getElementsByTagName("head")[0].appendChild(link);
    console.log(url)
}

const loadScript = (url, id, params, callback) => {
    var script = document.createElement ("script")
    script.type = "text/javascript";

    if (script.readyState){ //IE
        script.onreadystatechange = function(){
            if (script.readyState === "loaded" || script.readyState === "complete"){
                script.onreadystatechange = null;
                if (typeof callback === "function") {callback();} 
            }
        };
    } else { //Others
        script.onload = function(){
            if (typeof callback === "function") {callback();} 
        };
    }
    script.src = url;
    script.id = id;
    script.params = params;
    document.getElementsByTagName("head")[0].appendChild(script);
    console.log(url)
}

const loadPlugins = (list=[], handle) => {
  const order = (list, i) => () => {
    console.log(i + "=>" + list[i].url)

    if (list[i].hasOwnProperty('predication')) {
      if (list[i].predication && list[i].list) {
        order(list[i].list, 0)()
      }
      order(list, ++i)()
    } else {
      (i === list.length - 1) ? 
      handle(list[i].url, list[i].id, list[i].params, null) : 
      handle(list[i].url, list[i].id, list[i].params, order(list, ++i))
    }
  }
  order(list, 0)()
}

//global plugins
const GLOBAL_CSS_LIST = ginfo => [
  {id: 'style', params: null, url: PUBLIC_URL + '/common/js/plug_module/style.css'}
]
const GLOBAL_JS_LIST = ginfo => [
  {id: 'jquery.2', params: null, url: PUBLIC_URL + '/common/js/jquery.2.js'},
  {id: 'jquery.form', params: null, url: PUBLIC_URL + '/common/js/jquery.form.js'},
  {id: 'plug-in_module', params: null, url: PUBLIC_URL + '/common/js/plug_module/plug-in_module.js'},

  {id: 'ginfo_autoplus', params: ginfo, url: PUBLIC_URL + '/common/js/ginfo_autoplus.js'},

  {id: 'aws_autoplus', params: PUBLIC_URL, url: PUBLIC_URL + '/common/js/aws_autoplus.js'},
  {id: 'aw_template_autoplus', params: null, url: PUBLIC_URL + '/common/js/aw_template_autoplus.js'},
  {id: 'app_autoplus', params: null, url: PUBLIC_URL + '/common/js/app_autoplus.js'},
  {id: 'app_helper', params: null, url: PUBLIC_URL + '/common/js/app_helper.js'},
  {
    predication: (ieVersion() > 0 && ieVersion() <= 8),
    list: [
      {id: 'jquery-1.9.1', params: null, url: PUBLIC_URL + '/common/theme/lime/js/jquery-1.9.1.js'}
    ]
  },
  {id: 'jquery.superslide.2.1.1', params: null, url: PUBLIC_URL + '/common/theme/lime/js/jquery.superslide.2.1.1.js'},
  {id: 'layout', params: null, url: PUBLIC_URL + '/common/theme/lime/js/layout.js'}
]

export const globalPlugins = ginfo => {
  loadPlugins(GLOBAL_CSS_LIST(ginfo), loadCSS)
  loadPlugins(GLOBAL_JS_LIST(ginfo), loadScript)
}

//home plugins
export const homeColumnPlugins = (ginfo, params) => {
  loadPlugins(GLOBAL_CSS_LIST(ginfo), loadCSS)
  let HOME_JS_LIST = GLOBAL_JS_LIST(ginfo)
  HOME_JS_LIST.splice(8, 0, 
    {id: 'home_helper', params: params, url: PUBLIC_URL + '/common/js/app/home_helper.js'}
  )
  loadPlugins(HOME_JS_LIST, loadScript)
}

//topic plugins
export const topicsColumnPlugins = (ginfo, params) => {
  loadPlugins(GLOBAL_CSS_LIST(ginfo), loadCSS)
  let TOPIC_JS_LIST = GLOBAL_JS_LIST(ginfo)
  TOPIC_JS_LIST.splice(8, 0, 
    {id: 'topic_helper', params: params, url: PUBLIC_URL + '/common/js/app/topic_helper.js'}
  )
  loadPlugins(TOPIC_JS_LIST, loadScript)
}

//question plugins
export const quesColumnPlugins = globalPlugins

//article plugins
export const articlesColumnPlugins = globalPlugins

//people plugins
export const peopleColumnPlugins = globalPlugins

//notifications plugins
export const notiColumnPlugins = globalPlugins

//inbox
export const inboxColumnPlugins = globalPlugins

//search plugins
export const searchColumnPlugins = globalPlugins

//account
export const accountColumnPlugins = (ginfo, params) => {
  loadPlugins(GLOBAL_CSS_LIST(ginfo), loadCSS)
  let ACCOUNT_JS_LIST = GLOBAL_JS_LIST(ginfo)
  ACCOUNT_JS_LIST.push(
    {id: 'jsencrypt.min', params: null, url: PUBLIC_URL + '/common/js/jsencrypt.min.js'},
    {id: 'account_helper', params: params, url: PUBLIC_URL + '/common/js/account_helper.js'},
  )
  loadPlugins(ACCOUNT_JS_LIST, loadScript)
}

//setting plugins
export const accountSettingPlugins = ginfo => {
  loadPlugins(GLOBAL_CSS_LIST(ginfo), loadCSS)
  let ACCOUNT_SETTING_JS_LIST = GLOBAL_JS_LIST(ginfo)
  ACCOUNT_SETTING_JS_LIST.splice(8, 0, 
    {id: 'fileupload_autoplus', params: null, url: PUBLIC_URL + '/common/js/fileupload_autoplus.js'},
    {id: 'scrollable', params: null, url: PUBLIC_URL + '/common/js/scrollable.js'},
    {id: 'jsencrypt.min', params: null, url: PUBLIC_URL + '/common/js/jsencrypt.min.js'},
    {id: 'setting', params: null, url: PUBLIC_URL + '/common/js/app/setting.js'},
    {id: 'setting_helper', params: null, url: PUBLIC_URL + '/common/js/app/setting_helper.js'},
    {id: 'layer', params: null, url: PUBLIC_URL + '/common/js/layer-v3.1.1/layer/layer.js'}
  )
  loadPlugins(ACCOUNT_SETTING_JS_LIST, loadScript)
}

//topic page plugins
export const topicPagePlugins = (ginfo, params) => {
  loadPlugins(GLOBAL_CSS_LIST(ginfo), loadCSS)
  let TOPIC_PAGE_JS_LIST = GLOBAL_JS_LIST(ginfo)
  TOPIC_PAGE_JS_LIST.splice(8, 0, 
    {id: 'topic_helper', params: params, url: PUBLIC_URL + '/common/js/app/topic_helper.js'}
  )
  loadPlugins(TOPIC_PAGE_JS_LIST, loadScript)
}

//publish plugins
const PUBLISH_CSS_LIST = ginfo => [
  {id: 'style', params: null, url: PUBLIC_URL + '/common/js/plug_module/style.css'}
]
const PUBLISH_JS_LIST = (ginfo, params) => [
  {id: 'jquery.2', params: null, url: PUBLIC_URL + '/common/js/jquery.2.js'},
  {id: 'jquery.form', params: null, url: PUBLIC_URL + '/common/js/jquery.form.js'},
  {id: 'plug-in_module', params: null, url: PUBLIC_URL + '/common/js/plug_module/plug-in_module.js'},

  {id: 'ginfo_autoplus', params: ginfo, url: PUBLIC_URL + '/common/js/ginfo_autoplus.js'},

  {id: 'aws_autoplus', params: PUBLIC_URL, url: PUBLIC_URL + '/common/js/aws_autoplus.js'},
  {id: 'aw_template_autoplus', params: null, url: PUBLIC_URL + '/common/js/aw_template_autoplus.js'},
  {id: 'app_autoplus', params: null, url: PUBLIC_URL + '/common/js/app_autoplus.js'},
  {id: 'app_helper', params: null, url: PUBLIC_URL + '/common/js/app_helper.js'},
  ////////////////////
  {id: 'ueditor.config', params: null, url: PUBLIC_URL + '/common/js/ueditor-dev-1.5.0/ueditor.config.js'},
  {id: 'ueditor.all', params: null, url: PUBLIC_URL + '/common/js/ueditor-dev-1.5.0/ueditor.all.js'},
  {id: 'zh-cn', params: null, url: PUBLIC_URL + '/common/js/ueditor-dev-1.5.0/lang/zh-cn/zh-cn.js'},
  ////////////////////
  {id: 'fileupload_autoplus', params: null, url: PUBLIC_URL + '/common/js/fileupload_autoplus.js'},
  {id: 'publish_helper', params: params, url: PUBLIC_URL + '/common/js/app/publish_helper.js'},
  {id: 'publish_autoplus', params: null, url: PUBLIC_URL + '/common/js/app/publish_autoplus.js'},
  ////////////////////
  //{id: 'ckeditor', params: null, url: PUBLIC_URL + '/common/js/editor/ckeditor/ckeditor.js'},
  //{id: 'ckeditor_jquery', params: null, url: PUBLIC_URL + '/common/js/editor/ckeditor/adapters/jquery.js'},
  ///////////////////
  {
    predication: (ieVersion() > 0 && ieVersion() <= 8),
    list: [
      {id: 'jquery-1.9.1', params: null, url: PUBLIC_URL + '/common/theme/lime/js/jquery-1.9.1.js'}
    ]
  },
  {id: 'jquery.superslide.2.1.1', params: null, url: PUBLIC_URL + '/common/theme/lime/js/jquery.superslide.2.1.1.js'},
  {id: 'layout', params: null, url: PUBLIC_URL + '/common/theme/lime/js/layout.js'}
]
export const publishPlugins = (ginfo, params) => {
  loadPlugins(PUBLISH_CSS_LIST(ginfo), loadCSS)
  loadPlugins(PUBLISH_JS_LIST(ginfo, params), loadScript)
}

//question page plugins
const QUES_PAGE_CSS_LIST = ginfo => [
  {id: 'style', params: null, url: PUBLIC_URL + '/common/js/plug_module/style.css'}
]
const QUES_PAGE_JS_LIST = (ginfo, params) => [
  {id: 'jquery.2', params: null, url: PUBLIC_URL + '/common/js/jquery.2.js'},
  {id: 'jquery.form', params: null, url: PUBLIC_URL + '/common/js/jquery.form.js'},
  {id: 'plug-in_module', params: null, url: PUBLIC_URL + '/common/js/plug_module/plug-in_module.js'},

  {id: 'ginfo_autoplus', params: ginfo, url: PUBLIC_URL + '/common/js/ginfo_autoplus.js'},

  {id: 'aws_autoplus', params: PUBLIC_URL, url: PUBLIC_URL + '/common/js/aws_autoplus.js'},
  {id: 'aw_template_autoplus', params: null, url: PUBLIC_URL + '/common/js/aw_template_autoplus.js'},
  {id: 'app_autoplus', params: null, url: PUBLIC_URL + '/common/js/app_autoplus.js'},
  {id: 'app_helper', params: null, url: PUBLIC_URL + '/common/js/app_helper.js'},
  ////////////////////
  {id: 'ueditor.config', params: null, url: PUBLIC_URL + '/common/js/ueditor-dev-1.5.0/ueditor.config.js'},
  {id: 'ueditor.all', params: null, url: PUBLIC_URL + '/common/js/ueditor-dev-1.5.0/ueditor.all.js'},
  {id: 'zh-cn', params: null, url: PUBLIC_URL + '/common/js/ueditor-dev-1.5.0/lang/zh-cn/zh-cn.js'},
  ////////////////////
  {id: 'fileupload_autoplus', params: null, url: PUBLIC_URL + '/common/js/fileupload_autoplus.js'},
  {id: 'question_helper', params: params, url: PUBLIC_URL + '/common/js/app/question_helper.js'},
  {id: 'question_detail_autoplus', params: null, url: PUBLIC_URL + '/common/js/app/question_detail_autoplus.js'},
  {id: 'layer', params: null, url: PUBLIC_URL + '/common/js/layer-v3.1.1/layer/layer.js'},
  {
    predication: (ieVersion() > 0 && ieVersion() <= 8),
    list: [
      {id: 'jquery-1.9.1', params: null, url: PUBLIC_URL + '/common/theme/lime/js/jquery-1.9.1.js'}
    ]
  },
  {id: 'jquery.superslide.2.1.1', params: null, url: PUBLIC_URL + '/common/theme/lime/js/jquery.superslide.2.1.1.js'},
  {id: 'layout', params: null, url: PUBLIC_URL + '/common/theme/lime/js/layout.js'}
]
export const quesPagePlugins = (ginfo, params) => {
  loadPlugins(QUES_PAGE_CSS_LIST(ginfo), loadCSS)
  loadPlugins(QUES_PAGE_JS_LIST(ginfo, params), loadScript)
}

//article page plugins
const ARTICLE_PAGE_CSS_LIST = ginfo => [
  {id: 'style', params: null, url: PUBLIC_URL + '/common/js/plug_module/style.css'}
]
const ARTICLE_PAGE_JS_LIST = (ginfo, params) => [
  {id: 'jquery.2', params: null, url: PUBLIC_URL + '/common/js/jquery.2.js'},
  {id: 'jquery.form', params: null, url: PUBLIC_URL + '/common/js/jquery.form.js'},
  {id: 'plug-in_module', params: null, url: PUBLIC_URL + '/common/js/plug_module/plug-in_module.js'},

  {id: 'ginfo_autoplus', params: ginfo, url: PUBLIC_URL + '/common/js/ginfo_autoplus.js'},

  {id: 'aws_autoplus', params: PUBLIC_URL, url: PUBLIC_URL + '/common/js/aws_autoplus.js'},
  {id: 'aw_template_autoplus', params: null, url: PUBLIC_URL + '/common/js/aw_template_autoplus.js'},
  {id: 'app_autoplus', params: null, url: PUBLIC_URL + '/common/js/app_autoplus.js'},
  {id: 'app_helper', params: null, url: PUBLIC_URL + '/common/js/app_helper.js'},
  ////////////////////
  {id: 'article_helper', params: params, url: PUBLIC_URL + '/common/js/app/article_helper.js'},
  ///////////////////
  {
    predication: (ieVersion() > 0 && ieVersion() <= 8),
    list: [
      {id: 'jquery-1.9.1', params: null, url: PUBLIC_URL + '/common/theme/lime/js/jquery-1.9.1.js'}
    ]
  },
  {id: 'jquery.superslide.2.1.1', params: null, url: PUBLIC_URL + '/common/theme/lime/js/jquery.superslide.2.1.1.js'},
  {id: 'layout', params: null, url: PUBLIC_URL + '/common/theme/lime/js/layout.js'}
]
export const articlePagePlugins = (ginfo, params) => {
  loadPlugins(ARTICLE_PAGE_CSS_LIST(ginfo), loadCSS)
  loadPlugins(ARTICLE_PAGE_JS_LIST(ginfo, params), loadScript)
}

//people page plugins
const PEOPLE_PAGE_CSS_LIST = ginfo => [
  {id: 'style', params: null, url: PUBLIC_URL + '/common/js/plug_module/style.css'}
]
const PEOPLE_PAGE_JS_LIST = (ginfo, params) => [
  {id: 'jquery.2', params: null, url: PUBLIC_URL + '/common/js/jquery.2.js'},
  {id: 'jquery.form', params: null, url: PUBLIC_URL + '/common/js/jquery.form.js'},
  {id: 'plug-in_module', params: null, url: PUBLIC_URL + '/common/js/plug_module/plug-in_module.js'},

  {id: 'ginfo_autoplus', params: ginfo, url: PUBLIC_URL + '/common/js/ginfo_autoplus.js'},

  {id: 'aws_autoplus', params: PUBLIC_URL, url: PUBLIC_URL + '/common/js/aws_autoplus.js'},
  {id: 'aw_template_autoplus', params: null, url: PUBLIC_URL + '/common/js/aw_template_autoplus.js'},
  {id: 'app_autoplus', params: null, url: PUBLIC_URL + '/common/js/app_autoplus.js'},
  {id: 'app_helper', params: null, url: PUBLIC_URL + '/common/js/app_helper.js'},
  ////////////////////
  {id: 'people_helper', params: params, url: PUBLIC_URL + '/common/js/app/people_helper.js'},
  ///////////////////
  {
    predication: (ieVersion() > 0 && ieVersion() <= 8),
    list: [
      {id: 'jquery-1.9.1', params: null, url: PUBLIC_URL + '/common/theme/lime/js/jquery-1.9.1.js'}
    ]
  },
  {id: 'jquery.superslide.2.1.1', params: null, url: PUBLIC_URL + '/common/theme/lime/js/jquery.superslide.2.1.1.js'},
  {id: 'layout', params: null, url: PUBLIC_URL + '/common/theme/lime/js/layout.js'}
]
export const PeoplePagePlugins = (ginfo, params) => {
  loadPlugins(PEOPLE_PAGE_CSS_LIST(ginfo), loadCSS)
  loadPlugins(PEOPLE_PAGE_JS_LIST(ginfo, params), loadScript)
}
