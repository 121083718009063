/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';
import * as tools from '../controller/tools'
import LoadMore from './LoadMore'

const LeftNav = ({quesSquare, switchSquare, setSquare, getSquare}) => {
  let toSquare = type => e => {setSquare(type);switchSquare(type)}
  let hotTopics = (quesSquare.hasOwnProperty('sidebar_hot_topics') && tools.isArray(quesSquare.sidebar_hot_topics)) ? quesSquare.sidebar_hot_topics : []
  return (
  <div className="left_nav">
    <dl className="nav2 cur" >
      <dt><a href="javascript:void(0);" onClick={toSquare('new')}>问答</a></dt>
      <dd>
        <a href="javascript:void(0);" onClick={toSquare('new')} className={(getSquare() === 'new') ? 'Select' : ''}>最新</a>
        <a href="javascript:void(0);" onClick={toSquare('hot')} className={(getSquare() === 'hot') ? 'Select' : ''}>热门</a>
      </dd>
    </dl>
    <dl className="nav4 cur" >
      <dt><a href="javascript:void(0)">推荐话题</a></dt>
      <dd className="tjht">
      {[...hotTopics].map(t=> <a href={"/topic/" + t.topic_title}><img src={t.topic_pic} alt={t.topic_title}/>{t.topic_title}</a>)}
      </dd>
    </dl>
  </div>
  )
}

const MiddleArea = ({ginfo, quesSquare, getSquare, more}) => {
  let postsList = quesSquare.hasOwnProperty('posts_list') ? (tools.isArray(quesSquare.posts_list) ? quesSquare.posts_list : []) : []
  let curQuesCount = quesSquare.hasOwnProperty('cur_question_count') ? quesSquare.cur_question_count : postsList.length
  return (
  <div className="Middle_area ">
    <div className="wenda">
    {
      [...postsList].map((post, i) => {
        let userInfo = post.hasOwnProperty('user_info') ? post.user_info : {}
        return (
        <dl>
          <dt>
            {
              (post.anonymous === 0) 
              ? <a href={"/people/" + userInfo.url_token} className="aw-user-name" data-id={userInfo.uid}>
                <img src={userInfo.avatar_file} alt={userInfo.user_name}/>{userInfo.user_name}</a>
              : <a href="javascript:void(0);" className="aw-user-name"><img src={require("../../img/avatar-mid-img.png")} alt="匿名用户"/>匿名用户</a>
            }
            {
              (post.answer_count > 0) 
              ? <span><span>回复了问题（{post.answer_count}回复）</span><label className="fr">{post.update_time}</label></span> 
              : <span><span>发起了问题</span><label className="fr">{post.add_time}</label></span>
            }
          </dt>
          <dd><p className="bold">{(i < 3) ? <em className="ding"></em> : ''}<a href={"/question/" + post.question_id}>{post.question_content}</a></p></dd>
          <div className="foot_bq">
            <label className="browse"><i>{post.view_count} </i>次浏览</label>
            <label className="follow"><i>{post.focus_count}</i>人关注</label>
          </div>
        </dl>
        )
      })
    }
    </div>

    <div className="mbw auto-square">
        <div className="load-more btn">
          <div className="load_more_div">
            <div className="load_more"><LoadMore perPage={ginfo.G_PER_PAGE} curRows={curQuesCount} loadEvent={e=>more(getSquare())}/></div>
          </div>
        </div>
    </div>
    
  </div>
  )
}

const RightArea = ({quesSquare}) => {
  let thisUser = quesSquare.hasOwnProperty('this_user') ? quesSquare.this_user : {}
  let thisUserInfo = thisUser.hasOwnProperty('user_info') ? thisUser.user_info : {}
  let hotUsers = quesSquare.hasOwnProperty('sidebar_hot_users') && tools.isArray(quesSquare.sidebar_hot_users) ? quesSquare.sidebar_hot_users : []
  return (
  <div className="right_area ">
    {/* 个人 */}
    {
      (thisUser.user_id > 0) ?
        <div className="personal" style={{position:'static'}}>
          <dl>
            <dt><a href={"/people/" + thisUserInfo.url_token}><img src={thisUserInfo.avatar_file} alt="" /></a></dt>
            <dd><a href={"/people/" + thisUserInfo.url_token}>{thisUserInfo.user_name}</a></dd>
          </dl>
          <ul>
            <li><span>赞同</span><label>{thisUserInfo.agree_count}</label></li>
            <li><span>威望</span><label>{thisUserInfo.reputation}</label></li>
            <div className="clear"></div>
          </ul>
        </div>
      : ''
    }

    {/* 公告板块
    <div class="right_Notice">
      <h4>公告<a href="/announce/index">查看更多</a></h4>
      <ul>
        <li><a href="/announce/detail/id-106">【奖励发放】<em class="new"></em></a></li>
        <li><a href="/announce/detail/id-105">【奖励发放】</a></li>
        <li><a href="/announce/detail/id-104">【奖励发放】</a></li>
      </ul>
    </div>
    */}	

    {/* 热门用户 */}
    <div class="right_Notice">
	    <h4>热门用户<a href="/people/">查看更多</a></h4>
      {
        [...hotUsers].map(u => {
          return (
          <dl>
            <dt><a href={"/people/" + u.url_token}><img src={u.avatar_file} alt=""/></a></dt>
            <dd>
              <a href={"/people/" + u.url_token} data-id={u.uid} className="aw-user-name">{u.user_name}</a>
              <p><i>{u.answer_count}</i>个问题，<i>{u.agree_count}</i>人赞同</p>
            </dd>
            <div className="clear"></div>
          </dl>
          )
        })
      }
		</div>

    {/* 广告板块
    <div class="right_Notice az_download" style={{width:'256px',height:'100%',padding:'0px'}}>
			<img onclick="goUrl(11,'https://www.chainfor.com/app/index.html');" style={{cursor:'pointer', width:'100%', height:'80px', margin:'0px 0px 8px 0px'}} src="/uploads/common/1552626853.jpg" alt="链向财经" />
			<img onclick="goUrl(15,'https://www.hellobtc.com/gzh/rumen/?b2  ');" style={{cursor:'pointer',width:'100%', height:'80px', margin:'0px'}} src="/uploads/common/1569216771.jpg" alt="白话区块链" />
    </div>
    */}

    {/* APP二维码下载板块
    <div class="right_Notice b_pp"><img src="../uploads/common/1540824923.jpg" alt="" /></div>
    */}
  </div>
  )
}

class QuesMain extends Component {

  constructor(props) {
    super(props)
    this.whereSquare = 'new'
    this.setSquare = this.setSquare.bind(this)
    this.getSquare = this.getSquare.bind(this)
  }

  setSquare(where) {this.whereSquare = where}
  getSquare() {return this.whereSquare}

  render() {
    let {ginfo, quesSquare, switchSquare, more} = this.props
    return (
    <div className="w1150 Main_area">
      <LeftNav quesSquare={quesSquare} switchSquare={switchSquare} setSquare={this.setSquare} getSquare={this.getSquare}/>
      <MiddleArea ginfo={ginfo} quesSquare={quesSquare} getSquare={this.getSquare} more={more}/>
      <RightArea quesSquare={quesSquare}/>
      <div className="clear"></div>
    </div>)
  }
}

export default QuesMain
