/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Link} from 'react-router-dom'
import * as tools from '../controller/tools'
import LoadMore from './LoadMore'

const LeftNav = ({id, sortKey, ques}) => {
  const columns = [{'agree_count':'按票数排序'},{'add_time':'按时间排序'}]
  const linkCol = (id, sortKey, col) => {
    let to = "/question/" + id + "/" + Object.keys(col)[0]
    let selected = (sortKey === Object.keys(col)[0]) ? 'Select' : ''
    return (<Link className={selected} to={to}>{Object.values(col)[0]}</Link>)
  }
  return (
  <div className="left_nav">
    <dl className="nav10 cur">
        <dt><a href="javascript:void(0);">回复 {ques.hasOwnProperty('answer_count') ? ques.answer_count : 0}</a></dt>
        <dd>{columns.map(col => linkCol(id, sortKey, col))}</dd>
    </dl>
  </div>
  )
}

const QuesAnswer = ({index, ginfo, thisUser, answer, quesInfo}) => {
  let thisUserInfo = thisUser.hasOwnProperty('user_info') ? thisUser.user_info : {}
  let answerUserInfo = answer.hasOwnProperty('user_info') ? answer.user_info : {}
  const agreeByMore = e => {
    window.$('#agreeByMore'+index).parents('.aw-agree-by').find('em,a').removeClass('collapse')
    window.$('#agreeByMore'+index).remove()
  }
  const agreeVote = e => {
    if (thisUser.user_id && (thisUserInfo.user_name !== answerUserInfo.user_name)) {
      window.AWS.User.agree_vote('#agree_vote'+index, thisUserInfo.user_name, answer.answer_id)
    }
  }
  const disAgreeVote = e => {
    if (thisUser.user_id && (thisUserInfo.user_name !== answerUserInfo.user_name)) {
      window.AWS.User.disagree_vote('#disagree_vote'+index, thisUserInfo.user_name, answer.answer_id)
    }
  }
  const favorite = e => {window.AWS.dialog('favoriteTag', {item_id:answer.answer_id, item_type:'answer'})}
  const commentShare = e => {window.question_comment_share('#question_comment_share'+index)}
  return (
    <li className="aw-item">
      <div className="details_top">
          {(answer.anonymous === 0) ?
          <dl>
            <dt>
              <a className="aw-user-name" href={'/people/' + answerUserInfo.url_token} data-id={answer.uid}>
              <img src={answerUserInfo.avatar_file} alt={answerUserInfo.user_name}/></a>
            </dt>
            <dd>
              <a className="aw-user-name" href={'/people/' + answerUserInfo.url_token} data-id={answer.uid} >{answerUserInfo.user_name}</a>
              <label>{answer.add_time}</label>
              <p>{answerUserInfo.introduction}</p>
            </dd>
            <div className="clear"></div>
          </dl> : 
          <dl>
            <dt>
              <a className="aw-user-name" href="javascript:void(0);">
              <img src={ginfo.G_STATIC_URL + '/common/avatar-mid-img.png'} alt=""/></a>
            </dt>
            <dd>
              <a className="aw-user-name" href="javascript:void(0);" >匿名用户</a>
              <label>{answer.add_time}</label>
            </dd>
            <div className="clear"></div>
          </dl>
          }
      </div>
      
      <div className="Agree_with">
        {
          tools.isEmpty(answer.agree_users) ? '' : 
          <span className="aw-agree-by "> 赞同来自:
            {
              (Object.values(answer.agree_users).length === 0) ? '' : 
              [...Object.values(answer.agree_users)].map((v,k) => {
              return (k > 0) ?
                (<span>
                  <em className={(k >= 5) ? 'collapse': ''}  >、</em>
                  <a href={'/people/' + v.url_token} data-id={v.uid} className={'aw-user-name ' + ((k >= 5) ? 'collapse' : '')}>{v.user_name}</a>
                </span>)
              :
                (<span>
                  <a href={'/people/' + v.url_token} data-id={v.uid} className={'aw-user-name ' + ((k >= 5) ? 'collapse' : '')}>{v.user_name}</a>
                </span>)
              })
            }
            {
              (Object.values(answer.agree_users).length <= 5) ? '' :
              <a href="javascript:void(0);" className="more" id={"agreeByMore"+index} onClick={agreeByMore}>更多 >></a>
            }
          </span>
        }
      </div>

      <div className="Paran_box" dangerouslySetInnerHTML={{__html: tools.htmlUnescape(answer.answer_content)}}></div>

      <div className="hdbox">
        <div className="foot_bq">
          <div className="zkzd operate">
            <span id={'agree_vote'+index} className={'agree ' + ((thisUserInfo.user_name === answerUserInfo.user_name) ? 'disabled grayon ' : '') + ((answer.agree_status === 1) ? 'active ' : '') + ((!thisUser.user_id) ? 'disabled grayon' : '')} onClick={agreeVote}>
            <em className="up"></em><i className="count">{answer.agree_count}</i></span>
            <span id={'disagree_vote'+index} className={'disagree ' + ((thisUserInfo.user_name === answerUserInfo.user_name) ? 'disabled grayon ' : '') + ((answer.agree_status === -1) ? 'active ' : '') + ((!thisUser.user_id) ? 'disabled grayon' : '')} onClick={disAgreeVote}>
            <em className="down"></em><i className="count">{answer.against_count}</i></span>
          </div>

          <label className="comment aw-add-comment shows" data-id={answer.answer_id} data-type="answer" data-comment-count={answer.comment_count}
          data-img={require("../../img/comment.png")}><i>{answer.comment_count > 0 ? answer.comment_count : 0}</i>人评论</label>

          <label className="sc" onClick={favorite}><i></i>收藏</label>
          <label className="fx"  style={{cursor:'pointer'}} data-id={ginfo.G_BASE_URL + '/m/question/' + quesInfo.question_id + '/agree_count/'  + answer.answer_id}
          data-name={answer.answer_content}  id={'question_comment_share'+ index} onClick={commentShare}>分享
          <div className="fx_list bdsharebuttonbox" style={{display:'none'}}>
            <ul>
                <li className="qq"><a style={{fontSize:'12px', lineHeight:'17px'}} href="#" className="bds_qzone" data-cmd="qzone" title="分享到QQ空间">分享到空间</a></li>
                <li className="wb"><a style={{fontSize:'12px', lineHeight:'17px'}} href="#" className="bds_tsina" data-cmd="tsina" title="分享到新浪微博">分享到微博</a></li>
                <li className="wx"><a style={{fontSize:'12px', lineHeight:'17px'}} href="#" className="bds_weixin" data-cmd="weixin" title="分享到微信">分享到微信</a></li>
                {/* 
                <li class="wx"><a href="javascript:void(0);" onClick={window.qrCodeGo('/question/'+ quesInfo.question_id + '/agree_count/'  + answer.answer_id)} title="分享到微信">分享到微信</a></li>
                */}
            </ul>
          </div>
          </label>
        </div>
      </div>
    </li>                  
  )
}
const MiddleArea = ({ginfo, thisUser, ques, more}) => {
  let answers = ques.hasOwnProperty('answers') ? ques.answers : {}
  let addAnswersCount = ques.hasOwnProperty('add_answers_count') ? ques.add_answers_count : [...Object.keys(answers)].length
  let quesInfo = ques.hasOwnProperty('question_info') ? ques.question_info : {}
  let draftContent = ques.hasOwnProperty('draft_content') ? ques.draft_content : {}
  let draftMessage = draftContent.hasOwnProperty('message') ? draftContent.message : ''
  const reply = e => {
    window.AWS.ajax_post(window.$('#answer_form'), window.AWS.ajax_processer, 'reply_question')
  }
  return (
  <div className="Middle_area ">
      <div className="details_box">
        {
          ([...Object.values(answers)].length === 0) ? '' :
          <ul>
            {[...Object.values(answers)].map((answer,i) => <QuesAnswer index={i} ginfo={ginfo} thisUser={thisUser} answer={answer} quesInfo={quesInfo}/>)}
            <li className="load_more_li">
              <div className="load_more"><LoadMore perPage={ginfo.G_PER_PAGE} curRows={addAnswersCount} loadEvent={more}/></div>
            </li>
          </ul>
        }
        
        {/* <div className="mbw"><div className="pg"></div></div> 
        <div className="load_more"><LoadMore perPage={ginfo.G_PER_PAGE} curRows={addAnswersCount} loadEvent={more}/></div> */}

	    	<div style={{display: 'none'}} id="edit"></div>
        <form action={ginfo.G_BASE_URL + "/question/ajax/save_answer/"} onsubmit="return false;" method="post" id="answer_form" className="question_answer_form">
          <input type="hidden" name="post_hash" value={ginfo.G_POST_HASH}/>
          <input type="hidden" name="question_id" value={quesInfo.question_id}/>
          <input type="hidden" name="attach_access_key" value={ques.attach_access_key}/>
          <div className="Paran_text">
              <div className="wmd-panel">
              <script className="fuwenben" name="answer_content" id="container" type="text/plain" dangerouslySetInnerHTML={{__html:tools.htmlUnescape(draftMessage)}}></script>
              </div>
          </div>
          <div class="tips">
            {/* <p>如需问题补充中@其他用户,需要在名字后面添加空格。例如：我有一个问题想要@陈老师 来解答。</p> */}
            <span style={{color: '#999999', fontSize: '10px'}} id="answer_content_message">&nbsp;</span>
          </div>
          <div className="Put_questions">
            <span className="foucs cur">
              <em></em>	关注问题	                		
              <input type="checkbox" style={{display: 'none'}} className="auto_focus" checked="checked" value="1" name="auto_focus"/>
            </span>
            <span className="anonymous ">
              <em></em>
              <input style={{display: 'none'}} type="checkbox" class="pull-left" value="0" name="anonymous"/>匿名回复                    
            </span>
            <a href="javascript:void(0);" className="btn btn-normal btn-success pull-right btn-reply" id="publish_submit" onClick={reply}>回复</a>
            <div className="clear"></div>
          </div>
        </form>
      </div>
      <input type="hidden" id="share_content" value=""/>
      <input type="hidden" id="share_url" value=""/>
      <input type="hidden" id="share_title" value=""/>
  </div>
  )
}

const Initiator = ({thisUser, ques}) => {
  let quesInfo = ques.hasOwnProperty('question_info') ? ques.question_info : {}
  let quesUserInfo = quesInfo.hasOwnProperty('user_info') ? quesInfo.user_info : {}
  const initiatorFollow = e => {
    window.follow(window.$('#initiator_follow'), 'user', quesUserInfo.uid)
  }
  return (
    !(quesInfo.anonymous === 0) ? '' :
    <div className="personal Initiator">
      <h4>发起人</h4>
      <dl>
        <dt>
        <a className="aw-user-name" href={'/people/' + quesUserInfo.url_token}>
        <img alt={quesUserInfo.user_name} src={quesUserInfo.avatar_file}/>
        </a>
        </dt>
        <dd>
        <a className="aw-user-name" href={'/people/' + quesUserInfo.url_token} data-id={quesUserInfo.uid}>{quesUserInfo.user_name}</a>
        <span>{!tools.isEmpty(quesUserInfo.introduction) ? quesUserInfo.introduction : '暂无介绍'}</span>
        </dd>
        <div className="clear"></div>
      </dl>
      <ul>
        <li>
          <span>赞同</span>
          <label>{quesUserInfo.agree_count}</label>
        </li>
        <li>
          <span>威望</span>
          <label>{quesUserInfo.reputation}</label>
        </li>
        <div className="clear"></div>
      </ul>
      <div className="Invitation_but">
        {
          ((!thisUser.user_id) || ((!quesUserInfo.uid) && (thisUser.user_id === quesUserInfo.uid))) 
          ? <label>+关注</label> : <button id="initiator_follow" onClick={initiatorFollow}>{ques.user_follow_check?'取消关注':'+关注'}</button>
        }
      </div>
    </div>
  )
}

const RelateQues = ({ques}) => {
  let quesRelatedList = ques.question_related_list
  let quesItem = q => {
    return <li><a href={'/question/' + q.question_id}>{q.question_content}</a></li>
  }
  return (
    !ques.question_related_list ? '' :
    <div className="tj_articles">
      <h4>相关问题</h4>
      <ul>{[...Object.values(quesRelatedList)].map(q => quesItem(q))}</ul>
    </div>
  )
}

const PersonConcern = ({ques}) => {
  let quesInfo = ques.hasOwnProperty('question_info') ? ques.question_info : {}
  return (
    <div className="person_concern">
      <h4>浏览: {quesInfo.view_count}</h4>
      <p>最新活动: {quesInfo.update_time}</p>
      <div className="t_line"></div>
      <h4>关注: {quesInfo.focus_count} 人</h4>
      <ul id="focus_users"></ul>
      <div className="clear"></div>
    </div>
  )
}

const RightArea = ({thisUser, ques}) =>
  <div className="right_area">
    <div className="right_box">
      <Initiator thisUser={thisUser} ques={ques}/>
      <RelateQues ques={ques}/>
      <PersonConcern ques={ques}/> 
    </div>
  </div>

const QuesPageMain = props => {
  let {ginfo, params, thisUser, question, more} = props
  let {id, sort_key} = params
  return (
  <div className="w1150 Main_area">
    <LeftNav id={id} sortKey={sort_key} ques={question}/>
    <MiddleArea ginfo={ginfo} thisUser={thisUser} ques={question} more={more}/>
    <RightArea thisUser={thisUser} ques={question}/>
    <div className="clear"></div>
  </div>)
}
export default QuesPageMain
