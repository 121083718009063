import React, { Component } from 'react'
import {Provider} from 'react-redux'
import store from '../model/store'
import {URL} from '../controller/constants'
import * as tools from '../controller/tools'
import Whoops404 from './Whoops'
import {
  homeSquare, quesSquare, articleSquare, topicSquare, peopleSquare,
  changeQuesPage, changeArticlePage, changeTopicPage, changePeoplePage,
  changeUserActions, updateStatus, changeSearchContent, changePublishContent,
  changeNotiContent, changeInboxContent, changeAccountContent, changeSettingContent} from "../controller/actions";
import {
  homeColumnPlugins, quesColumnPlugins, articlesColumnPlugins, topicsColumnPlugins,
  peopleColumnPlugins, PeoplePagePlugins, searchColumnPlugins, notiColumnPlugins,
  publishPlugins, quesPagePlugins, articlePagePlugins, topicPagePlugins,
  inboxColumnPlugins, accountColumnPlugins, accountSettingPlugins} from '../controller/plugins'
import {
  TopNavContainer, HomeSloganContainer, HomeMainContainer, QuesSloganContainer, 
  QuesMainContainer, QuesBoxContainer, QuesPageMainContainer, ArticlesMainContainer,
  ArticlePageMainContainer, TopicsSloganContainer, TopicsMainContainer, TopicBoxContainer,
  TopicPageMainContainer, PeopleMainContainer, PeopleBoxContainer, PeoplePageMainContainer,
  SearchSloganContainer, SearchMainContainer, PublishMainContainer, NotiMainContainer,
  InboxMainContainer, AccountContainer, SettingContainer, FooterContainer, GoTopContainer} from '../controller/container'

const Loading = props => 
  <div className="auto-center">
    <img src={require("../../img/loading_a.gif")} alt=""/>
  </div>

const Logouting = props =>
  <div class="w1150 Main_area">
    <div className="cw404">
      <img src={require("../../img/wait_for.png")} alt=""/>
      <div className="exit_text"><p>正在准备退出, 请稍候...</p></div>
    </div>
  </div>


/**
 * column template
 */
export const ColumnTemplate = ({children, column}) =>
  <div>
    <TopNavContainer column={column}/>
    {children}
    <FooterContainer />
    <GoTopContainer />
  </div>

/**
 * home column
 */
export class HomeColumn extends Component {
  constructor(props) {
    super(props)
    this.state = {loading: true, pluginsOnce: true}
    this.exec = this.exec.bind(this)
  }

  exec() {
    this.setState({loading: false, pluginsOnce: true})
  }

  componentWillMount() {
    let ginfo = store.getState().ginfo
    let sortType = (ginfo.G_USER_ID > 0) ? 'hot' : 'new'
    store.dispatch(homeSquare(URL.HOME_API + ((sortType === 'hot') ? 'sort_type-hot__' : ''), this.exec))
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.pluginsOnce === nextState.pluginsOnce
  }

  componentDidUpdate() {
    if (this.state.pluginsOnce) {
      homeColumnPlugins(store.getState().ginfo) //the third party plug-ins
      this.setState({loading: false, pluginsOnce: false})
    }
  }

  render(){
    return (this.state.loading) ? <Loading/> :
      <Provider store = {store}>
        <ColumnTemplate column="home">
          <HomeSloganContainer />
          <HomeMainContainer />
        </ColumnTemplate>
      </Provider>
  }
}

/**
 * question column
 */
export class QuesColumn extends Component {
  constructor(props) {
    super(props)
    this.state = {loading: true, pluginsOnce: true}
    this.exec = this.exec.bind(this)
  }

  exec() {
    this.setState({loading: false, pluginsOnce: true})
  }

  componentWillMount() {
    store.dispatch(quesSquare(URL.QUESTIONS_API + 'index_square/', this.exec))
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.pluginsOnce === nextState.pluginsOnce
  }

  componentDidUpdate() {
    if (this.state.pluginsOnce) {
      quesColumnPlugins(store.getState().ginfo) //the third party plug-ins
      this.setState({loading: false, pluginsOnce: false})
    }
  }

  render(){
    return (this.state.loading) ? <Loading/> :
      <Provider store = {store}>
        <ColumnTemplate column="question">
          <QuesSloganContainer />
          <QuesMainContainer />
        </ColumnTemplate>
      </Provider>
  }
}

export class QuesPage extends Component {
  constructor(props) {
    super(props)
    this.state = {loading: true, pluginsOnce: true}
    this.exec = this.exec.bind(this)
  }

  exec() {
    this.setState({loading: false, pluginsOnce: true})
  }

  componentWillMount() {
    let {id, sort_key, answer_id} = this.props.match.params
    sort_key = !tools.isEmpty(sort_key) ? sort_key : 'agree_count'
    this.props.match.params = {...this.props.match.params, sort_key}
    if (tools.isNumber(id)) {
      let url = URL.QUESTIONS_API + 'id-' + id
      if (sort_key === 'agree_count') {
        url = url + '__sort_key-' + sort_key + '__sort-ASC'
      }
      if (sort_key === 'add_time') {
        url = url + '__sort_key-' + sort_key + '__sort-DESC'
      }
      if (!tools.isEmpty(answer_id)) {
        url = url + '__answer_id-' + answer_id
      }
      store.dispatch(changeQuesPage(url, this.exec))
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.pluginsOnce === nextState.pluginsOnce
  }

  componentDidUpdate() {
    if (this.state.pluginsOnce) {
      const {question} = store.getState().question
      const quesInfo = (question.hasOwnProperty('question_info')) ? question.question_info : {}
      let params = {
        item_ids: question.item_ids,
        comment_unfold: question.comment_unfold,
        uninterested_count: question.uninterested_count,
        answer_edit_time: question.answer_edit_time,
        user_answered: question.hasOwnProperty('user_answered') ? question.user_answered : '',
        upload_enable: question.upload_enable,
        question_id: quesInfo.question_id,
        human_valid: question.human_valid,
        attach_access_key: question.attach_access_key
      }
      quesPagePlugins(store.getState().ginfo, params) //the third party plug-ins
      this.setState({loading: false, pluginsOnce: false})
    }
  }

  render(){
    let {id} = this.props.match.params
    return (!tools.isNumber(id)) ? <Whoops404 />: ((this.state.loading) ? <Loading/> :
      <Provider store = {store}>
        <ColumnTemplate column="question_page">
          <QuesBoxContainer {...this.props} />
          <QuesPageMainContainer {...this.props} />
        </ColumnTemplate>
      </Provider>
    )
  }
}

/**
 * article column
 */
export class ArticlesColumn extends Component {
  constructor(props) {
    super(props)
    this.state = {loading: true, pluginsOnce: true}
    this.exec = this.exec.bind(this)
  }

  exec() {
    this.setState({loading: false, pluginsOnce: true})
  }

  componentWillMount() {
    store.dispatch(articleSquare(URL.ARTICLES_API + 'index_square/', this.exec))
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.pluginsOnce === nextState.pluginsOnce
  }

  componentDidUpdate() {
    if (this.state.pluginsOnce) {
      articlesColumnPlugins(store.getState().ginfo) //the third party plug-ins
      this.setState({loading: false, pluginsOnce: false})
    }
  }

  render() {
    return (this.state.loading) ? <Loading/> :
      <Provider store = {store}>
        <ColumnTemplate column="article">
          <ArticlesMainContainer />
        </ColumnTemplate>
      </Provider>
  }
}

export class ArticlePage extends Component {
  constructor(props) {
    super(props)
    this.state = {loading: true, pluginsOnce: true}
    this.exec = this.exec.bind(this)
  }

  exec() {
    this.setState({loading: false, pluginsOnce: true})
  }

  componentWillMount() {
    let {id} = this.props.match.params
    if (tools.isNumber(id)) {
      store.dispatch(changeArticlePage(URL.ARTICLES_API + id, this.exec))
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.pluginsOnce === nextState.pluginsOnce
  }

  componentDidUpdate() {
    if (this.state.pluginsOnce) {
      const {article} = store.getState().article
      let params = {
        human_valid: article.human_valid
      }
      articlePagePlugins(store.getState().ginfo, params) //the third party plug-ins
      this.setState({loading: false, pluginsOnce: false})
    }
  }

  render(){
    let {id} = this.props.match.params
    return (!tools.isNumber(id)) ? <Whoops404 />: ((this.state.loading) ? <Loading/> :
      <Provider store = {store}>
        <ColumnTemplate column="article_page">
          <ArticlePageMainContainer {...this.props} />
        </ColumnTemplate>
      </Provider>
    )
  }
}

/**
 * topic column
 */
export class TopicsColumn extends Component {
  constructor(props) {
    super(props)
    this.state = {loading: true, pluginsOnce: true}
    this.exec = this.exec.bind(this)
  }

  exec() {
    this.setState({loading: false, pluginsOnce: true})
  }

  componentWillMount() {
    store.dispatch(topicSquare(URL.TOPICS_API + 'index_square/', this.exec))
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.pluginsOnce === nextState.pluginsOnce
  }

  componentDidUpdate() {
    if (this.state.pluginsOnce) {
      topicsColumnPlugins(store.getState().ginfo) //the third party plug-ins
      this.setState({loading: false, pluginsOnce: false})
    }
  }

  render(){
    return (this.state.loading) ? <Loading/> :
      <Provider store = {store}>
        <ColumnTemplate column="topic">
          <TopicsSloganContainer />
          <TopicsMainContainer />
        </ColumnTemplate>
      </Provider>
  }
}

export class TopicPage extends Component {
  constructor(props) {
    super(props)
    this.state = {loading: true, pluginsOnce: true}
    this.exec = this.exec.bind(this)
  }

  exec() {
    this.setState({loading: false, pluginsOnce: true})
  }

  componentWillMount() {
    let {id} = this.props.match.params
    if (!tools.isEmpty(id)) {
      store.dispatch(changeTopicPage(URL.TOPICS_API + id, this.exec))
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.pluginsOnce === nextState.pluginsOnce
  }

  componentDidUpdate() {
    if (this.state.pluginsOnce) {
      const topic = store.getState().topic
      let params = {
        topic_id: topic.topic_info.topic_id,
        contents_topic_id: topic.contents_topic_id,
        contents_related_topic_ids: topic.contents_related_topic_ids,
        contents_topic_title: topic.contents_topic_title
      }
      topicPagePlugins(store.getState().ginfo, params) //the third party plug-ins
      this.setState({loading: false, pluginsOnce: false})
    }
  }

  render(){
    let {id} = this.props.match.params
    return (tools.isEmpty(id)) ? <Whoops404 />: ((this.state.loading) ? <Loading/> :
      <Provider store = {store}>
        <ColumnTemplate column="topic_page">
          <TopicBoxContainer {...this.props} />
          <TopicPageMainContainer {...this.props} />
        </ColumnTemplate>
      </Provider>
    )
  }
}

/**
 * people column
 */
export class PeopleColumn extends Component {
  constructor(props) {
    super(props)
    this.state = {loading: true, pluginsOnce: true}
    this.exec = this.exec.bind(this)
  }

  exec() {
    this.setState({loading: false, pluginsOnce: true})
  }

  componentWillMount() {
    store.dispatch(peopleSquare(URL.PEOPLE_API + 'index_square/', this.exec))
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.pluginsOnce === nextState.pluginsOnce
  }

  componentDidUpdate() {
    if (this.state.pluginsOnce) {
      peopleColumnPlugins(store.getState().ginfo) //the third party plug-ins
      this.setState({loading: false, pluginsOnce: false})
    }
  }

  render() {
    return (this.state.loading) ? <Loading/> :
      <Provider store = {store}>
        <ColumnTemplate column="people">
          <PeopleMainContainer />
        </ColumnTemplate>
      </Provider>
  }
}

export class PeoplePage extends Component {
  constructor(props) {
    super(props)
    this.state = {loading: true, pluginsOnce: true}
    this.exec1 = this.exec1.bind(this)
    this.exec2 = this.exec2.bind(this)
  }

  exec1() {
    const people = store.getState().people
    let uid = people.user.uid
    store.dispatch(changeUserActions(URL.USER_ACTIONS_API, {uid, actions: 'all'}, this.exec2))
  }

  exec2() {
    this.setState({loading: false, pluginsOnce: true})
  }

  componentWillMount() {
    let {id} = this.props.match.params
    if (!tools.isEmpty(id)) {
      store.dispatch(changePeoplePage(URL.PEOPLE_API + id, this.exec1))
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.pluginsOnce === nextState.pluginsOnce
  }

  componentDidUpdate() {
    if (this.state.pluginsOnce) {
      const people = store.getState().people
      let params = {
        people_user_id: people.user.uid,
        activity_actions: people.activity_actions
      }
      PeoplePagePlugins(store.getState().ginfo, params) //the third party plug-ins
      this.setState({loading: false, pluginsOnce: false})
    }
  }

  render(){
    let {id} = this.props.match.params
    return (tools.isEmpty(id)) ? <Whoops404 />: ((this.state.loading) ? <Loading/> :
      <Provider store = {store}>
        <ColumnTemplate column="people_page">
          <PeopleBoxContainer {...this.props} />
          <PeoplePageMainContainer {...this.props} />
        </ColumnTemplate>
      </Provider>
    )
  }
}

/**
 * search column
 */
export class SearchColumn extends Component {

  constructor(props) {
    super(props)
    this.state = {loading: true, pluginsOnce: true}
    this.exec = this.exec.bind(this)
  }

  exec() {
    this.setState({loading: false, pluginsOnce: true})
  }

  componentWillMount() {
    let params = this.props.match.params
    let {type, page, is_recommend} = params
    type = (type) ? type : 'all'
    page = (page) ? page : 1
    is_recommend = (is_recommend) ? is_recommend : 0
    params = {...params, type, page, is_recommend}
    store.dispatch(updateStatus(params))

    let status = store.getState().search.status
    const handle = json => {
      if (json.rsm && json.rsm.raw_query) {
        let raw_query = json.rsm.raw_query
        let params = {...status, raw_query}
        store.dispatch(changeSearchContent(URL.SEARCH_API, params, this.exec))
      }
    }
    let {query} = status
    let body = {q_base64:query}
    tools.httpRequest(handle, URL.SEARCH_API, 'POST', body)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.pluginsOnce === nextState.pluginsOnce
  }

  componentDidUpdate() {
    if (this.state.pluginsOnce) {
      searchColumnPlugins(store.getState().ginfo) //the third party plug-ins
      this.setState({loading: false, pluginsOnce: false})
    }
  }

  render(){
    return (
      (this.state.loading) ? <Loading/> :
      <Provider store = {store}>
        <ColumnTemplate column="search">
          <SearchSloganContainer {...this.props} />
          <SearchMainContainer {...this.props}/>
        </ColumnTemplate>
      </Provider>
    )
  }
}

/**
 * publish column
 */
export class PublishColumn extends Component {
  constructor(props) {
    super(props)
    this.state = {loading: true, pluginsOnce: true}
    this.exec = this.exec.bind(this)
  }

  exec() {
    this.setState({loading: false, pluginsOnce: true})
  }

  componentWillMount() {
    let {type, id} = this.props.match.params
    let url = (type === 'question') ? URL.PUBLISH_QUES_API : URL.PUBLISH_ARTICLE_API
    if ((type === 'question') || (type === 'article')) {
      id = (tools.isNumber(id)) ? id : ''
      store.dispatch(changePublishContent(url + id, type, this.exec))
    }else{
      this.exec()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.pluginsOnce === nextState.pluginsOnce
  }

  componentDidUpdate() {
    if (this.state.pluginsOnce) {
      const {type} = this.props.match.params
      const ginfo = store.getState().ginfo
      const {question, article} = store.getState().publish
      const quesInfo = (question.hasOwnProperty('question_info')) ? question.question_info : {}
      const artInfo = (article.hasOwnProperty('article_info')) ? article.article_info : {}
      let params = {
        publish_type: type,
        answer_type: type,
        human_valid: ginfo.G_QUICK_PUBLISH_HUMAN_VALID,
        column_id: (type === 'article') ? artInfo.column_id : '',
        page_id: (type === 'question') ?
                ((quesInfo.hasOwnProperty('question_id')) ? quesInfo.question_id : 0) :
                ((artInfo.hasOwnProperty('id')) ? artInfo.id : 0),
        category_id: (type === 'question') ? 
                    ((quesInfo.hasOwnProperty('category_id')) ? quesInfo.category_id : 0) :
                    ((artInfo.hasOwnProperty('category_id')) ? artInfo.category_id : 0),
        attach_access_key: (type === 'question') ? question.attach_access_key : article.attach_access_key
      }
      publishPlugins(store.getState().ginfo, params) //the third party plug-ins
      this.setState({loading: false, pluginsOnce: false})
    }
  }

  render(){
    let {type} = this.props.match.params
    return ((type !== 'question') && (type !== 'article')) ? <Whoops404 /> : ((this.state.loading) ? <Loading/> :
      <Provider store = {store}>
        <ColumnTemplate column="publish">
          <PublishMainContainer {...this.props} />
        </ColumnTemplate>
      </Provider>
    )
  }
}

/**
 * notifications column
 */
export class NotiColumn extends Component {
  constructor(props) {
    super(props)
    this.state = {loading: true, pluginsOnce: true}
    this.exec = this.exec.bind(this)
  }

  exec() {
    this.setState({loading: false, pluginsOnce: true})
  }

  componentWillMount() {
    store.dispatch(changeNotiContent(URL.NOTIFICATIONS_API, this.exec))
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.pluginsOnce === nextState.pluginsOnce
  }

  componentDidUpdate() {
    if (this.state.pluginsOnce) {
      notiColumnPlugins(store.getState().ginfo) //the third party plug-ins
      this.setState({loading: false, pluginsOnce: false})
    }
  }

  render(){
    return (this.state.loading) ? <Loading/> :
      <Provider store = {store}>
        <ColumnTemplate column="notifications">
          <NotiMainContainer />
        </ColumnTemplate>
      </Provider>
  }
}

/**
 * inbox column
 */
export class InboxColumn extends Component {
  constructor(props) {
    super(props)
    this.state = {loading: true, pluginsOnce: true}
    this.exec = this.exec.bind(this)
  }

  exec() {
    this.setState({loading: false, pluginsOnce: true})
  }

  componentWillMount() {
    store.dispatch(changeInboxContent(URL.INBOX_API, this.exec))
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.pluginsOnce === nextState.pluginsOnce
  }

  componentDidUpdate() {
    if (this.state.pluginsOnce) {
      inboxColumnPlugins(store.getState().ginfo) //the third party plug-ins
      this.setState({loading: false, pluginsOnce: false})
    }
  }

  render(){
    return (this.state.loading) ? <Loading/> :
      <Provider store = {store}>
        <ColumnTemplate column="inbox">
          <InboxMainContainer />
        </ColumnTemplate>
      </Provider>
  }
}

/**
 * account column
 */
export class AccountColumn extends Component {
  constructor(props) {
    super(props)
    this.state = {loading: true, pluginsOnce: true}
    this.exec = this.exec.bind(this)
    this.getUrl = this.getUrl.bind(this)
  }

  exec() {
    let {route} = this.props.match.params
    if (route === 'logout') {
      const account = store.getState().account
      window.location = account.url
    } else {
      this.setState({loading: false, pluginsOnce: true})
    }
  }

  getUrl() {
    let {route, url} = this.props.match.params
    let ret = URL.ACCOUNT_API + (tools.isEmpty(route) ? 'login' : route) + '/'
    url = (tools.isEmpty(url)) ? '/' : decodeURIComponent(url)
    switch(route) {
      case 'register':
        let regRetUrl = (url.indexOf('/account/') < 0) ? url : '/'
        return (ret + 'type-mobile__return_url-' + regRetUrl)
      case 'login':
        let loginRetUrl = (url.indexOf('/account/') < 0) ? url : '/'
        return (ret + 'return_url-' + loginRetUrl)
      case 'find_password':
        return (ret)
      case 'logout':
        let logoutRetUrl = (url.indexOf('/account/') < 0) ? url : '/'
        return (ret + 'return_url-' + logoutRetUrl)
      default:
        return null
    }
  }

  componentWillMount() {
    let url = this.getUrl()
    if (!tools.isEmpty(url)) {
      store.dispatch(changeAccountContent(url, this.exec))
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.pluginsOnce === nextState.pluginsOnce
  }

  componentDidUpdate() {
    if (this.state.pluginsOnce) {
      const account = store.getState().account
      let params = {
        pub_key: account.pub_key
      }
      accountColumnPlugins(store.getState().ginfo, params) //the third party plug-ins
      this.setState({loading: false, pluginsOnce: false})
    }
  }

  render(){
    let {route} = this.props.match.params
    return (tools.isEmpty(this.getUrl())) ? <Whoops404 /> :
      ((this.state.loading) ? ((route === 'logout') ? <Logouting/> : <Loading/>) :
      <Provider store = {store}>
        <AccountContainer {...this.props}/>
      </Provider>
    )
  }
}

/**
 * account setting
 */
export class AccountSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {loading: true, pluginsOnce: true}
    this.exec = this.exec.bind(this)
    this.getAction = this.getAction.bind(this)
  }

  exec() {
    this.setState({loading: false, pluginsOnce: true})
  }

  getAction() {
    let {action} = this.props.match.params
    action = !tools.isEmpty(action) ? action : 'profile'
    switch(action) {
      case 'profile':
      case 'privacy':
      case 'change_mobile':
      case 'security':
        return action
      default:
        return null
    }
  }
  componentWillMount() {
    let action = this.getAction()
    store.dispatch(changeSettingContent(URL.SETTING_API + action + '/', this.exec))
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.pluginsOnce === nextState.pluginsOnce
  }

  componentDidUpdate() {
    if (this.state.pluginsOnce) {
      accountSettingPlugins(store.getState().ginfo) //the third party plug-ins
      this.setState({loading: false, pluginsOnce: false})
    }
  }

  render(){
    return (tools.isEmpty(this.getAction())) ? <Whoops404 /> :
    ((this.state.loading) ? <Loading/> :
      <Provider store = {store}>
        <ColumnTemplate column="setting">
          <SettingContainer {...this.props}/>
        </ColumnTemplate>
      </Provider>
    )
  }
}