import {CMD} from './constants.js'
import * as tools from './tools'

/**
 * global info
 */
export const addGlobalInfo = info => ({type: CMD.ADD_GLOBAL_INFO, ...info})
export const getGlobalInfo = (url, callback=f=>f) => (dispatch, getState) => {
  //json:{"rsm":{G_SITE_NAME:''}}
  const handleInfo = (dispatch, getState) => json => {
    if (json.rsm) {
      dispatch(addGlobalInfo(json.rsm))
    }
    if (typeof callback === "function") {callback()}
  }
  return tools.httpRequest(handleInfo(dispatch, getState), url)
}//todo: store.dispatch(getGlobalInfo(url))

/**
 * square actions
 */
export const buildHomeSquare = fields => ({type: CMD.BUILD_HOME_SQUARE, ...fields})
export const homeSquare = (url, callback=f=>f) => (dispatch, getState) => {
  //json:{"rsm":{"posts_list":[], ...}}
  const handleSquare = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      dispatch(buildHomeSquare(json.rsm))
      if (typeof callback === "function") {callback()}
    }
  }
  return tools.httpRequest(handleSquare(dispatch,getState), url)
}
export const addHome = fields => ({type: CMD.ADD_HOME_RESULT, ...fields})
export const addHomeResult = url => (dispatch, getState) => {
  const handleResult = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      dispatch(addHome(json.rsm))
    }
  }
  let page = getState().square.home.hasOwnProperty('cur_home_page') ? getState().square.home.cur_home_page : 1
  url = url + 'page-' + (++page)
  return tools.httpRequest(handleResult(dispatch, getState), url)
}

export const buildQuesSquare = fields => ({type: CMD.BUILD_QUES_SQUARE, ...fields})
export const quesSquare = (url, callback=f=>f) => (dispatch, getState) => {
  //json:{"rsm":{"posts_list":[], ...}}
  const handleSquare = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      dispatch(buildQuesSquare(json.rsm))
      if (typeof callback === "function") {callback()}
    }
  }
  return tools.httpRequest(handleSquare(dispatch,getState), url)
}
export const addQues = fields => ({type: CMD.ADD_QUES_RESULT, ...fields})
export const addQuesResult = url => (dispatch, getState) => {
  const handleResult = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      dispatch(addQues(json.rsm))
    }
  }
  let page = getState().square.question.hasOwnProperty('cur_question_page') ? getState().square.question.cur_question_page : 1
  url = url + 'page-' + (++page)
  return tools.httpRequest(handleResult(dispatch, getState), url)
}

export const buildArtSquare = fields => ({type: CMD.BUILD_ART_SQUARE, ...fields})
export const articleSquare = (url, callback=f=>f) => (dispatch, getState) => {
  //json:{"rsm":{"article_list":[], ...}}
  const handleSquare = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      dispatch(buildArtSquare(json.rsm))
      if (typeof callback === "function") {callback()}
    }
  }
  return tools.httpRequest(handleSquare(dispatch,getState), url)
}
export const addArticles = fields => ({type: CMD.ADD_ART_RESULT, ...fields})
export const addArticlesResult = url => (dispatch, getState) => {
  const handleResult = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      dispatch(addArticles(json.rsm))
    }
  }
  let page = getState().square.article.hasOwnProperty('cur_article_page') ? getState().square.article.cur_article_page : 1
  url = url + 'page-' + (++page)
  return tools.httpRequest(handleResult(dispatch, getState), url)
}

export const buildTopicSquare = fields => ({type: CMD.BUILD_TOPIC_SQUARE, ...fields})
export const topicSquare = (url, callback=f=>f) => (dispatch, getState) => {
  //json:{"rsm":{"topics_list":[], ...}}
  const handleSquare = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      dispatch(buildTopicSquare(json.rsm))
      if (typeof callback === "function") {callback()}
    }
  }
  return tools.httpRequest(handleSquare(dispatch,getState), url)
}
export const addTopic = fields => ({type: CMD.ADD_TOPIC_RESULT, ...fields})
export const addTopicResult = url => (dispatch, getState) => {
  const handleResult = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      dispatch(addTopic(json.rsm))
    }
  }
  let page = getState().square.topic.hasOwnProperty('cur_topic_page') ? getState().square.topic.cur_topic_page : 1
  url = url + 'page-' + (++page)
  return tools.httpRequest(handleResult(dispatch, getState), url)
}

export const buildPeopleSquare = fields => ({type: CMD.BUILD_PEOPLE_SQUARE, ...fields})
export const peopleSquare = (url, callback=f=>f) => (dispatch, getState) => {
  //json:{"rsm":{"users_list":[], ...}}
  const handleSquare = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      dispatch(buildPeopleSquare(json.rsm))
      if (typeof callback === "function") {callback()}
    }
  }
  return tools.httpRequest(handleSquare(dispatch,getState), url)
}
export const addPeople = fields => ({type: CMD.ADD_PEOPLE_RESULT, ...fields})
export const addPeopleResult = url => (dispatch, getState) => {
  const handleResult = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      dispatch(addPeople(json.rsm))
    }
  }
  let page = getState().square.people.hasOwnProperty('cur_people_page') ? getState().square.people.cur_people_page : 1
  url = url + 'page-' + (++page)
  return tools.httpRequest(handleResult(dispatch, getState), url)
}

/**
 * question actions
 */
export const changeQues = fields => ({type: CMD.CHANGE_QUES_PAGE, ...fields})
export const changeQuesPage = (url, callback=f=>f) => (dispatch, getState) => {
  //json:{"rsm":{"this_user":{},"question":{}}}
  const handleQuesPage = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      let thisUser = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm.this_user)) : {}
      let question = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm.question)) : {}
      dispatch(changeQues({thisUser, question}))
      if (typeof callback === "function") {callback()}
    }
  }
  return tools.httpRequest(handleQuesPage(dispatch, getState), url)
}
export const addAnswers = fields => ({type: CMD.ADD_ANSWERS_RESULT, ...fields})
export const addAnswersResult = (url, params) => (dispatch, getState) => {
  //json:{rsm:{result:{answers:{...}, ...}}}
  const handleResult = (dispatch, getState) => json => {
    let result = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm.result)) : {}
    let answers = result.hasOwnProperty('answers') ? result.answers : {}
    let add_answers_count = result.hasOwnProperty("add_answers_count") ? result.add_answers_count : 0
    dispatch(addAnswers({answers, add_answers_count}))
  }

  let {id, sort_key} = params
  let page = getState().question.question.hasOwnProperty('answer_cur_page') ? getState().question.question.answer_cur_page : 1
  sort_key = !tools.isEmpty(sort_key) ? sort_key : 'agree_count'
  url = url + 'id-' + id 
        + ((sort_key === 'agree_count') ? '__sort_key-' + sort_key + '__sort-ASC' : '')
        + ((sort_key === 'add_time') ? '__sort_key-' + sort_key + '__sort-DESC' : '')
        + '__page-' + (++page)
  return tools.httpRequest(handleResult(dispatch, getState), url)
}

/**
 * article actions
 */
export const changeArt = fields => ({type: CMD.CHANGE_ARTICLE_PAGE, ...fields})
export const changeArticlePage = (url, callback=f=>f) => (dispatch, getState) => {
  //json:{"rsm":{"this_user":{},"article":{}}}
  const handleArticlePage = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      let thisUser = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm.this_user)) : {}
      let article = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm.article)) : {}
      dispatch(changeArt({thisUser, article}))
      if (typeof callback === "function") {callback()}
    }
  }
  return tools.httpRequest(handleArticlePage(dispatch, getState), url)
}
export const addComment = fields => ({type: CMD.ADD_COMMENT_RESULT, ...fields})
export const addCommentResult = (url, params) => (dispatch, getState) => {
  //json:{"rsm":{"this_user":{},"article":{}}}
  const handleResult = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      let thisUser = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm.this_user)) : {}
      let article = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm.article)) : {}
      dispatch(addComment({thisUser, article}))
    }
  }
  let {id} = params
  let page = getState().article.article.hasOwnProperty('comment_cur_page') ? getState().article.article.comment_cur_page : 1
  url = url + 'id-' + id + '__page-' + (++page)
  return tools.httpRequest(handleResult(dispatch, getState), url)
}

/**
 * topic actions 
 */
export const changeTopic = fields => ({type: CMD.CHANGE_TOPIC_PAGE, ...fields})
export const changeTopicPage = (url, callback=f=>f) => (dispatch, getState) => {
  //json:{"rsm":{"all_list":[], ...}}
  const handleTopicPage = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      dispatch(changeTopic(json.rsm))
      if (typeof callback === "function") {callback()}
    }
  }
  return tools.httpRequest(handleTopicPage(dispatch,getState), url)
}
export const addAllList = fields => ({type: CMD.ADD_ALL_LIST_RESULT, ...fields})
export const addAllListResult = url => (dispatch, getState) => {
  const handleResult = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      dispatch(addAllList(json.rsm))
    }
  }
  let page = getState().topic.hasOwnProperty('cur_all_list_page') ? getState().topic.cur_all_list_page : 1
  url = url + 'page-' + (++page)
  return tools.httpRequest(handleResult(dispatch, getState), url)
}
export const addBestQues = fields => ({type: CMD.ADD_BEST_QUES_RESULT, ...fields})
export const addBestQuesResult = url => (dispatch, getState) => {
  const handleResult = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      dispatch(addBestQues(json.rsm))
    }
  }
  let page = getState().topic.hasOwnProperty('cur_best_ques_page') ? getState().topic.cur_best_ques_page : 1
  url = url + 'page-' + (++page)
  return tools.httpRequest(handleResult(dispatch, getState), url)
}
export const addAllQues = fields => ({type: CMD.ADD_ALL_QUES_RESULT, ...fields})
export const addAllQuesResult = url => (dispatch, getState) => {
  const handleResult = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      dispatch(addAllQues(json.rsm))
    }
  }
  let page = getState().topic.hasOwnProperty('cur_all_ques_page') ? getState().topic.cur_all_ques_page : 1
  url = url + 'page-' + (++page)
  return tools.httpRequest(handleResult(dispatch, getState), url)
}
export const addArtList = fields => ({type: CMD.ADD_ART_LIST_RESULT, ...fields})
export const addArtListResult = url => (dispatch, getState) => {
  const handleResult = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      dispatch(addArtList(json.rsm))
    }
  }
  let page = getState().topic.hasOwnProperty('cur_art_list_page') ? getState().topic.cur_art_list_page : 1
  url = url + 'page-' + (++page)
  return tools.httpRequest(handleResult(dispatch, getState), url)
}

/**
 * people actions
 */
export const changePeople = fields => ({type: CMD.CHANGE_PEOPLE_PAGE, ...fields})
export const changePeoplePage = (url, callback=f=>f) => (dispatch, getState) => {
  //json:{"rsm":{"user":{}, ...}
  const handlePeoplePage = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      dispatch(changePeople(json.rsm))
      if (typeof callback === "function") {callback()}
    }
  }
  return tools.httpRequest(handlePeoplePage(dispatch, getState), url)
}

export const changeActions = fields => ({type: CMD.CHANGE_USER_ACTIONS, ...fields})
export const changeUserActions =  (url, params, callback=f=>f) => (dispatch, getState) => {
  //json:{"rsm":{"list": ... , ...}
  const handleUserActions = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      let actionsResult = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm)) : {}
      let {actions} = params
      dispatch(changeActions({result: actionsResult, actionsType: actions}))
      if (typeof callback === "function") {callback()}
    }
  }
  let {uid, actions} = params

  if (  getState().people.hasOwnProperty('user_actions_content')
      && getState().people.user_actions_content.hasOwnProperty(actions)) {
    return
  }
  if (actions === 'all') {actions = getState().people.activity_actions}
  url = url + 'uid-' + uid + '__actions-' + actions
  return tools.httpRequest(handleUserActions(dispatch, getState), url)
}

export const addActions = fields => ({type: CMD.ADD_USER_ACTIONS, ...fields})
export const addUserActions = (url, params) => (dispatch, getState) => {
  //json:{"rsm":{"list": ... , ...}
  const handleUserActions = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      let actionsResult = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm)) : {}
      let {actions} = params
      dispatch(addActions({result: actionsResult, actionsType: actions}))
    }
  }
  let {uid, actions} = params
  let page = getState().people.user_actions_content[actions].page
  if (actions === 'all') {actions = getState().people.activity_actions}
  url = url + 'uid-' + uid + '__actions-' + actions + '__page-' + (++page)
  return tools.httpRequest(handleUserActions(dispatch, getState), url)
}

export const changeFollows = fields => ({type: CMD.CHANGE_USER_FOLLOWS, ...fields})
export const changeUserFollows =  (url, params) => (dispatch, getState) => {
  //json:{"rsm":{"users_list": ... , ...}
  const handleUserFollows = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      let followsResult = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm)) : {}
      let {followsType} = params
      dispatch(changeFollows({result: followsResult, followsType: followsType}))
    }
  }
  let {uid, followsType} = params
  /*
  if (  getState().people.hasOwnProperty('user_follows')
      && getState().people.user_follows.hasOwnProperty(followsType)) {
    return
  } */
  url = url + 'uid-' + uid + '__type-' + followsType
  return tools.httpRequest(handleUserFollows(dispatch, getState), url)
}

export const addFollows = fields => ({type: CMD.ADD_USER_FOLLOWS, ...fields})
export const addUserFollows = (url, params) => (dispatch, getState) => {
  //json:{"rsm":{"users_list": ... , ...}
  const handleUserFollows = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      let followsResult = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm)) : {}
      let {followsType} = params
      dispatch(addFollows({result: followsResult, followsType: followsType}))
    }
  }
  let {uid, followsType} = params
  let page = getState().people.user_follows[followsType].page
  url = url + 'uid-' + uid + '__type-' + followsType + '__page-' + (++page)
  return tools.httpRequest(handleUserFollows(dispatch, getState), url)
}

export const changeUserTopics = fields => ({type: CMD.CHANGE_USER_TOPICS, ...fields})
export const changeUserFollowTopics =  (url, params) => (dispatch, getState) => {
  //json:{"rsm":{"topic_list": ... , ...}
  const handleUserTopics = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      let topicsResult = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm)) : {}
      dispatch(changeUserTopics({result: topicsResult}))
    }
  }
  let {uid} = params
  if (getState().people.hasOwnProperty('user_topics')) {
    return
  }
  url = url + 'uid-' + uid
  return tools.httpRequest(handleUserTopics(dispatch, getState), url)
}

export const addUserTopics = fields => ({type: CMD.ADD_USER_TOPICS, ...fields})
export const addUserFollowTopics = (url, params) => (dispatch, getState) => {
  //json:{"rsm":{"topic_list": ... , ...}
  const handleUserTopics = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err);  window.location='/'; return}
    if (tools.isObject(json.rsm) && Object.keys(json.rsm).length > 0) {
      let topicsResult = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm)) : {}
      dispatch(addUserTopics({result: topicsResult}))
    }
  }
  let {uid} = params
  let page = getState().people.user_topics.page
  url = url + 'uid-' + uid + '__page-' + (++page)
  return tools.httpRequest(handleUserTopics(dispatch, getState), url)
}

/**
 * search actions
 */
export const updateStatus = status => ({type: CMD.UPDATE_SEARCH_STATUS, status:{...status}})
export const addSearch = result => ({type: CMD.ADD_SEARCH_RESULT, result:[...result]})
export const changeSearch = search => ({type: CMD.CHANGE_SEARCH_CONTENT, search:{...search}})

export const addSearchResult = url => (dispatch, getState) => {
  //json:{rsm:{result:[{...}, {...}]}}
  const handleResult = (dispatch, getState) => json => {
    let result = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm.result)) : []
    dispatch(addSearch(result))
  }
  let {cur_result_rows, raw_query, page, ...body} = getState().search.status
  body = {page: page + 1, ...body}
  return tools.httpRequest(handleResult(dispatch, getState), url, 'POST', body)
} //todo: store.dispatch(addSearchResult(url))

export const changeSearchContent = (url, params, callback=f=>f) => (dispatch, getState) => {
  let {cur_result_rows, raw_query, ...body} = params
  //json:{rsm:{result:[{...}, {...}]}}
  const handleResult = (dispatch, getState) => json => {
    let status = (params) ? {...params} : {}
    let result = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm.result)) : []
    let search = {status:{...status}, result:[...result]}
    dispatch(changeSearch(search))
    if (typeof callback === "function") {callback()}
  }
  return tools.httpRequest(handleResult(dispatch, getState), url, 'POST', body)
} //todo: store.dispatch(changeSearchContent(url, params, callback))

/**
 * publish actions
 */
export const changeQuestion = fields => ({type: CMD.CHANGE_PUBLISH_QUESTION, ...fields})
export const changeArticle = fields => ({type: CMD.CHANGE_PUBLISH_ARTICLE, ...fields})
export const changePublishContent = (url, type, callback=f=>f) => (dispatch, getState) => {

  //json:{rsm:{question:{...}}}
  const handleQuestion = (dispatch, getState) => json => {
    let content = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm)) : {}
    let question = content.hasOwnProperty('question') ? content.question : {}
    if (Object.keys(question).length > 0) {
      dispatch(changeQuestion(question))
      if (typeof callback === "function") {callback()}
    }else{
      alert((json.err) ? json.err : 'unkown response error!!!')
      window.location='/'
    }
  }

  //json:{rsm:{article:{...}}}
  const handleArticle = (dispatch, getState) => json => {
    let content = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm)) : {}
    let article = content.hasOwnProperty('article') ? content.article : {}
    if (Object.keys(article).length > 0) {
      dispatch(changeArticle(article))
      if (typeof callback === "function") {callback()}
    }else{
      alert((json.err) ? json.err : 'unkown response error!!!')
      window.location='/'
    }
  }

  switch(type) {
    case 'question':
      return tools.httpRequest(handleQuestion(dispatch, getState), url)
    case 'article':
      return tools.httpRequest(handleArticle(dispatch, getState), url)
    default:
      console.log("publish: unkown type error!!!")
  }
} //todo: store.dispatch(changePublishQuestion(url))

/**
 * notifications actions
 */
export const changeNoti = content => ({type: CMD.CHANGE_NOTI_CONTENT, ...content})
export const changeNotiContent = (url, callback=f=>f) => (dispatch, getState) => {
  //json:{"rsm":{...}}
  const handleContent = (dispatch, getState) => json => {
    let content = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm)) : {}
    dispatch(changeNoti(content))
    if (typeof callback === "function") {callback()}
  }
  return tools.httpRequest(handleContent(dispatch, getState), url)
}//todo: store.dispatch(changeNotiContent(url, cb))
export const addNoti = result => ({type: CMD.ADD_NOTI_RESULT, ...result})
export const addNotiResult = (url) => (dispatch, getState) => {
  //json:{"rsm":{...}}
  const handleResult = (dispatch, getState) => json => {
    let result = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm)) : {}
    dispatch(addNoti(result))
  }
  let {page} = getState().notifications
  url = url + 'page-' + (++page)
  return tools.httpRequest(handleResult(dispatch, getState), url)
}//todo: store.dispatch(addNotiResult(url))

/**
 * inbox actions
 */
export const changeInbox = content => ({type: CMD.CHANGE_INBOX_CONTENT, ...content})
export const changeInboxContent = (url, callback=f=>f) => (dispatch, getState) => {
  //json:{"rsm":{...}}
  const handleContent = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err); window.location.reload(); return}
    let content = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm)) : {}
    dispatch(changeInbox(content))
    if (typeof callback === "function") {callback()}
  }
  return tools.httpRequest(handleContent(dispatch, getState), url)
}//todo: store.dispatch(changeInboxContent(url, cb))
export const addInbox = result => ({type: CMD.ADD_INBOX_RESULT, ...result})
export const addInboxResult = (url) => (dispatch, getState) => {
  //json:{"rsm":{...}}
  const handleResult = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err); window.location.reload(); return}
    let result = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm)) : {}
    dispatch(addInbox(result))
  }
  let {page} = getState().inbox
  url = url + 'page-' + (++page)
  return tools.httpRequest(handleResult(dispatch, getState), url)
}//todo: store.dispatch(addInboxResult(url))
export const addDialog = result => ({type: CMD.ADD_DIALOG_RESULT, ...result})
export const addDialogResult = (url, id) => (dispatch, getState) => {
  //json:{"rsm":{...}}
  const handleResult = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err); window.location.reload(); return}
    let result = (json.rsm) ? JSON.parse(JSON.stringify(json.rsm)) : {}
    let resultObj = {}
    resultObj[String(id)] = result
    dispatch(addDialog(resultObj))
  }
  
  if (tools.isNumber(id)) { url = url + id }
  return tools.httpRequest(handleResult(dispatch, getState), url)
}//todo: store.dispatch(addDialogResult(url, id))

/**
 * account actions
 */
export const changeAccount = info => ({type: CMD.CHANGE_ACCOUNT_CONTENT, ...info})
export const changeAccountContent = (url, callback=f=>f) => (dispatch, getState) => {
  //json:{"rsm":{type:''}}
  const handleContent = (dispatch, getState) => json => {
    if (json.errno < 0) {
      alert(json.err)
      if (!tools.isEmpty(json.rsm.url)) {
        window.location = json.rsm.url
      } else {
        window.location = '/'
      }
      return
    }
    if (json.rsm) {
      dispatch(changeAccount(json.rsm))
    }
    if (typeof callback === "function") {callback()}
  }
  return tools.httpRequest(handleContent(dispatch, getState), url)
}//todo: store.dispatch(changeAccountContent(url))

/**
 * account setting
 */
export const changeSetting = info => ({type: CMD.CHANGE_SETTING_CONTENT, ...info})
export const changeSettingContent = (url, callback=f=>f) => (dispatch, getState) => {
  //json:{"rsm":{type:''}}
  const handleContent = (dispatch, getState) => json => {
    if (json.errno < 0) {alert(json.err); window.location = '/'; return;}
    if (json.rsm) {
      dispatch(changeSetting(json.rsm))
    }
    if (typeof callback === "function") {callback()}
  }
  return tools.httpRequest(handleContent(dispatch, getState), url)
}//todo: store.dispatch(changeSettingContent(url))

