import React, {Component} from 'react'
import {Provider} from 'react-redux'
import store from '../model/store'
import {ColumnTemplate} from './Columns'
import {globalPlugins} from '../controller/plugins'

const Main404 = props => 
  <div class="w1150 Main_area">
    <div class="content style404">
        <img src={require("../../img/cw404.png")} alt="404"/>
    </div>
  </div>

class Whoops404 extends Component {

  componentDidMount() {
    globalPlugins(store.getState().ginfo) //the third party plug-ins
  }

  render() {
    return (
    <Provider store = {store}>
      <ColumnTemplate column="whoops404">
      <Main404 />
      </ColumnTemplate>
    </Provider>
    )
  }
}

export default Whoops404