/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import * as tools from '../controller/tools'

const TopicBox = ({topic}) => {
  let topicInfo = topic.hasOwnProperty('topic_info') ? topic.topic_info : {}
  let thisUser = topic.hasOwnProperty('this_user') ? topic.this_user : {}
  let about = e => {window.$('#tab-about').click()}
  let follow = e => {window.AWS.User.follow(window.$('#topic-box-follow'), 'topic', topicInfo.topic_id);}
  return (
  <div className="personal_top">
    <div className="w1150">
      <dl>
        <dt><a href="javascript:void(0);"><img src={topicInfo.topic_pic} alt={topicInfo.topic_title}/></a></dt>
        <dd>
          <h1>{topicInfo.topic_title}</h1>
          {
            tools.htmlToText(tools.htmlUnescape(topicInfo.topic_description)).length < 130
            ?
            <p>{tools.htmlToText(tools.htmlUnescape(topicInfo.topic_description))}</p>
            :
            <p>{tools.htmlToText(tools.htmlUnescape(topicInfo.topic_description)).slice(0, 130) + '...'}
            <a href="javascript:void(0);" onClick={about} >查看全部</a></p>
          }
        </dd>
        {
          (thisUser.user_id > 0) ?
          <dd className="but">
            <a href="javascript:void(0);" id = "topic-box-follow" onClick={follow} className="gz_but follow btn btn-normal btn-success">
              <span>{topicInfo.has_focus ? '取消关注' : '关注'}</span> <em>|</em> <b>{topicInfo.focus_count}</b>
            </a>
          </dd> : ''
        }
        <div className="clear"></div>
      </dl>
    </div>
  </div>
  )
}

export default TopicBox
