/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import * as tools from '../controller/tools'
import LoadMore from './LoadMore'

const MiddleAuthors = ({peopleSquare, peopleMore}) => {
  let usersList = peopleSquare.hasOwnProperty('users_list') ? (peopleSquare.users_list ? peopleSquare.users_list : []) : []
  let curPeopleCount = peopleSquare.hasOwnProperty('cur_people_count') ? peopleSquare.cur_people_count : usersList.length
  return (
  <div>
    <div className="Popular_author">
    {
      ([...usersList].length === 0) ? '' :
      [...usersList].map(u => {
        return (
        <dl>
          <dt><a className="aw-user-name" href={"/people/"+ u.url_token} data-id={u.uid}><img src={u.avatar_file} alt=""/></a></dt>
          <dd>
            <a className="aw-user-name" href={"/people/" + u.url_token} data-id={u.uid}>{u.user_name}</a>
            <h5>{tools.isEmpty(u.introduction) ? '暂无介绍' : u.introduction}</h5>
            <p><i>{u.views_count}</i>次浏览<em></em><i>{u.article_count}</i>篇文章</p>
          </dd>
          <div className="clear"></div>
        </dl>
        )
      })
    }
    </div>

    <div className="mbw auto-square">
      <div className="load-more btn">
        <div className="load_more_div">
          <div className="load_more"><LoadMore perPage={'12'} curRows={curPeopleCount} loadEvent={peopleMore}/></div>
        </div>
      </div>
    </div>

  </div>
  )
}


const MiddleArea = ({ginfo, peopleSquare, peopleMore}) =>
  <div class="Middle_area " style={{marginLeft:'42px'}}>
    <MiddleAuthors ginfo={ginfo} peopleSquare={peopleSquare} peopleMore={peopleMore}/>
  </div>

const RightArea = ({peopleSquare}) => {
  let hotUsers = (peopleSquare && tools.isObject(peopleSquare) && Object.keys(peopleSquare).length > 0) ? 
                  (peopleSquare.hasOwnProperty('sidebar_hot_users') ? (peopleSquare.sidebar_hot_users ? peopleSquare.sidebar_hot_users : []) : []) : []
  return (
    <div className="right_area " style={{marginRight:'180px'}}>   
      {
        <div className="right_Notice tj_author">
        <h4>推荐作者</h4>
        {
          [...hotUsers].map(u => {
          return (
            <dl>
              <dt><a className="aw-user-name" href={"/people/" + u.url_token} data-id={u.uid}><img src={u.avatar_file} alt=""/></a></dt>
              <dd><a className="aw-user-name" href={"/people/"+ u.url_token} data-id={u.uid}>{u.user_name}</a><p><i>{u.views_count}</i>次浏览，<i>{u.article_count}</i>篇文章</p></dd>
              <div className="clear"></div>
            </dl>)
          })
        }
        </div>
      }
    </div>
  )
}

class PeopleMain extends Component {
  render() {
    let {ginfo, peopleSquare, more} = this.props
    return (
    <div className="w1150 Main_area">
      <MiddleArea ginfo={ginfo} peopleSquare={peopleSquare} getSquare={this.getSquare} peopleMore={more}/>
      <RightArea  peopleSquare={peopleSquare}/>
      <div className="clear"></div>
    </div>)
  }
}

export default PeopleMain
