/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {BASE, URL} from '../controller/constants'
import * as tools from '../controller/tools'

const IndexLogo = ({href}) =>
  <div className="index_logo">
    <a href={href}><img src={require("../../img/logo.png").default} alt="logo"/></a>
  </div>

const NavBox = ({column}) => {
  const columns = [{'column':'home', 'to':'/', 'title':'动态'}, {'column':'question', 'to':'/question', 'title':'问答'},
                   {'column':'article', 'to':'/article', 'title':'文章'}, {'column':'topic', 'to':'/topic', 'title':'话题'}]
  const linkCol = (c, col) => {
    return (<li className={(c === col.column) ? 'slected' : ''}><Link to={col.to}>{col.title}</Link></li>)
  }
  return (<div className="nav_box"><ul>{columns.map(col => linkCol(column, col))}</ul></div>)
}

const TopSearch = props => {
  let _input
  const handle = json => {
    if (json.rsm && json.rsm.query) {
      window.location = BASE + "/search/" + json.rsm.query
    }
  }
  const submit = e => {
    e.preventDefault()
    let query = tools.trim(_input.value)
    if (!tools.isEmpty(query)) {
      let body = {q:query}
      tools.httpRequest(handle, URL.SEARCH_API, 'POST', body)
    }
  }
  return (
    <div className="top_search">
      <form className="navbar-search" id="global_search_form" onSubmit={submit}>
          <input className="form-control search-query" type="text" ref={input=>_input=input} placeholder="搜索问题、话题、人" autocomplete="off" name="q" id="aw-search-query" /><button></button>
          <div className="search_result aw-dropdown">
              <p className="title" style={{marginTop:"0px", borderTop:"none"}} >输入关键字进行搜索</p>
              <div className="aw-dropdown-list" style={{maxHeight:'202px',overflowY:'auto'}}></div>
              <p className="search">搜索 <a onClick={submit} href="javascript:void(0);">""</a></p>
          </div>
      </form>
    </div>
  )
}

const Launch = props =>
  <div className="auto-wtwz">
    <Link to="/publish/question/"><img src={require("../../img/wd2.png").default} alt="question"/>提问题</Link>
    <Link to="/publish/article/"><img src={require("../../img/zl2.png").default} alt="article"/>写文章</Link>
  </div>

const LoginedUser = ({ginfo}) => {
  let thisUser = ginfo.hasOwnProperty('G_THIS_USER') ? ginfo.G_THIS_USER : {}
  let thisUserInfo = thisUser.hasOwnProperty('user_info') ? thisUser.user_info : {}
  let notice = e => {window.notifications();}
  let letter = e => {window.user_inboxs();}
  return (
  <div className="help">
    <ul>
      <li className="t_personal ">
        <span><a href={'/people/' + thisUserInfo.url_token}><img alt={thisUserInfo.user_name} src={thisUserInfo.avatar_file}/></a></span>
        <div className="per_but" style={{display: 'none'}}>
          <a href="/setting/"><img src={require("../../img/set.png")} alt=""/>设置</a>
          {
            (thisUserInfo['permission']['is_administortar'] || thisUserInfo['permission']['is_moderator']) ?
            <a href="/?/admin/"><img src={require("../../img/admin.png")} alt=""/>管理</a> : ''
          }
          <a href={"/account/logout/" + encodeURIComponent(window.location.href)}><img src={require("../../img/sign_out.png")} alt=""/>退出</a>
        </div>
      </li>
      <li className="t_message">
        <a href="javascript:void(0);" onClick={letter}><span><em id="inbox_unread" style={{display:'none'}}></em></span></a>       
      </li>
      <li className="t_notice">
        <a href="javascript:void(0);" onClick={notice} ><span><em id="notifications_unread" style={{display:'none'}}></em></span></a>
        <div className="Private_letter focus_user" style={{display: 'none'}} id="header_notification_list">
          <div className="l_more"><button onClick={notice}>查看全部通知</button></div>
        </div>
      </li>
    </ul>
  </div>
  )
}

const LoginRegister = props =>
  <div className="auto-zcdl">
    <Link className="zc" to={"/account/register/" + encodeURIComponent(window.location.href)}>注册</Link>
    <Link className="dl" to={"/account/login/" + encodeURIComponent(window.location.href)}>登录</Link>
  </div>

class TopNav extends Component {
  shouldComponentUpdate(){
    return false
  }
  render() {
    const {ginfo, column} = this.props
    return (
    <div className="top_nav">
      <div className="w1150 matser">
        <IndexLogo href="/"/>
        <NavBox column={column}/>
        <TopSearch/>
        <Launch />
        {(ginfo.G_USER_ID) ? <LoginedUser ginfo={ginfo}/> : <LoginRegister />}
      </div>
      <div className="w1150 check" style={{display:"none"}}></div>
    </div>
    )
  }
}

export default TopNav;
