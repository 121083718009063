/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Link} from 'react-router-dom'
import * as tools from '../controller/tools'
import LoadMore from './LoadMore'

const LeftNav = ({id, art}) => {
  let artInfo = art.hasOwnProperty('article_info') ? art.article_info : {}
  let artTitle = artInfo.hasOwnProperty('title') ? artInfo.title : ''
  return (
    <div className="left_nav">
      <dl className="nav1"><dt><Link to="/">动态</Link></dt></dl>
      <dl className="nav2"><dt><Link to="/question">问答</Link></dt></dl>
      <dl className="nav3 cur con">
        <dt><Link to={"/article/" + id}>文章</Link>
        <span>{artTitle.length <= 8 ? artTitle : artTitle.slice(0, 8) + '...'}</span>
      </dt></dl>
      <dl className="nav4"><dt><Link to="/topic">话题</Link></dt></dl>
    </div>
  )
}

const MiddleArea = ({ginfo, thisUser, art, more}) => {
  let artInfo = art.hasOwnProperty('article_info') ? art.article_info : {}
  let artTopics = art.hasOwnProperty('article_topics') ? art.article_topics : []
  let thisUserInfo = thisUser.hasOwnProperty('user_info') ? thisUser.user_info : {}
  let artComments = art.hasOwnProperty('comments') ? art.comments : []
  let curCommentCount = art.hasOwnProperty('cur_comment_count') ? art.cur_comment_count : [...artComments].length
  const lockArt = e => {window.AWS.ajax_request(ginfo.G_BASE_URL + '/article/ajax/lock/', 'article_id='+artInfo.id)}
  const delArt = e => {
    window.AWS.dialog('confirm', {'message' : '确认删除?'}, function(){
      window.AWS.ajax_request(ginfo.G_BASE_URL + '/api/article/remove_article/', 'article_id='+artInfo.id);})
  }
  const agreeVote = id => e => {window.AWS.User.article_vote_Step_on('#agree_vote', id, 1)}
  const disagreeVote = id  => e => {window.AWS.User.article_vote_Step_up('#disagree_vote', id, -1)}
  const favorite = id => e => {window.AWS.dialog('favoriteTag', {item_id: id, item_type:'article'});}
  const artShare = id => e => {window.article_share('#article_share', id)}
  const commentVote = (id, index) => e => {window.AWS.User.article_comment_vote_new(window.$('#comment_vote'+index+' i'), id, 1)}
  const articleComment = index => e => {window.AWS.Init.init_article_comment_box_new(window.$('#article_comment'+index))}
  const delComment = id => e => {
    window.AWS.dialog('confirm', {'message' : '确认删除?'}, function(){
      window.AWS.ajax_request(ginfo.G_BASE_URL + '/api/article/remove_comment/', 'comment_id='+id);
    });
  }
  const commentSubmit = e => {window.AWS.ajax_post(window.$('#answer_form'), window.AWS.ajax_processer, 'reply')}
  const captchaClick = e => {window.$('#captcha').attr("src", ginfo.G_BASE_URL + '/account/captcha/' + Math.floor(Math.random() * 10000))}
  return (
    <div className="Middle_area ">
    <div className="details_box wz_details">
      <div className="wz_title">
        <h2>{artInfo.title}</h2>
        {
          !(thisUserInfo.permission.is_administortar || thisUserInfo.permission.is_moderator) ? '' :
          <div className="auto-wz-operate">
            {
              ((!artInfo.lock) && ((artInfo.uid === thisUser.user_id) || thisUserInfo.permission.edit_article))
              ? <label className="edit"><a href={"/publish/article/" + artInfo.id} onClick="">编辑</a></label> : ''
            }
            <label className="del"><a href="javascript:void(0);" onClick={delArt}>删除</a></label>
            <label className="lock"><a href="javascript:void(0);" onClick={lockArt}>{artInfo.lock ? '解锁' : '锁定'}</a></label>
          </div>
        }
      </div>
      <div className="problem_bq topic_label aw-topic-bar" data-type="article" data-id={artInfo.id} data-img={require("../../img/close2.png")}>
      {
        [...artTopics].map(t => <a className="topic-tag text" data-id={t.topic_id} href={"/topic/" + t.url_token}>{t.topic_title}</a>)
      }
      {
        !(thisUserInfo.permission.is_administortar || thisUserInfo.permission.is_moderator) ? '' :
        <span className="aw-edit-topic_article edit">{[...artTopics].length === 0 ? '添加话题' : '编辑话题'}</span>
      }
      </div>
      <div className="Paran_box" style={{wordBreak:'break-all'}} id="question_topic_editor" data-type="article" data-id={artInfo.id} 
      dangerouslySetInnerHTML={{__html: tools.htmlUnescape(artInfo.message)}}></div>
      <div className="hdbox">
        <div className="foot_bq">
          <div className="zkzd">
            <span className="agree " id="agree_vote" onClick={agreeVote(artInfo.id)}><em className="up"></em>{artInfo.votes}</span>
            <span className="disagree " id="disagree_vote" onClick={disagreeVote(artInfo.id)}><em className="down"></em></span>
          </div>
          <label className="comment"><i>{art.comments_count}</i>人评论</label>
          <label className="browse"><i>{artInfo.views}</i>次浏览</label>
          <label className="sc" onClick={favorite(artInfo.id)}><i></i>收藏</label>
          <input type="hidden" id="share_content" value=""/>
          <input type="hidden" id="share_url" value=""/>
          <input type="hidden" id="share_title" value=""/>
          <label className="fx" id="article_share" onClick={artShare(artInfo.id)} style={{cursor:'pointer'}}>分享
            <div className="fx_list bdsharebuttonbox" style={{display:'none'}}>
              <ul>
                <li className="qq"><a style={{fontSize:'12px', lineHeight:'17px'}} href="#" className="bds_qzone" data-cmd="qzone" title="分享到QQ空间">分享至空间</a></li>
                <li className="wb"><a style={{fontSize:'12px', lineHeight:'17px'}} href="#" className="bds_tsina" data-cmd="tsina" title="分享到新浪微博">分享至微博</a></li>
                <li className="wx"><a style={{fontSize:'12px', lineHeight:'17px'}} href="#" className="bds_weixin" data-cmd="weixin" title="分享到微信">分享到微信</a></li>
              </ul>
            </div>
          </label>
          <label className="fr">{artInfo.add_time}</label>
        </div>
      </div>

      {
        ([...artComments].length === 0) ? '' :
        <div className="comment_box">
            <ul>
              {
                [...artComments].map((c,i) => {
                  return (
                  <li className="aw-item">
                    <div className="pl_top">
                      <a href={"/people/" + c.user_info.url_token}><img src={c.user_info.avatar_file} alt={c.user_info.user_name} />{c.user_info.user_name}</a>
                      {/* 
                      {tools.isEmpty(c.at_user_info) ? '' : '回复'}
                      {tools.isEmpty(c.at_user_info) ? '' : <a href={"/people/"+ c.at_user_info.url_token}>{c.at_user_info.user_name}</a>}
                      */}
                      <label className="fr">{c.add_time}</label>
                      <div className="clear"></div>
                    </div>
                    <div className="text_bottom" dangerouslySetInnerHTML={{__html: tools.htmlUnescape(c.message)}}></div>
                    <div className="hdbox">
                      <label id={'comment_vote' + i} onClick={commentVote(c.id, i)}><img src={require("../../img/h_zan.png")} alt=""/><i>{c.votes} 赞</i></label>
                      <label id={'article_comment' + i} onClick={articleComment(i)} className="aw-article-comment" data-id={c.user_info.uid} data-user={c.user_info.user_name} data-mess={tools.htmlUnescape(c.message)}>
                        <img src={require("../../img/comment.png")} alt=""/><i>回复</i>
                      </label>
                      {
                        !(thisUserInfo.permission.is_administortar || thisUserInfo.permission.is_moderator) ? '' :
                        <label className="del"><a href="javascript:void(0);" onClick={delComment(c.id)}>删除</a></label>
                      }
                    </div>
                  </li>
                  )
                })
              }
            </ul>
            <div className="load_more_li">
                <div className="load_more"><LoadMore perPage={ginfo.G_PER_PAGE} curRows={curCommentCount} loadEvent={more}/></div>
            </div>
        </div>
      }

      {
        artInfo.lock ?
        <div>
          <p align="center" style={{marginTop:'10px'}}>该文章目前已经被锁定, 无法添加新评论!</p>
        </div>
        :
        (!thisUser.user_id ? 
        <div>
          <p align="center" style={{marginTop:'10px'}}>要回复文章请先<a href="/account/login/" style={{color:'#6095F2'}}>登录</a>或<a href="/account/register/" style={{color:'#6095F2'}}>注册</a></p>
        </div>
        :
        <div>
          <form action={ginfo.G_BASE_URL + "/article/ajax/save_comment/"} onsubmit="return false;" method="post" id="answer_form">
            <input type="hidden" name="post_hash" value={ginfo.G_POST_HASH}/>
            <input type="hidden" name="article_id" value={artInfo.id} />
            <div className="Text_domain"><textarea rows="3" name="message" id="comment_editor" className="form-control autosize" placeholder="写下你的评论..."/></div>
            <div className="Put_questions">
              {
                !art.human_valid ? '' :
                <div className="auto-captcha">
                  <em className="auth-img pull-right"><img src="" onClick={captchaClick} id="captcha" alt=""/></em>
                  <input className="pull-right form-control" type="text" name="seccode_verify" placeholder="验证码"/>
                </div>
              }
              <a href="javascript:void(0);" onClick={commentSubmit} className="btn btn-normal btn-success pull-right btn-submit btn-reply" >评论</a>
            </div>
          </form>
        </div>
        )
      }           
      <div className="clear"></div>
    </div>
  </div>
  )
}

const Initiator = ({thisUser, art}) => {
  let artInfo = art.hasOwnProperty('article_info') ? art.article_info : {}
  let artUserInfo = artInfo.hasOwnProperty('user_info') ? artInfo.user_info : {}
  const initiatorFollow = e => {
    window.follow(window.$('#initiator_follow'), 'user', artUserInfo.uid)
  } 
  return (
    <div className="personal Initiator wz_fqr">
      <h4>发起人</h4>
      <dl>
        <dt><a href={'/people/' + artUserInfo.url_token}><img alt={artUserInfo.user_name} src={artUserInfo.avatar_file}/></a></dt>
        <dd>
            <a className="aw-user-name" href={"/people/"+ artUserInfo.url_token} data-id={artUserInfo.uid}>{artUserInfo.user_name}</a>
            <p style={{fontSize:'12px',color:'#666'}}>{!tools.isEmpty(artUserInfo.introduction) ? artUserInfo.introduction : '暂无介绍'}</p>
        </dd>
        <div className="clear"></div>
      </dl>
      <ul>
        <li>
          <span>赞同</span>
          <label>{artUserInfo.agree_count}</label>
        </li>
        <li>
          <span>威望</span>
          <label>{artUserInfo.reputation}</label>
        </li>
        <div className="clear"></div>
      </ul>
      <div className="Invitation_but">
        {
          ((thisUser.user_id) && (thisUser.user_id !== artUserInfo.uid))
          ? <button id="initiator_follow" onClick={initiatorFollow}>{art.user_follow_check?'取消关注':'+关注'}</button> : <label>+关注</label>
        }
      </div>
    </div>
  )
}

const RelateQues = ({art}) => {
  let quesRelatedList = art.question_related_list
  let quesItem = q => {
    return <li><a href={'/question/' + q.question_id}>{q.question_content}</a></li>
  }
  return (
    !art.question_related_list ? '' :
    <div className="guide">
      <h4>相关问题</h4>
      <ul>{[...Object.values(quesRelatedList)].map(q => quesItem(q))}</ul>
    </div>
  )
}

const RightArea = ({thisUser, art}) =>
  <div className="right_area">
    <Initiator thisUser={thisUser} art={art}/>
    <RelateQues art={art}/>
  </div>

const ArticlePageMain = props => {
  let {ginfo, params, thisUser, article, more} = props
  let {id} = params
  return (
  <div className="w1150 Main_area">
    <LeftNav id={id} art={article}/>
    <MiddleArea ginfo={ginfo} thisUser={thisUser} art={article} more={more}/>
    <RightArea thisUser={thisUser} art={article}/>
    <div className="clear"></div>
  </div>)
}
export default ArticlePageMain
