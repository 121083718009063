import React, { Component } from 'react'
import {BrowserRouter as Router, withRouter, Route, Switch, Redirect} from 'react-router-dom'
import store from '../model/store'
import initer from '../model/initer'
import Whoops404 from './Whoops'
import {URL} from '../controller/constants'
import {getGlobalInfo} from '../controller/actions'
import {
  HomeColumn, QuesColumn, ArticlesColumn, TopicsColumn, PeopleColumn, 
  NotiColumn, InboxColumn, SearchColumn, PublishColumn, AccountColumn, AccountSetting} from './Columns'
import {QuesPage, ArticlePage, TopicPage, PeoplePage} from './Columns'

const Loading = props => 
  <div className="auto-center">
    <img src={require("../../img/loading_a.gif")} alt=""/>
  </div>

const LoginRouter = props => <Redirect to="/account/login"/>

const AppWrapper = () => {
  let ginfo = store.getState().ginfo
  let hasLogined = (ginfo.G_USER_ID > 0) ? true : false
  return (
  <div className="app">
    <Switch>
    <Route exact path="/" component={withRouter(HomeColumn)}/>
    <Route exact path="/question" component={withRouter(QuesColumn)}/>
    <Route path="/question/:id/:sort_key?/:answer_id?" component={withRouter(QuesPage)}/>
    <Route exact path="/article" component={withRouter(ArticlesColumn)}/>
    <Route path="/article/:id" component={withRouter(ArticlePage)}/>
    <Route exact path="/topic" component={withRouter(TopicsColumn)}/>
    <Route path="/topic/:id" component={withRouter(TopicPage)}/>
    <Route exact path="/people" component={hasLogined ? withRouter(PeopleColumn) : LoginRouter}/>
    <Route path="/people/:id" component={hasLogined ? withRouter(PeoplePage) : LoginRouter}/>
    <Route exact path="/inbox" component={hasLogined ? withRouter(InboxColumn) : LoginRouter}/>
    <Route path="/notifications" component={hasLogined ? withRouter(NotiColumn) : LoginRouter}/>
    <Route path="/publish/:type/:id?" component={hasLogined ? withRouter(PublishColumn) : LoginRouter}/>
    <Route path="/search/:query/:type?/:page?/:is_recommend?" component={withRouter(SearchColumn)}/>
    <Route path="/setting/:action?" component={hasLogined ? withRouter(AccountSetting) : LoginRouter}/>
    <Route path="/account/:route/:url?" component={withRouter(AccountColumn)}/>
    <Route component={Whoops404} />
    </Switch>
  </div>
  )
}

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {loading: true}
    this.exec = this.exec.bind(this)
  }

  exec() {
    this.setState({loading: false})
  }

  componentWillMount() {
    initer(store)
    store.dispatch(getGlobalInfo(URL.GINFO_API, this.exec))
  }

  componentDidMount() {
    //globalPlugins() //the third party plug-ins
  }

  render() {
    return (this.state.loading) ? <Loading/> : <Router><AppWrapper/></Router>
  }
}

export default App;
