import {CMD} from "./constants"
import * as tools from './tools'

//ginfo
export const ginfo = (state={}, action) => {
  let {type, ...info} = action
  switch(action.type) {
    case CMD.ADD_GLOBAL_INFO:
      return {...info}
    default:
      return state
  }
}

//square
export const square = (state={}, action) => {
  let {type, ...rest} = action
  let updateHomeSquare = state => {
    let homeSqu = state.hasOwnProperty('home') ? state.home : {}
    let homeListToAdd = rest.hasOwnProperty('posts_list') ? (tools.isArray(rest.posts_list) ? rest.posts_list : []) : []
    if (homeSqu.hasOwnProperty('posts_list') && tools.isArray(homeSqu.posts_list)) {
      for (let itemToAdd of homeListToAdd) {
        if (itemToAdd.hasOwnProperty('question_id')) {
          if (homeSqu.posts_list.every(post => (post.question_id !== itemToAdd.question_id))) {
            homeSqu.posts_list.push(itemToAdd)
          }
        }
        if (itemToAdd.hasOwnProperty('id')) {
          if (homeSqu.posts_list.every(post => (post.id !== itemToAdd.id))) {
            homeSqu.posts_list.push(itemToAdd)
          }
        }
      }
    }
    homeSqu['cur_home_page'] = homeSqu.hasOwnProperty('cur_home_page') ? (homeSqu.cur_home_page + 1) : 1
    homeSqu['cur_home_count'] = rest.hasOwnProperty('posts_list') ? (tools.isArray(rest.posts_list) ? rest.posts_list.length : 0) : 0
    return homeSqu
  }
  let updateQuesSquare = state => {
    let quesSqu = state.hasOwnProperty('question') ? state.question : {}
    let quesListToAdd = rest.hasOwnProperty('posts_list') ? (tools.isArray(rest.posts_list) ? rest.posts_list : []) : []
    if (quesSqu.hasOwnProperty('posts_list') && tools.isArray(quesSqu.posts_list)) {
      quesSqu.posts_list = [...quesSqu.posts_list, ...quesListToAdd]
    }
    quesSqu['cur_question_page'] = quesSqu.hasOwnProperty('cur_question_page') ? (quesSqu.cur_question_page + 1) : 1
    quesSqu['cur_question_count'] = rest.hasOwnProperty('posts_list') ? (tools.isArray(rest.posts_list) ? rest.posts_list.length : 0) : 0
    return quesSqu
  }
  let updateArtSquare = state => {
    let artSqu = state.hasOwnProperty('article') ? state.article : {}
    let artListToAdd = rest.hasOwnProperty('article_list') ? (tools.isArray(rest.article_list) ? rest.article_list : []) : []
    let artTopicsToAdd = rest.hasOwnProperty('article_topics') ? (tools.isObject(rest.article_topics) ? rest.article_topics : {}) : {}
    if (artSqu.hasOwnProperty('article_list') && tools.isArray(artSqu.article_list)) {
      artSqu.article_list = [...artSqu.article_list, ...artListToAdd]
    }
    if (artSqu.hasOwnProperty('article_topics') && tools.isObject(artSqu.article_topics)) {
      artSqu.article_topics = {...artSqu.article_topics, ...artTopicsToAdd}
    }
    artSqu['cur_article_page'] = artSqu.hasOwnProperty('cur_article_page') ? (artSqu.cur_article_page + 1) : 1
    artSqu['cur_article_count'] = rest.hasOwnProperty('article_list') ? (tools.isArray(rest.article_list) ? rest.article_list.length : 0) : 0
    return artSqu
  }
  let updateTopicSquare = state => {
    let topicSqu = state.hasOwnProperty('topic') ? state.topic : {}
    let topicsListToAdd = rest.hasOwnProperty('topics_list') ? (tools.isArray(rest.topics_list) ? rest.topics_list : []) : []
    if (topicSqu.hasOwnProperty('topics_list') && tools.isArray(topicSqu.topics_list)) {
      topicSqu.topics_list = [...topicSqu.topics_list, ...topicsListToAdd]
    }
    topicSqu['cur_topic_page'] = topicSqu.hasOwnProperty('cur_topic_page') ? (topicSqu.cur_topic_page + 1) : 1
    topicSqu['cur_topic_count'] = rest.hasOwnProperty('topics_list') ? (tools.isArray(rest.topics_list) ? rest.topics_list.length : 0) : 0
    return topicSqu
  }
  let updatePeopleSquare = state => {
    let peopleSqu = state.hasOwnProperty('people') ? state.people : {}
    let usersListToAdd = rest.hasOwnProperty('users_list') ? (tools.isArray(rest.users_list) ? rest.users_list : []) : []
    if (peopleSqu.hasOwnProperty('users_list') && tools.isArray(peopleSqu.users_list)) {
      peopleSqu.users_list = [...peopleSqu.users_list, ...usersListToAdd]
    }
    peopleSqu['cur_people_page'] = peopleSqu.hasOwnProperty('cur_people_page') ? (peopleSqu.cur_people_page + 1) : 1
    peopleSqu['cur_people_count'] = rest.hasOwnProperty('users_list') ? (tools.isArray(rest.users_list) ? rest.users_list.length : 0) : 0
    return peopleSqu
  }
  switch(type) {
    case CMD.BUILD_HOME_SQUARE:
      return {...state, home:{...rest, cur_home_page: 1}}
    case CMD.ADD_HOME_RESULT:
      return {...state, home:{...updateHomeSquare(state)}}
    case CMD.BUILD_QUES_SQUARE:
      return {...state, question:{...rest, cur_question_page: 1}}
    case CMD.ADD_QUES_RESULT:
      return {...state, question:{...updateQuesSquare(state)}}
    case CMD.BUILD_ART_SQUARE:
      return {...state, article: {...rest, cur_article_page: 1}}
    case CMD.ADD_ART_RESULT:
      return {...state, article: {...updateArtSquare(state)}}
    case CMD.BUILD_TOPIC_SQUARE:
      return {...state, topic:{...rest, cur_topic_page: 1}}
    case CMD.ADD_TOPIC_RESULT:
      return {...state, topic:{...updateTopicSquare(state)}}
    case CMD.BUILD_PEOPLE_SQUARE:
      return {...state, people: {...rest, cur_people_page: 1}}
    case CMD.ADD_PEOPLE_RESULT:
      return {...state, people: {...updatePeopleSquare(state)}}
    default:
      return state
  }
}

//question
export const question = (state = {}, action) => {
  let {type, ...rest} = action
  let updateQues = state => {
    let ques = state.hasOwnProperty('question') ? state.question : {}
    let answersToAdd = rest.hasOwnProperty('answers') ? rest.answers : {}
    if (ques.hasOwnProperty('answers') && tools.isObject(ques.answers)) {
      Object.values(answersToAdd).forEach(value => {
        ques.answers[String([...Object.keys(ques.answers)].length + 1)] = value
      })
    }
    ques['answer_cur_page'] = ques.hasOwnProperty('answer_cur_page') ? (ques.answer_cur_page + 1) : 1
    ques['add_answers_count'] = rest.hasOwnProperty('add_answers_count') ? rest.add_answers_count : 0
    return ques
  }
  switch(type) {
    case CMD.CHANGE_QUES_PAGE:
      let thisUser = rest.hasOwnProperty('thisUser') ? rest.thisUser : {}
      let question = rest.hasOwnProperty('question') ? rest.question : {}
      return {thisUser: {...thisUser}, question:{...question, answer_cur_page: 1}}
    case CMD.ADD_ANSWERS_RESULT:
      return {thisUser: {...state.thisUser}, question:{...updateQues(state)}}
    default:
      return state
  }
}

//article
export const article = (state = {}, action) => {
  let {type, ...rest} = action
  let updateArticle = state => {
    let article = state.hasOwnProperty('article') ? state.article : {}
    let art = rest.hasOwnProperty('article') ? rest.article : {}
    let commentsToAdd = art.hasOwnProperty('comments') ? art.comments : []
    if (article.hasOwnProperty('comments') && tools.isArray(article.comments)) {
      article.comments = [...article.comments, ...commentsToAdd]
    }
    article['comment_cur_page'] = article.hasOwnProperty('comment_cur_page') ? (article.comment_cur_page + 1) : 1
    article['cur_comment_count'] = [...commentsToAdd].length
    return article
  }
  switch(type) {
    case CMD.CHANGE_ARTICLE_PAGE:
      let thisUser = rest.hasOwnProperty('thisUser') ? rest.thisUser : {}
      let article = rest.hasOwnProperty('article') ? rest.article : {}
      return {thisUser: {...thisUser}, article:{...article, comment_cur_page: 1}}
    case CMD.ADD_COMMENT_RESULT:
      return {thisUser: {...rest.thisUser}, article:{...updateArticle(state)}}
    default:
      return state
  }
}

//topic
export const topic = (state = {}, action) => {
  let {type, ...rest} = action
  let updateAllList = state => {
    let topicState = {...state}
    let allListToAdd = rest.hasOwnProperty('all_list') ? (tools.isArray(rest.all_list) ? rest.all_list : []) : []
    if (topicState.hasOwnProperty('all_list') && tools.isArray(topicState.all_list)) {
      topicState.all_list = [...topicState.all_list, ...allListToAdd]
    }
    topicState['cur_all_list_page'] = topicState.hasOwnProperty('cur_all_list_page') ? (topicState.cur_all_list_page + 1) : 1
    topicState['cur_all_list_count'] = rest.hasOwnProperty('all_list') ? (tools.isArray(rest.all_list) ? rest.all_list.length : 0) : 0
    return topicState
  }
  let updateBestQues = state => {
    let topicState = {...state}
    let bestQuesToAdd = rest.hasOwnProperty('best_questions_list') ? (tools.isArray(rest.best_questions_list) ? rest.best_questions_list : []) : []
    if (topicState.hasOwnProperty('best_questions_list') && tools.isArray(topicState.best_questions_list)) {
      topicState.best_questions_list = [...topicState.best_questions_list, ...bestQuesToAdd]
    }
    topicState['cur_best_ques_page'] = topicState.hasOwnProperty('cur_best_ques_page') ? (topicState.cur_best_ques_page + 1) : 1
    topicState['cur_best_ques_count'] = rest.hasOwnProperty('best_questions_list') ? (tools.isArray(rest.best_questions_list) ? rest.best_questions_list.length : 0) : 0
    return topicState
  }
  let updateAllQues = state => {
    let topicState = {...state}
    let allQuesToAdd = rest.hasOwnProperty('all_questions_list') ? (tools.isArray(rest.all_questions_list) ? rest.all_questions_list : []) : []
    if (topicState.hasOwnProperty('all_questions_list') && tools.isArray(topicState.all_questions_list)) {
      topicState.all_questions_list = [...topicState.all_questions_list, ...allQuesToAdd]
    }
    topicState['cur_all_ques_page'] = topicState.hasOwnProperty('cur_all_ques_page') ? (topicState.cur_all_ques_page + 1) : 1
    topicState['cur_all_ques_count'] = rest.hasOwnProperty('all_questions_list') ? (tools.isArray(rest.all_questions_list) ? rest.all_questions_list.length : 0) : 0
    return topicState
  }
  let updateArtList = state => {
    let topicState = {...state}
    let artListToAdd = rest.hasOwnProperty('articles_list') ? (tools.isArray(rest.articles_list) ? rest.articles_list : []) : []
    if (topicState.hasOwnProperty('articles_list') && tools.isArray(topicState.articles_list)) {
      topicState.articles_list = [...topicState.articles_list, ...artListToAdd]
    }
    topicState['cur_art_list_page'] = topicState.hasOwnProperty('cur_art_list_page') ? (topicState.cur_art_list_page + 1) : 1
    topicState['cur_art_list_count'] = rest.hasOwnProperty('articles_list') ? (tools.isArray(rest.articles_list) ? rest.articles_list.length : 0) : 0
    return topicState
  }
  switch(action.type) {
    case CMD.CHANGE_TOPIC_PAGE:
      return {...rest}
    case CMD.ADD_ALL_LIST_RESULT:
      return {...state, ...updateAllList(state)}
    case CMD.ADD_BEST_QUES_RESULT:
      return {...state, ...updateBestQues(state)}
    case CMD.ADD_ALL_QUES_RESULT:
      return {...state, ...updateAllQues(state)}
    case CMD.ADD_ART_LIST_RESULT:
      return {...state, ...updateArtList(state)}
    default:
      return state
  }
}

//people
export const people = (state = {}, action) => {
  let {type, ...fields} = action
  let {actionsType, followsType, result} = fields
  let handleActions = (state, actionsType, actionsResult) => {
    let retResult = {}
    let userActionsContent = state.user_actions_content
    let actionsContent = userActionsContent.hasOwnProperty(actionsType) ? userActionsContent[actionsType] : {}
    let actionsResultList = (actionsResult.hasOwnProperty('list') && tools.isArray(actionsResult.list)) ? actionsResult.list :
                              ((actionsResult.hasOwnProperty('list') && tools.isObject(actionsResult.list)) ? Object.values(actionsResult.list) : [])
    retResult['user_actions_content'] = {...userActionsContent}
    if (actionsContent.hasOwnProperty('list') && tools.isArray(actionsContent.list)) {
      actionsContent = {
        list: [...actionsContent.list, ...actionsResultList],
        page: actionsResult.hasOwnProperty('page') ? actionsResult.page : (actionsContent.hasOwnProperty('page') ? actionsContent.page : 0),
        cur_actions_count: actionsResultList.length
      }
    } else if (actionsContent.hasOwnProperty('list') && tools.isObject(actionsContent.list)) {
      actionsContent = {
        list: [...Object.values(actionsContent.list), ...actionsResultList],
        page: actionsResult.hasOwnProperty('page') ? actionsResult.page : (actionsContent.hasOwnProperty('page') ? actionsContent.page : 0),
        cur_actions_count: actionsResultList.length
      }
    }
    retResult['user_actions_content'][actionsType] = actionsContent
    return retResult
  }
  let handleFollows = (state, followsType, followsResult) => {
    let retResult = {}
    let userFollowsContent = state.user_follows
    let followsContent = userFollowsContent.hasOwnProperty(followsType) ? userFollowsContent[followsType] : {}
    retResult['user_follows'] = {...userFollowsContent}
    followsContent = {
      users_list: {...followsContent.users_list, ...(followsResult.hasOwnProperty('users_list') ? followsResult.users_list : {})},
      page: followsResult.hasOwnProperty('page') ? followsResult.page : (followsContent.hasOwnProperty('page') ? followsContent.page : 0),
      cur_follows_count: followsResult.hasOwnProperty('users_list') ? (tools.isObject(followsResult.users_list) ? Object.keys(followsResult.users_list).length : 0) : 0
    }
    retResult['user_follows'][followsType] = followsContent
    return retResult
  }

  let handleTopics = (state, topicsResult) => {
    let retResult = {}
    let userTopicsContent = state.user_topics
    retResult['user_topics'] = {
      topic_list: [...userTopicsContent.topic_list, ...(topicsResult.hasOwnProperty('topic_list') ? topicsResult.topic_list : [])],
      page: topicsResult.hasOwnProperty('page') ? topicsResult.page : (userTopicsContent.hasOwnProperty('page') ? userTopicsContent.page : 0),
      cur_topics_count: topicsResult.hasOwnProperty('topic_list') ? (tools.isArray(topicsResult.topic_list) ? topicsResult.topic_list.length : 0) : 0
    }
    return retResult
  }
  switch(type) {
    case CMD.CHANGE_PEOPLE_PAGE:
      return {...fields}
    case CMD.CHANGE_USER_ACTIONS:
      let actionsResult = {...state.user_actions_content}
      actionsResult[actionsType] = result
      return {...state, user_actions_content: actionsResult}
    case CMD.ADD_USER_ACTIONS:
      let handleResult = handleActions(state, actionsType, result)
      return {...state, ...handleResult}
    case CMD.CHANGE_USER_FOLLOWS:
      let followsResult = {...state.user_follows}
      followsResult[followsType] = result
      return {...state, user_follows: followsResult}
    case CMD.ADD_USER_FOLLOWS:
      let handleFollowsResult = handleFollows(state, followsType, result)
      return {...state, ...handleFollowsResult}
    case CMD.CHANGE_USER_TOPICS:
      return {...state, user_topics: result}
    case CMD.ADD_USER_TOPICS:
      let handleTopicsResult = handleTopics(state, result)
      return {...state, ...handleTopicsResult}
    default:
      return state
  }
}

//search
export const search = (state = {}, action) => {
  let {type, status, result, search} = action
  status = (status) ? status : {}
  result = (result) ? result : []
  search = (search) ? search : {}
  switch(type) {
    case CMD.UPDATE_SEARCH_STATUS:
      return {
        status:{...state.status, ...status},
        result:[...state.result]
      }
    case CMD.CHANGE_SEARCH_CONTENT:
      return {
        status: {...state.status, ...search.status, cur_result_rows: search.result.length},
        result: [...search.result]
      }
    case CMD.ADD_SEARCH_RESULT:
      return {
        status:{...state.status, page: state.status.page + 1, cur_result_rows: result.length},
        result:[...state.result, ...result]
      }
    default:
      return state
  }
}

//publish
export const publish = (state={}, action) => {
  let {type, ...fields} = action
  switch(type) {
    case CMD.CHANGE_PUBLISH_QUESTION:
      return {
        question: {...fields},
        article: state.article
      }
    case CMD.CHANGE_PUBLISH_ARTICLE:
      return {
        question: state.question,
        article: {...fields}
      }
    default:
      return state
  }
}

//notifications
export const notifications = (state={}, action) => {
  let {type, ...fields} = action
  switch(type) {
    case CMD.CHANGE_NOTI_CONTENT:
      return {...fields, cur_noti_num: fields.list.length}
    case CMD.ADD_NOTI_RESULT:
      return {
        ...state,
        page: state.page + 1,
        cur_noti_num: fields.list.length,
        list:[...state.list, ...fields.list],
      }
    default:
      return state
  }
}

//inbox
export const inbox = (state={}, action) => {
  let {type, ...fields} = action
  switch(type) {
    case CMD.CHANGE_INBOX_CONTENT:
      return {
        ...fields, 
        page: 1, 
        cur_inbox_num: fields.hasOwnProperty('list') ? (fields.list ? fields.list.length : 0) : 0}
    case CMD.ADD_INBOX_RESULT:
      return {
        ...state,
        list:[...state.list, ...fields.list],
        page: state.page + 1,
        cur_inbox_num: fields.hasOwnProperty('list') ? (fields.list ? fields.list.length : 0) : 0,
      }
    case CMD.ADD_DIALOG_RESULT:
      let dialog = state.dialog ? state.dialog : {}
      return {
        ...state,
        dialog: {...dialog, ...fields}
      }
    default:
      return state
  }
}

//account
export const account = (state={}, action) => {
  let {type, ...info} = action
  switch(action.type) {
    case CMD.CHANGE_ACCOUNT_CONTENT:
      return {...info}
    default:
      return state
  }
}

//setting
export const setting = (state={}, action) => {
  let {type, ...info} = action
  switch(action.type) {
    case CMD.CHANGE_SETTING_CONTENT:
      return {...info}
    default:
      return state
  }
}