/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import * as tools from '../controller/tools'
import LoadMore from './LoadMore'

const LeftNav = ({articles, authors, setSquare, getSquare}) => {
  let showArts = e => {
    setSquare('article')
    articles()
  }
  let showAuthors = e => {
    setSquare('people')
    authors()
  }
  return (
    <div className="left_nav">
      <div className="left_nav">
        <dl className={"nav20 " + ((getSquare() === 'article') ? 'cur con' : '')}><dt><a href="javascript:void(0);" onClick={showArts}>文章</a></dt></dl>
        <dl className={"nav21 " + ((getSquare() === 'people') ? 'cur con' : '')}><dt><a href="javascript:void(0);" onClick={showAuthors}>作者</a></dt></dl>
      </div>
    </div>
  )
}

const MiddleAuthors = ({ginfo, peopleSquare, peopleMore}) => {
  let usersList = peopleSquare.hasOwnProperty('users_list') ? (peopleSquare.users_list ? peopleSquare.users_list : []) : []
  let curPeopleCount = peopleSquare.hasOwnProperty('cur_people_count') ? peopleSquare.cur_people_count : usersList.length
  return (
  <div>
    <div className="Popular_author">
    {
      ([...usersList].length === 0) ? '' :
      [...usersList].map(u => {
        return (
        <dl>
          <dt><a className="aw-user-name" href={"/people/"+ u.url_token} data-id={u.uid}><img src={u.avatar_file} alt=""/></a></dt>
          <dd>
            <a className="aw-user-name" href={"/people/" + u.url_token} data-id={u.uid}>{u.user_name}</a>
            <h5>{tools.isEmpty(u.introduction) ? '暂无介绍' : u.introduction}</h5>
            <p><i>{u.views_count}</i>次浏览<em></em><i>{u.article_count}</i>篇文章</p>
          </dd>
          <div className="clear"></div>
        </dl>
        )
      })
    }
    </div>

    <div className="mbw auto-square">
      <div className="load-more btn">
        <div className="load_more_div">
          <div className="load_more"><LoadMore perPage={ginfo.G_PER_PAGE} curRows={curPeopleCount} loadEvent={peopleMore}/></div>
        </div>
      </div>
    </div>

  </div>
  )
}

const MiddleArticles = ({ginfo, artSquare, artMore}) => {
  let artList = artSquare.hasOwnProperty('article_list') ? (artSquare.article_list ? artSquare.article_list : [])  : []
  let artTopics = artSquare.hasOwnProperty('article_topics') ? (artSquare.article_topics ? artSquare.article_topics : {}) : {}
  let curArtCount = artSquare.hasOwnProperty('cur_article_count') ? artSquare.cur_article_count : artList.length
  return (
    <div className="auto-square-articles">
      <div className="n_article">
        {
          [...artList].map(art => {
            return (
            <dl>
              <dt><a href={"/article/" + art.id}><img src={(!tools.isEmpty(art.article_img)) ? art.article_img : require('../../img/default-cover.jpg')} alt=""/></a></dt>
              <dd>
                <h2><a href={"/article/" + art.id}>{art.title}</a></h2>
                <div className="a_label">
                {
                  !(artTopics[art.id] && (artTopics[art.id].length > 0)) ? '' :
                  artTopics[art.id].map(at => <a href={"/topic/"+ at.url_token} target="_blank" rel="noopener noreferrer">{at.topic_title}</a>)
                }
                </div>
                <div className="a_foot">
                  <a className="peopic aw-user-name" href={"/people/"+art.user_info.url_token} data-id={art.user_info.uid}><img src={art.user_info.avatar_file} alt=""/></a>
                  <a style={{width:'auto'}} class="peoname aw-user-name" href={"/people/"+art.user_info.url_token} data-id={art.user_info.uid}>{art.user_info.user_name}</a>
                  <span style={{paddingLeft:'5px'}}>|<span className="peotime" style={{paddingLeft:'5px'}}>{art.add_time}</span></span>
                  <div className="foot_bq"><label className="browse"><i style={{color:'#999'}}>{' ' + art.views}</i></label></div>
                </div>
              </dd>
              <div className="clear"></div>
            </dl>
            )
          })
        }
      </div>

      <div className="mbw auto-square">
        <div className="load-more btn">
          <div className="load_more_div">
            <div className="load_more"><LoadMore perPage={ginfo.G_PER_PAGE} curRows={curArtCount} loadEvent={artMore}/></div>
          </div>
        </div>
      </div>

    </div>
  )
}

const MiddleArea = ({ginfo, artSquare, peopleSquare, getSquare, artMore, peopleMore}) =>
  <div class="Middle_area ">
    {
      (getSquare() === 'article') ? <MiddleArticles ginfo={ginfo} artSquare={artSquare} artMore={artMore}/> : 
      ((getSquare() === 'people') ? <MiddleAuthors ginfo={ginfo} peopleSquare={peopleSquare} peopleMore={peopleMore}/> : '')
    }
  </div>

const RightArea = ({artSquare, peopleSquare}) => {
  let hotArticles = artSquare.hasOwnProperty('hot_articles') ? (artSquare.hot_articles ? artSquare.hot_articles : []) : []
  let hotUsers = (peopleSquare && tools.isObject(peopleSquare) && Object.keys(peopleSquare).length > 0) ? 
                  (peopleSquare.hasOwnProperty('sidebar_hot_users') ? (peopleSquare.sidebar_hot_users ? peopleSquare.sidebar_hot_users : []) : []) : []
  return (
    <div className="right_area ">   
      <div className="tj_articles">
        <h4>推荐文章</h4>
        <ul>{[...hotArticles].map(ha => <li><a href={"/article/"+ha.id}>{ha.title}</a></li>)}</ul>
      </div>
      {
        ([...hotUsers].length === 0) ? '' :
        <div className="right_Notice tj_author">
        <h4>推荐作者</h4>
        {
          [...hotUsers].map(u => {
          return (
            <dl>
              <dt><a className="aw-user-name" href={"/people/" + u.url_token} data-id={u.uid}><img src={u.avatar_file} alt=""/></a></dt>
              <dd><a className="aw-user-name" href={"/people/"+ u.url_token} data-id={u.uid}>{u.user_name}</a><p><i>{u.views_count}</i>次浏览，<i>{u.article_count}</i>篇文章</p></dd>
              <div className="clear"></div>
            </dl>)
          })
        }
        </div>
      }
    </div>
  )
}

class ArticlesMain extends Component {

  constructor(props) {
    super(props)
    this.whereSquare = 'article'
    this.setSquare = this.setSquare.bind(this)
    this.getSquare = this.getSquare.bind(this)
  }

  setSquare(where) {this.whereSquare = where}
  getSquare() {return this.whereSquare}

  render() {
    let {ginfo, artSquare, peopleSquare, authors, articles, artMore, peopleMore} = this.props
    return (
    <div className="w1150 Main_area">
      <LeftNav articles={articles} authors={authors} setSquare={this.setSquare} getSquare={this.getSquare}/>
      <MiddleArea ginfo={ginfo} artSquare={artSquare} peopleSquare={peopleSquare} getSquare={this.getSquare} artMore={artMore} peopleMore={peopleMore}/>
      <RightArea  artSquare={artSquare} peopleSquare={peopleSquare}/>
      <div className="clear"></div>
    </div>)
  }
}

export default ArticlesMain
