/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {URL} from '../controller/constants'
import * as tools from '../controller/tools'

const LeftNav = ({type}) => {
  const columns = [{'question':'问题'},{'article':'文章'}]
  const linkCol = (t, c) => {
    let to = "/publish/" + Object.keys(c)[0] + "/"
    let selected = (t === Object.keys(c)[0]) ? 'Select' : ''
    return (<Link className={selected} to={to}>{Object.values(c)[0]}</Link>)
  }
  return (
    <div className="left_nav">
      <dl className="nav8 cur ">
        <dt><a href="javascript:void(0)">发起</a></dt>
        <dd>{columns.map(col => linkCol(type, col))}</dd>
      </dl>
    </div>
  )
}

const QuesMiddle = ({ginfo, ques}) => {
  let _quesContent, _recentTopics=[]
  let quesInfo = (ques.hasOwnProperty('question_info')) ? (tools.isObject(ques.question_info) ? ques.question_info : {}) : {}
  let quesId = (quesInfo.hasOwnProperty('question_id')) ? quesInfo.question_id : ''
  let formAction = URL.BASE_URL + '/api/publish/' + ((!tools.isEmpty(quesId)) ? 'modify_question/' : 'publish_question/')
  let quesContent = tools.trim((quesInfo.hasOwnProperty('question_content')) ? quesInfo.question_content : '')
  let quesDetail = (quesInfo.hasOwnProperty('question_detail')) ? quesInfo.question_detail : ''
  let quesTopics = ques.hasOwnProperty('question_topics') ? (tools.isArray(ques.question_topics) ? ques.question_topics : []) : []
  let recentTopics = (ques.hasOwnProperty('recent_topics')) ? (tools.isArray(ques.recent_topics) ? ques.recent_topics : []) : []
  const addTopic = e => {window.keypress()}
  const delTopic = i => e => {window.$('#delete_topic' + i).parents('.topic-tag').remove()}
  const quesSubmit = e => {
    _quesContent.value = (!tools.isEmpty(tools.trim(_quesContent.value))) ? tools.trim(_quesContent.value) : _quesContent.defaultValue
    window.AWS.ajax_post(window.$('#question_form'), window.AWS.ajax_processer,'question')
    return false
  }
  const addRecentTopic = i => e => {
    window.$('#aw_edit_topic_title').val(_recentTopics[i].innerHTML)
    window.$('.add').click()
    _recentTopics[i].style.display='none'
  }
  recentTopics = recentTopics.map((value, key) => {
    return (<a className="text" ref={a=>_recentTopics[key]=a} href="javascript:void(0);" onClick={addRecentTopic(key)}>{value}</a>)
  })
  
  return (
    <div className="Middle_area">
    <form action={formAction} method="post" id="question_form" onsubmit="return false;">
      <input type="hidden" name="post_hash" value={ginfo.G_POST_HASH} />
      <input type="hidden" name="attach_access_key" value={ques.attach_access_key}/>
      <input type="hidden" name="question_id" id="question_id" value={quesId}/>
      <input type="hidden" name="ask_user_id" value={ques.hasOwnProperty('ask_user_id') ? ques.ask_user_id : ''}/>
      <div class="Launch_box">
        <h3>问题标题：</h3>
        <div className="aw-publish-title" style={{position:'relative'}}>
            <input name="question_content" id="question_contents" type="text" placeholder="请输入问题标题..." ref={input=>_quesContent=input} defaultValue={quesContent} />
            <div className="aw-publish-suggest-question collapse aw-dropdown ser_list">
                <p className="text-color-999" style={{padding:'5px 0 5px 17px'}}>你的问题可能已经有答案</p>
                <ul className="aw-dropdown-list"></ul>
            </div>
        </div>

        <h3>问题补充（选填）：</h3>
        <div className="Paran_text" style={{display:'none'}}>
            <div className="wmd-panel">
            <script className="fuwenben" style={{display:'block'}} id="container" type="text/plain" name="question_detail" dangerouslySetInnerHTML={{__html:quesDetail}}></script>
            </div>
        </div>
        {/*<p>如需问题补充中@其他用户,需要在名字后面添加空格。例如：我有一个问题想要@陈老师 来解答。</p>*/}
        <span style={{color:'#999999',fontSize:'10px'}} id="answer_content_message">&nbsp;</span>.
        <div>
          <h3>添加话题：</h3>
          <div className="add_topic_lists topic_label">
          {
            [...quesTopics].map((qt, i) => {
              return (
                <span class="topic-tag">
                <i class="text">{qt.topic_title}</i>
                <em>
                  <img style={{cursor:'pointer'}} className="delete_topic" id={"delete_topic" + i} src={require("../../img/close2.png")} onClick={delTopic(i)} alt=""/>
                </em>
                <input type="hidden" value={qt.topic_title} name="topics[]"/>
                </span>
              )
            })
          }
          </div>
          <div className="Add_topic aw-topic-bar" data-type="publish" style={{marginBottom:'22px'}}>
            <div className="topic tag-bar">
              <div className="aw-edit-topic-box form-inline" style={{position:'relative'}}>
                <input type="text" className="form-control" id="aw_edit_topic_title" autoComplete="off" onKeyPress={addTopic} placeholder="添加话题..."/>
                <a className="btn btn-normal btn-success add" style={{display:'none'}}>添加 </a>
                <a className="btn btn-normal btn-gray close-edit" style={{display:'none'}}>取消</a>
                <div className="aw-dropdown ser_list" style={{maxHeight:'160px', overflowY:'auto', display:'none'}}>
                    <p className="title" style={{height:'40px',lineHeight:'40px',paddingLeft:'10px'}}>没有找到相关结果</p>
                    <ul className="aw-dropdown-list"></ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          ([...recentTopics].length === 0) ? '' :
          <div>
            <h3 style={{marginTop:'0px'}}>最近话题：</h3>
            <div className="latest_topic">{[...recentTopics]}</div>
          </div>
        }
        <div className="Put_questions">
          {
            (!tools.isEmpty(quesId)) ? null :
            <span className="anonymous">
              <em></em>
              <input style={{display:'none'}} type="checkbox" class="pull-left" value="1" name="anonymous"/>匿名提问
            </span>
          }
          <a href="javascript:void(0)" className="btn btn-large btn-success btn-publish-submit" id="publish_submit" onClick={quesSubmit}>提问</a>
          <div class="clear"></div>    
        </div>
      </div>
    </form>
    </div>
  )
}

const ArticleMiddle = ({ginfo, art}) => {
  let _artTitle, _recentTopics=[]
  let artInfo = (art.hasOwnProperty('article_info')) ? (tools.isObject(art.article_info) ? art.article_info : {}) : {}
  let artId = (artInfo.hasOwnProperty('id')) ? artInfo.id : ''
  let formAction = URL.BASE_URL + '/api/publish/' + ((!tools.isEmpty(artId)) ? 'modify_article/' : 'publish_article/')
  let artTitle = tools.trim((artInfo.hasOwnProperty('title')) ? artInfo.title : '')
  let artImg = tools.trim((artInfo.hasOwnProperty('article_img')) ? artInfo.article_img : '')
  let artMessage = artInfo.hasOwnProperty('message') ? artInfo.message : ''
  let artTopics = art.hasOwnProperty('article_topics') ? (tools.isArray(art.article_topics) ? art.article_topics : []) : []
  let recentTopics = art.hasOwnProperty('recent_topics') ? (tools.isArray(art.recent_topics) ? art.recent_topics : []) : []
  const addTopic = e => {window.keypress()}
  const delTopic = i => e => {window.$('#delete_topic' + i).parents('.topic-tag').remove()}
  const artSubmit = e => {
    _artTitle.value = (!tools.isEmpty(tools.trim(_artTitle.value))) ? tools.trim(_artTitle.value) : _artTitle.defaultValue
    window.AWS.ajax_post(window.$('#article_form'), window.AWS.ajax_processer,'article')
    return false
  }
  const addRecentTopic = i => e => {
    window.$('#aw_edit_topic_title').val(_recentTopics[i].innerHTML)
    window.$('.add').click()
    _recentTopics[i].style.display='none'
  }
  recentTopics = recentTopics.map((value, key) => {
    return (<a className="text" ref={a=>_recentTopics[key]=a} href="javascript:void(0);" onClick={addRecentTopic(key)}>{value}</a>)
  })

  return (
    <div className="Middle_area">
      <form action={formAction} method="post" id="article_form" onsubmit="return false;">
        <input type="hidden" name="post_hash" value={ginfo.G_POST_HASH}/>
        <input type="hidden" name="attach_access_key" value={art.attach_access_key}/>
        <input type="hidden" name="article_id" id="article_id" value={artId}/>
        <input type="hidden" name="logo_img" value={(!tools.isEmpty(artImg)) ? artImg : ''} />
        <div class="Launch_box">
            <h3>文章标题：</h3>
            <input name="title" type="text" placeholder="请输入文章标题..." ref={input=>_artTitle=input} defaultValue={artTitle} />
            <h3>文章封面图：</h3>
            <div className="pt_upload article-cover">
                <div id="logo_uploader" style={{display:'none'}}></div>
                <a id="cover_upload" href="javascript:void(0);">
                  <span className="auto-article-cover" >
                    <img src={(!tools.isEmpty(artImg)) ? artImg : require('../../img/jia.png')} alt="" id="logo_img" />
                  </span>
                  <span id="logo_uploading_status" style={{display:'none'}} ><img src={require('../../img/loading_c.gif')} alt=""></img></span>
                </a>
            </div>
            <p>支持 jpg、jpeg、png、gif格式的图片，图片尺寸比例140px * 92px，图片最大为5M</p>

            <h3>文章内容：</h3>
            <div className="Paran_text" style={{display:'none'}}>
                <div className="wmd-panel">
                  <script className="fuwenben" style={{display:'block'}} id="container" type="text/plain" name="message" dangerouslySetInnerHTML={{__html:artMessage}}></script>
                </div>
            </div>
            {/*<p>如需问题补充中@其他用户,需要在名字后面添加空格。例如：我有一个问题想要@陈老师 来解答。</p>*/}
            <span style={{color:'#999999', fontSize:'10px'}} id="answer_content_message">&nbsp;</span>

            <h3>添加话题：</h3>
            <div className="add_topic_lists topic_label">
            {
              [...artTopics].map((at, i) => {
                return (
                  <span class="topic-tag">
                  <i class="text">{at.topic_title}</i>
                  <em>
                    <img style={{cursor:'pointer'}} className="delete_topic" id={"delete_topic" + i} src={require("../../img/close2.png")} onClick={delTopic(i)} alt=""/>
                  </em>
                  <input type="hidden" value={at.topic_title} name="topics[]"/>
                  </span>
                )
              })
            }
            </div>
            <div className="Add_topic aw-topic-bar" style={{marginBottom:'22px'}}>
                <div className="topic tag-bar">
                    <div className="aw-edit-topic-box form-inline" style={{position:'relative'}}>
                        <input type="text" className="form-control" id="aw_edit_topic_title" autocomplete="off" onkeypress={addTopic} placeholder="添加话题..."/>
                        <a className="btn btn-normal btn-success add" style={{display:'none'}}>添加 </a>
                        <a className="btn btn-normal btn-gray close-edit" style={{display:'none'}}>取消</a>
                        <div className="aw-dropdown ser_list" style={{maxHeight:'160px', overflowY:'auto', display:'none'}}>
                            <p className="title" style={{height:'40px', lineHeight:'40px', paddingLeft:'10px'}}>没有找到相关结果</p>
                            <ul className="aw-dropdown-list"></ul>
                        </div>
                    </div>
                </div>
            </div>
            
            {
              ([...recentTopics].length === 0) ? '' :
              <div>
                <h3 style={{marginTop:'0px'}}>最近话题：</h3>
                <div className="latest_topic">{[...recentTopics]}</div>
              </div>
            }

            <div className="Put_questions">
                <a style={{cursor:'pointer'}} className="btn btn-large btn-success btn-publish-submit" id="publish_submit" onClick={artSubmit}>确认发起</a>
                <div className="clear"></div>
            </div>
        </div>
      </form>
    </div>
  )
}

const QuesRight = props =>
  <div class="right_area ">
    <div class="guide">
      <h4>发起问题指南</h4>
      <p><b>问题标题:</b> 请用准确的语言描述您发布的问题思想</p>
      <p><b>问题补充:</b> 详细补充您的问题内容, 并提供一些相关的素材以供参与者更多的了解您所要问题的主题思想</p>
      <p><b>选择话题:</b> 选择一个或者多个合适的话题, 让您发布的问题得到更多有相同兴趣的人参与. 所有人可以在您发布问题之后添加和编辑该问题所属的话题</p>
    </div>
  </div>

const ArticleRight = props =>
  <div class="right_area ">
    <div class="guide">
        <h4>发起文章指南</h4>
        <p><b>文章标题:</b> 请用准确的语言描述您发布的文章思想</p>
        <p><b>文章补充:</b> 详细补充您的文章内容, 并提供一些相关的素材以供参与者更多的了解您所要文章的主题思想</p>
        <p><b>选择话题:</b> 选择一个或者多个合适的话题, 让您发布的文章得到更多有相同兴趣的人参与. 所有人可以在您发布文章之后添加和编辑该文章所属的话题</p>
    </div>
  </div>

const PublishQues = ({ginfo, type, question}) =>
  <div class="w1150 Main_area">
    <LeftNav type={type}/>
    <QuesMiddle ginfo={ginfo} ques={question} />
    <QuesRight />
    <div class="clear"></div>
  </div>

const PublishArticle = ({ginfo, type, article}) =>
  <div class="w1150 Main_area">
    <LeftNav type={type} />
    <ArticleMiddle ginfo={ginfo} art={article} />
    <ArticleRight />
    <div class="clear"></div>
  </div>

class PublishMain extends Component {
  render() {
    const {ginfo, type, question, article} = this.props
    switch(type) {
      case 'question':
        return <PublishQues ginfo={ginfo} type={type} question={question} />
      case 'article':
        return <PublishArticle ginfo={ginfo} type={type} article={article} />
      default:
        return <span style={{color:'red', fontSize:'18px'}}>unkown error!!!</span>
    }
  }
}

export default PublishMain;
