/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import * as tools from '../controller/tools'
import LoadMore from './LoadMore'

const LeftNav = ({query, type}) => {
  const columns = [{'all':'全部'},{'users':'用户'},{'topics':'话题'},{'questions':'问题'},{'articles':'文章'}]
  const linkCol = (q, t, c) => {
    t = (tools.isEmpty(t)) ? 'all' : t
    let to = "/search/" + q + "/" + Object.keys(c)[0]
    let selected = (t === Object.keys(c)[0]) ? 'Select' : ''
    return (<Link className={selected} to={to}>{Object.values(c)[0]}</Link>)
  }
  return (
  <div className="left_nav">
    <dl className="nav17 cur">
        <dt><a href="javascript:void(0);">搜索</a></dt>
        <dd>{columns.map(col => linkCol(query, type, col))}</dd>
    </dl>
  </div>)
}

const BlockUsers = ({item}) =>
  <dl>
    <dd>
      <div className="h_pic">
        <a href={'/people/' + item.detail.user_url_token} className="aw-user-name" data-id={item.uid}>
        <img src={item.detail.avatar_file} alt=""/>
        </a>
      </div>
      <div className="h_text">
        <a href={'/people/' + item.detail.user_url_token} target="_blank" rel="nofollow me noopener noreferrer">{item.name}</a>
        <label></label>
        <p><i>{item.detail.reputation}</i>威望,<i>{item.detail.agree_count}</i>个赞同</p>
      </div>
      <div className="clear"></div>
    </dd>
  </dl>

const BlockTopics = ({item}) =>
  <dl>
      <dd>
        <div className="h_pic">
          <a href={'/topic/' + item.detail.url_token} class="aw-topic-img" data-id={item.detail.topic_id}>
          <img src={item.detail.topic_pic} alt=""/>
          </a>
        </div>
        <div className="h_text">
          <a href={'/topic/' + item.detail.url_token} className="text" target="_blank" rel="nofollow me noopener noreferrer">{item.name}</a>
          <p>7天新增<i>{item.detail.discuss_count_last_week}</i>个讨论，30天新增<i>{item.detail.discuss_count_last_month}</i>个讨论</p> 
        </div>
        <div className="follow_BUT">
          <ul>
            <li>
                <span>讨论</span>
                <b>{item.detail.discuss_count}</b>
              </li>
              <li>
                <span>关注</span>
                <b>{item.detail.focus_count}</b>
              </li>
          </ul>
        </div>
        <div className="clear"></div>
      </dd>
  </dl>

const BlockQuestions = ({query, item}) => {
  const highText = (t, q) => {
    for (let v of [...q.replace(/\s/g,'')]) {
      if (t !== v) {continue}
      return <i className="auto-color-red">{t}</i>
    }
    return t
  }
  let name = [...item.name.replace(/\s/g,'')]
  name = (name.length <= 24) ? [...name]: [...name.slice(0, 24),'...']
  name = name.map(n => highText(n, query))
  let quesTopics = (Array.isArray(item.question_topics)) ? item.question_topics : []
  return (
  <dl>
    <dt>
      <a href={'/people/' + item.detail.user_url_token} className="aw-user-name" data-id={item.detail.uid}>
      <img src={item.detail.avatar_url} alt=""/>{item.detail.user_name}</a>
      <label className="fr">{item.detail.add_time}</label>
    </dt>
    <dd className="texts"><p className="bold"><a href={'/question/' + item.search_id}>{name}</a></p></dd>
    <dd className="biaoq">
      {quesTopics.map(t => <a href={'/topic/' + t.url_token}>{t.topic_title}</a>)}
      <div className="clear"></div>
    </dd>
    <dd><p></p></dd>
    <dd><label><img src={require("../../img/comment.png")} alt=""/><i>{item.detail.comment_count}</i>人评论</label></dd>
  </dl>)
}

const BlockArticles = ({query, item}) => {
  const highText = (t, q) => {
    for (let v of [...q.replace(/\s/g,'')]) {
      if (t !== v) {continue}
      return <i className="auto-color-red">{t}</i>
    }
    return t
  }
  let name = [...item.name.replace(/\s/g,'')]
  name = (name.length <= 24) ? name: [...name.slice(0, 24),'...']
  name = name.map(n => highText(n, query))

  let content = [...item.detail.content.replace(/\s/g,'')]
  content = (content.length <= 48) ? [...content] : [...content.slice(0, 48),'...']
  content = content.map(c => highText(c, query))
  return (
  <dl>
    <dt>
      <a href={'/people/' + item.detail.user_url_token} className="aw-user-name" data-id={item.detail.uid}>
      <img src={item.detail.avatar_url} alt=""/>{item.detail.user_name}</a>
      <label className="fr">{item.detail.add_time}</label>
    </dt>
    <dd className="texts"><p className="bold" style={{fontSize:'13px'}}><a href={'/article/' + item.search_id}>{name}</a></p></dd>
    <dd><p style={{color:'#666'}}>{content}<a className="more" href={'/article/' + item.search_id}>查看全部</a></p></dd>
    <dd>
      <label><img src={require("../../img/bj.png")} alt=""/><i>{item.detail.comments}</i>评论</label>
      <label><img src={require("../../img/browse.png")} alt=""/><i>{item.detail.views}</i>浏览</label>
    </dd>
  </dl>)
}

const BlockMore = ({ginfo, rows, more}) => <LoadMore perPage={ginfo.G_PER_PAGE} curRows={rows} loadEvent={more}/>

const MiddleNull = props =>
  <div className="Middle_area">
    <div className="wenda">
      <div className="no_result">
        <img src={require("../../img/no_result.png")} alt="no result"/>
        <p>暂无内容</p>
      </div>
    </div>
  </div>
  
/*
const MiddleLoading = props =>
  <div className="Middle_area">
    <div className="wenda">
    <span className="auto-flush-loading"><img src={require("../../img/loading_b.gif")} alt=""/></span>
    </div>
  </div>
*/

const MiddleUsers = ({ginfo, result, rows, more}) =>
  <div className="Middle_area">
    <div className="wenda">
      {result.map(r => <BlockUsers item = {r}/>)}
      <BlockMore ginfo={ginfo} rows={rows} more={more}/>
    </div>
  </div>

const MiddleTopics = ({ginfo, result, rows, more}) =>
  <div className="Middle_area">
    <div className="wenda">
      {result.map(r => <BlockTopics item = {r}/>)}
      <BlockMore ginfo={ginfo} rows={rows} more={more}/>
    </div>
  </div>

const MiddleQuestions = ({ginfo, query, result, rows, more}) =>
  <div className="Middle_area">
    <div className="wenda">
      {result.map(r => <BlockQuestions query={query} item ={r}/>)}
      <BlockMore ginfo={ginfo} rows={rows} more={more}/>
    </div>
  </div>

const MiddleArticles = ({ginfo, query, result, rows, more}) =>
  <div className="Middle_area">
    <div className="wenda">
      {result.map(r => <BlockArticles query={query} item ={r}/>)}
      <BlockMore ginfo={ginfo} rows={rows} more={more}/>
    </div>
  </div>

const MiddleAll = ({ginfo, query, result, rows, more}) =>
  <div className="Middle_area">
    <div className="wenda">
    {result.map(r =>{
      if (r.type === 'questions') { return <BlockQuestions query={query} item = {r}/>}
      else if (r.type === 'articles') { return <BlockArticles query={query} item = {r}/>}
      else if (r.type === 'users') { return <BlockUsers item = {r}/>}
      else if (r.type === 'topics') { return <BlockTopics item = {r}/>}
      else {return <dl></dl>}
    })}
    {/* 
    {[...result].filter(r => r.type === 'questions').map(r => <BlockQuestions query={query} item = {r}/>)}
    {[...result].filter(r => r.type === 'articles').map(r => <BlockArticles query={query} item = {r}/>)}
    {[...result].filter(r => r.type === 'users').map(r => <BlockUsers item = {r}/>)}
    {[...result].filter(r => r.type === 'topics').map(r => <BlockTopics item = {r}/>)}
    */}
    <BlockMore ginfo={ginfo} rows={rows} more={more}/>
    </div>
  </div>

const MiddleArea = ({ginfo, query, type, result, rows, more}) => {
  switch(type) {
    case 'users':
      result = [...result].filter(r => r.type === type)
      return (result.length > 0) ? <MiddleUsers ginfo={ginfo} result={result} rows={rows} more={more}/> : <MiddleNull/>
    case 'topics':
      result = [...result].filter(r => r.type === type)
      return (result.length > 0) ? <MiddleTopics ginfo={ginfo} result={result} rows={rows} more={more}/> : <MiddleNull/>
    case 'questions':
      result = [...result].filter(r => r.type === type)
      return (result.length > 0) ? <MiddleQuestions ginfo={ginfo} query={query} result={result} rows={rows} more={more}/> : <MiddleNull/>
    case 'articles':
      result = [...result].filter(r => r.type === type)
      return (result.length > 0) ? <MiddleArticles ginfo={ginfo} query={query} result={result} rows={rows} more={more}/> : <MiddleNull/>
    default:
      return (result.length > 0) ? <MiddleAll ginfo={ginfo} query={query} result={result} rows={rows} more={more}/> : <MiddleNull/>
  }
}

const RightArea = props =>
  <div className="right_area ">
      {/* 公告 */}
      <div className="right_Notice">
      <h4>公告<a href="../announce/index.htm">查看更多</a></h4>
      <ul>
          <li>
            <a href="../announce/detail/id-60.htm" >【币问奖励发放 </a>
          </li>
        <li>
            <a href="../announce/detail/id-59.htm" >【币问奖励发放】</a>
          </li>
          <li>
            <a href="../announce/detail/id-58.htm" >【奖励调整通知】</a>
          </li>
      </ul>
      </div>
      {/* 公告 */}
          
      {/* 下载 */}
      <div className="right_Notice b_pp"><img src={require("../../img/1540824923.jpg")} alt="" /></div>
  </div>

class SearchMain extends Component {
  render() {
    const {ginfo, status, result, more} = this.props
    const {cur_result_rows, raw_query, query, type} = status
    return (
    <div className="w1150 Main_area">
      <LeftNav query={query} type={type}/>
      <MiddleArea ginfo={ginfo} query={raw_query} type={type} result={result} rows={cur_result_rows} more={more}/>
      <RightArea />
      <div className="clear"></div>
    </div>
    )
  }
}

export default SearchMain
